const ctrl = ['$scope', 'fzpSvcAttribute', 'fzpSvcPersonDepositEdit', function ($scope, fzpSvcAttribute, fzpSvcPersonDepositEdit) {
    const my = this;

    my.getCitizetship = function () {
        const m = $scope.model;
        const r = $scope.ref;
        if (!m || !m.citizenship) return null;
        if (m.citizenship == 'other') return m.otherCitizenship;
        if (!r || !r.citizenship) return m.citizenship;
        return (r.citizenship.find(x => x.code === m.citizenship) || {}).name;
    };

    my.showModal = function () {
        fzpSvcPersonDepositEdit.open(
                $scope.model,
                $scope.config,
                $scope.ref,
                $scope.suggestPersons,
                'Депозит ФЛ',
                $scope.ngReadonly
            )
            .then(result => {
                if (!result) return;
                $scope.onChange({
                    $item: result
                });
            });
    };

    my.validate = function () {
        if (!$scope.model || !$scope.model.person) return false;
        const d = $scope.model.person;
        const cfg = $scope.config || {};
        const required = field => !!cfg[field] && !!cfg[field].required;
        const show = field => !!cfg[field] && !!cfg[field].show;
        const isRf = !cfg.citizenship || !cfg.citizenship.show || d.citizenship === '643'; //'rf';

        return !!d.lastName && !!d.firstName && !!d.secondName &&
            (!required('inn') || !!d.inn) &&
            (!show('inn') || !d.inn || d.inn.length == 12) &&
            (!required('birthDate') || !!d.birthDate) &&
            (!required('birthPlace') || !!d.birthPlace) &&
            (!required('registrationAddress') || (!!d.registrationAddress && !!d.registrationAddress.result)) &&
            (!required('phone') || (d.contacts || []).some(x => !!x.phone)) &&
            (!required('email') || (d.contacts || []).some(x => !!x.email)) &&
            (!required('position') || !!d.position) &&
            (!required('authorizingDoc') || !!d.authorizingDoc) &&
            (!required('citizenship') || !!d.citizenship) &&
            (!required('otherCitizenship') || d.citizenship !== 'other' || !!d.otherCitizenship) &&
            (!required('identityDocument') || (!!d.number && (!isRf || (!!d.series && !!d.issuedDate && !!d.issuingAuthorityCode && !!d.issuingAuthority)))) &&
            (!required('sharePercent') || !!d.sharePercent || d.sharePercent === 0) &&
            (!show('sharePercent') || !d.sharePercent || (d.sharePercent >= 0 && d.sharePercent <= 100)) &&
            (!required('shareSum') || (!!d.shareSum !== 0)) &&
            fzpSvcAttribute.validateGroup(d.personAttributes) &&
            fzpSvcAttribute.validateGroup(d.relationAttributes) &&
            fzpSvcAttribute.validateGroup(d.relationTopAttributes);
    };

    function updateRef(person) {
        if (!person || !$scope.suggestPersons) return;
        if (!person.lastName || !person.firstName || !person.secondName || !person.series || !person.number) return;

        const newRefItem = {
            displayName: `${person.lastName} ${person.firstName} ${person.secondName} (паспорт ${person.series} ${person.number})`,
            key: `${person.lastName}#${person.firstName}#${person.secondName}#${person.series}#${person.number}`,
            data: JSON.parse(JSON.stringify(person))
        };

        const refItem = $scope.suggestPersons.find(x => x.key === newRefItem.key);
        if (!refItem) {
            $scope.suggestPersons.push(newRefItem);
        } else {
            for (let key of Object.keys(newRefItem.data)) {
                if (key !== 'personAttributes' && key !== 'relationAttributes') {
                    refItem.data[key] = newRefItem.data[key];
                }
            }
            mergeAttributes('personAttributes', refItem.data, newRefItem.data);
            mergeAttributes('relationAttributes', refItem.data, newRefItem.data);
        }
    };

    function mergeAttributes(name, destData, srcData) {
        if (srcData[name] && srcData[name].items && srcData[name].items.length) {
            if (!destData[name]) destData[name] = {};
            if (!destData[name].items) destData[name].items = [];

            const destMap = new Map();
            destData[name].items.forEach(attr => destMap.set(attr.attributeId, attr));
            srcData[name].items.forEach(attr => {
                if (destMap.has(attr.attributeId)) {
                    const destAttr = destMap.get(attr.attributeId);
                    destAttr.value = attr.value;
                    destAttr.values = attr.values;
                } else {
                    destData[name].items.push(attr);
                }
            });
        }
    };

    $scope.$watch('model', () => updateRef($scope.model));
}];

export default ctrl;