import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.config = angular.copy($modalParams.config);
    my.data = angular.copy($modalParams.item);

    my.closeOk = function () {
        $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.selectedBank = {
        bankName: (my.data.account || {}).bankName,
        BIK: (my.data.account || {}).BIK,
        corrNumber: (my.data.account || {}).corrNumber
    };

    $scope.$watch('my.selectedBank', function (selectedBank) {
        if (!selectedBank) selectedBank = {};

        if (!my.data.account) my.data.account = {};
        my.data.account.bankName = selectedBank.bankName;
        my.data.account.BIK = selectedBank.BIK;
        my.data.account.corrNumber = selectedBank.corrNumber;
    });
}];