const defaultConfig = {
    inn: {
        show: true,
        required: false
    },
    birthDate: {
        show: true,
        required: true
    },
    birthPlace: {
        show: true,
        required: true
    },
    identityDocument: {
        show: true,
        required: true
    },
    registrationAddress: {
        show: true,
        required: true
    },
    phone: {
        show: true,
        required: false
    },
    email: {
        show: true,
        required: false
    },
    position: {
        show: false,
        required: false
    },
    authorizingDoc: {
        show: false,
        required: false
    },
    citizenship: {
        show: true,
        required: true
    },
    otherCitizenship: {
        show: true,
        required: true
    },
    sharePercent: {
        show: true,
        required: true
    },
    shareSum: {
        show: false,
        required: false
    },
};

const ctrl = ['$scope', 'fzpSvcPersonEdit', 'fzpSvcFounderCompanyEdit', function ($scope, fzpSvcPersonEdit, fzpSvcFounderCompanyEdit) {
    const my = this;

    my.onDelete = function (item) {
        const i = $scope.model.data.indexOf(item);
        $scope.model.data.splice(i, 1);
    };

    my.onChange = function (oldItem, newItem) {
        const i = $scope.model.data.indexOf(oldItem);
        $scope.model.data[i] = newItem;
    };

    my.onCreatePerson = function () {
        if (!$scope.model) return;
        const config = Object.assign({}, defaultConfig, $scope.model.config || {});
        fzpSvcPersonEdit.open(
                angular.copy($scope.model.personItemTemplate || {}),
                config,
                $scope.model.ref,
                $scope.suggestPersons,
                'Учредитель / акционер',
                false
            )
            .then(person => {
                if (!person) return;

                const founder = {
                    person: person,
                    personAttributes: person.personAttributes,
                    shareSum: person.shareSum,
                    sharePercent: person.sharePercent
                };
                delete founder.person.personAttributes;
                delete founder.person.shareSum;
                delete founder.person.sharePercent;

                if (!$scope.model.data) $scope.model.data = [];
                $scope.model.data.push(founder);
            });
    };

    my.onCreateCompany = function () {
        if (!$scope.model) return;
        const config = Object.assign({}, defaultConfig, $scope.model.config || {});
        fzpSvcFounderCompanyEdit.open(
                {},
                config,
                $scope.model.ref,
                'Учредитель / акционер',
                false
            )
            .then(founderCompany => {
                if (!founderCompany) return;
                if (!$scope.model.data) $scope.model.data = [];
                $scope.model.data.push(founderCompany);
            });
    };
}];

export default ctrl;