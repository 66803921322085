const ctrl = ['$scope', function ($scope) {
    const my = this;

    $scope.uniqueId = new Date().getTime() + (Math.random() * 100000000000000000).toString(36);

    my.needValidate = (x) => {
        return x && x.companyVat && (x.companyVat.amount != null || x.companyVat.amountApproved != null || x.companyVat.amountDecline != null);
    }

    my.calcDeclineRate = (x) => {
        x.declineRate = !!x.amount && x.amount != 0 ? x.amountDecline/x.amount * 10000 : 0
    }

    my.onChangeAmountVat = (x) => {
        if(!!x.amount && ( !!x.amountApproved  || !!x.amountDecline)){
            if(!!x.amountApproved) {
                x.amountDecline = x.amount - x.amountApproved
            }else{
                x.amountApproved = x.amount - x.amountDecline
            }
            my.calcDeclineRate(x);
        }
    }

    my.onChangeNoDecisionVat = (x) => {
        if(x.noDecision){
            x.amountApproved = 0;
            x.amountDecline = 0;
            x.declineRate = 0;
        }
    }

    my.onChangeAmountApprovedVat = (x) => {
        if(!!x.amountApproved && (!!x.amount || !!x.amountDecline)){
            if(!!x.amount) {
                x.amountDecline = x.amount - x.amountApproved
            }else{
                x.amount = x.amountDecline + x.amountApproved
            }
            my.calcDeclineRate(x);
        }
    }

    my.onChangeAmountDeclineVat = (x) => {
        if(!!x.amountDecline && ( !!x.amount || !!x.amountApproved)){
            if(!!x.amount) {
                x.amountApproved = x.amount - x.amountDecline
            }else{
                x.amount = x.amountDecline + x.amountApproved
            }
            my.calcDeclineRate(x);
        }
    }
}];

export default ctrl;