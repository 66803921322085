import angular from 'angular';

require('angular-ui-mask');
require('@farzoom/fz-address');
require('@farzoom/fz-modal');
require('@farzoom/fz-select-bank');
require('../../components/cSelectCompany');
require('@farzoom/fz-task-form-parts');
require('@farzoom/fz-to-date');
require('@farzoom/fz-to-money');
require('@farzoom/fz-to-number');

import cAttribute from './cAttribute';
import cAttributeGroup from './cAttributeGroup';
import cAttributes from './cAttributes';
import fzpSvcAttribute from './fzSvcAttribute';
import fzCAttributeBank from './cAttributeBank';
import fzCAttributeCompany from './cAttributeCompany';
import cAttributeGroupDetails from './cAttributeGroupDetails';
import dPatternValidation from './dPatternValidator';

const moduleName = 'com.farzoom.pear.cAttributes';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectBank',
        'com.farzoom.common.fzSelectCompany',
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts',
        'ui.mask'
    ])
    .directive('fzpCAttribute', cAttribute)
    .directive('fzpCAttributeBank', fzCAttributeBank)
    .directive('fzpCAttributeCompany', fzCAttributeCompany)
    .directive('fzpCAttributeGroup', cAttributeGroup)
    .directive('fzpCAttributes', cAttributes)
    .directive('fzpCAttributeGroupDetails', cAttributeGroupDetails)
    .service('fzpSvcAttribute', fzpSvcAttribute)
    .directive('fzpPattern', dPatternValidation)

export default moduleName;
