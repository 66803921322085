const ctrl = function ($scope, fzpSvcAttribute) {
    const my = this;

    my.model = {};
    my.attributeContext = null;

    my.evaluateExpression = function (expressionString) {
        if (!my.attributeContext) return;
        return fzpSvcAttribute.evaluateExpression(expressionString, my.attributeContext);
    };

    $scope.$watch('model', function (model) {
        if (model) {
            my.attributeContext = fzpSvcAttribute.createContext(model);
            if (model.attributes) {
                my.model = model.attributes;
            } else {
                my.model = model;
            }
        }
    });
};

ctrl.$inject = ['$scope', 'fzpSvcAttribute'];
export default ctrl;
