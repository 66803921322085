import angular from 'angular';
import fzpCOrder from './fzpCOrder';

const moduleName = 'com.farzoom.pear.cOrder';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCOrder', fzpCOrder)

export default moduleName;
