import angular from 'angular';

import cDownloadCam from './cDownloadCam';

const moduleName = 'com.farzoom.coconut.fzFiles'

angular.module(moduleName, [])
    .directive('fzpDownloadCam', cDownloadCam)

export default moduleName;
