import angular from 'angular';
import component from './fzpCProductKik';
import component1 from './fzpCProductKikRepaymentSchedule';
import component2 from './fzpCProductKikAcct';
import component3 from './fzpCProductKikContracts';
import './styles.css';

const moduleName = 'com.farzoom.pear.fzpCProductKik';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCProductKik', component)
    .directive('fzpCProductKikRepaymentSchedule', component1)
    .directive('fzpCProductKikAcct', component2)
    .directive('fzpCProductKikContracts', component3)

export default moduleName;
