import angular from 'angular';
import component from './fzpCProductLimitSum';
import componentSet from './fzpCProductLimit';

const moduleName = 'com.farzoom.pear.fzpCProductLimit';

angular.module(moduleName, [
'com.farzoom.common.fzToDate',
'com.farzoom.common.fzToMoney',
'com.farzoom.common.fzToNumber'
])
.directive('fzpCProductLimit', componentSet)
.directive('fzpCProductLimitSum', component)

export default moduleName;