const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.removePayment = function (item) {
        const index = $scope.model.payments.indexOf(item);
        if (index > -1) $scope.model.payments.splice(index, 1);
    };

    my.addPayment = function () {
        if (!$scope.model.payments) $scope.model.payments = [];
        $scope.model.payments.push({});
    };
    
    my.checkPayment = function () {
        return !$scope.model.isInstalment || ($scope.model.payments && $scope.model.payments.length>0)
    }

}];

export default ctrl;
