import './styles.css';

export default ['$modalInstanse', '$modalParams', function ($modalInstanse, $modalParams) {
    const my = this;

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.diff = $modalParams.diff;
}];
