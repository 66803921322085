import selectLotTmpl from "./select-lot/template.html";
import selectLotCtrl from "./select-lot/controller";

import {
    today,
    calcDuration,
    mapServiceLotToModel
} from './utils';

const ctrl = ['$scope', 'fzModal', '$http', 'camunda', function ($scope, fzModal, $http, camunda) {
    const my = this;
    my.type = 'График погашения основного долга';

    $scope.maxDuration = angular.isDefined($scope.maxDuration) ? $scope.maxDuration : 2200;
    my.ref = {
        auctionType: [{
                code: 'public',
                name: 'Открытый'
            },
            {
                code: 'private',
                name: 'Закрытый'
            },
        ]
    };
    my.state = {
        isZgrSearching: false,
        searchResult: null,
        zgrNotFound: false,
        isManualPurchase: false,
        showEditDetails: false,
    }

    my.calcRepaymentAmount = function(){
        if($scope.model.data && $scope.model.data.kik && $scope.model.data.kik.repaymentPercent && $scope.model.data.amount){
            $scope.model.data.kik.repaymentAmount = Math.round($scope.model.data.amount * $scope.model.data.kik.repaymentPercent * 100) / 1000000;
        }
    }

    $scope.$watch('model', function () {
        // reset
        my.state = {
            isZgrSearching: false,
            searchResult: null,
            zgrNotFound: false,
            isManualPurchase: false,
            showEditDetails: false,
        }
        $scope.model.fio = '';
        $scope.model.email = '';
        $scope.model.workPhone = '';
        $scope.model.pattern = '+7(999)999-99-99';

        if($scope.model.data && $scope.model.data.purchase && $scope.model.data.purchase.lots && $scope.model.data.purchase.lots.length == 1){
            if(!$scope.model.data.purchase.lots[0].lotNumber) $scope.model.data.purchase.lots = [];
        }

    });

    my.filterLaw = item => {
        const res = !($scope.model.data.bankGuaranteeTypeRefId === 'participation' && item.code === '185-615') &&
            !($scope.model.data.bankGuaranteeTypeRefId === 'returnAdvance' && item.code === '44')
        return res;
    }

    function search(purchaseNumber) {
        return $http.get('/api/zgr-search?purchaseNumber=' + purchaseNumber).then(resp => resp.data);
    };

    my.zgrSearch = () => {
        my.state.isManualPurchase = false;
        my.state.isZgrSearching = true;
        my.state.searchResult = null;

        search($scope.model.data.purchase.purchaseNumber).then(resp => {
            if (!resp.lots || !resp.lots.length) {
                my.state.zgrNotFound = true;
            } else {
                my.state.zgrNotFound = false;
                my.state.searchResult = resp;
                my.state.showEditDetails = true;

                $scope.model.data.purchase.marketPlace = resp.etp.name;
                $scope.model.data.purchase.law = resp.federalLawRefId;
                $scope.model.data.purchase.tenderType = resp.placingWay;
                $scope.model.data.purchase.tenderDate = resp.publishDate;
                $scope.model.data.purchase.purchaseLink = resp.url;
                $scope.model.data.purchase.purchaseSubject = resp.subject;

                if (resp.lots.length === 1) {
                    $scope.model.data.purchase.lots = resp.lots.map(lot => mapServiceLotToModel(lot, $scope.model.data.bankGuaranteeTypeRefId));
                } else {
                    my.selectLot();
                }
            }
            my.state.isZgrSearching = false;
        }).catch(err => {
            my.state.zgrNotFound = true;
            my.state.isZgrSearching = false;
        });
    }

    my.setManualPurchase = () => {
        my.state.searchResult = null;
        my.state.isManualPurchase = true;
        my.state.showEditDetails = true;
        my.state.zgrNotFound = false;
        $scope.model.data.purchase = {
            purchaseNumber: $scope.model.data.purchase.purchaseNumber,
            lots: [{
                lotNumber: "1",
                beneficiaries: [{}]
            }]
        };
    }

    my.editPurchase = () => {
        my.state.searchResult = null;
        my.state.isManualPurchase = false;
        my.state.showEditDetails = false;
        my.state.zgrNotFound = false;
        $scope.model.data.purchase = {
            purchaseNumber: $scope.model.data.purchase.purchaseNumber
        };
    }

    my.addProduct = (lot) => {
        if (!lot.products) lot.products = [];
        lot.products.push({})
    }

    my.removeProduct = (lot, prod) => {
        const index = lot.products.indexOf(prod);
        if (index === -1) return;
        lot.products.splice(index, 1);
    }

    my.addIkz = (lot) => {
        if (!lot.ikzList) lot.ikzList = [];
        lot.ikzList.push("");
    }

    my.removeIkz = (lot, index) => {
        lot.ikzList.splice(index, 1);
    }

    my.selectLot = function () {
        fzModal.open({
            windowClass: 'fz-modal__window-lots',
            template: selectLotTmpl,
            controller: selectLotCtrl,
            controllerAs: 'my',
            scope: {
                modalTitle: 'Выберите лоты'
            },
            params: {
                lots: my.state.searchResult.lots
            }
        }).closed.then(result => {
            if (result.resultCode != 'OK') return;
            if (!result.selectedLots || !result.selectedLots.length) {
                $scope.model.data.purchase.lots = [];
            } else {
                $scope.model.data.purchase.lots = result.selectedLots.map(lot => mapServiceLotToModel(lot, $scope.model.data.bankGuaranteeTypeRefId));
            }
        });
    }

    // Калькулируемое поле - %% снижения от начальной цены контракта
    my.calcDeltaPrice = function calcDeltaPrice(lot) {
        let deltaPrice = null;
        if (!lot.startAmount || lot.startAmount == 0) {
            deltaPrice = null;
        } else if (!lot.finalAmount || lot.finalAmount == 0) {
            deltaPrice = null;
        } else {
            deltaPrice = Math.round(((lot.startAmount - lot.finalAmount) / lot.startAmount * 100) * 100) / 100;
        }
        return deltaPrice;
    }

    my.showIsRequiredSecurityForGuaranteePeriod = () => $scope.model.data.bankGuaranteeTypeRefId === 'execution'; // Гарантийный период
    my.showIsRequiredIndisputableDebiting = () => ['44', '223', '615'].includes($scope.model.data.purchase.law); // Бесспорное списание
    my.showIsIncludedForfeit = () => $scope.model.data.bankGuaranteeTypeRefId === 'execution' || $scope.model.data.bankGuaranteeTypeRefId === 'period'; // Неустойки
    my.showPrepaymentExists = () => $scope.model.data.bankGuaranteeTypeRefId === 'execution'; // Aванс
    my.showGuaranteePeriod = () => $scope.model.data.bankGuaranteeTypeRefId === 'execution'; // Гарантийный период
    my.showGuaranteeExecution = () => $scope.model.data.bankGuaranteeTypeRefId === 'participation'; // Гарантия на участие

    my.calcDuration = (startDate, endDate, maxDuration) => {
        let days = calcDuration(startDate, endDate);
        if (!days || days > maxDuration) {
            return null;
        } else {
            return days;
        }
    };

    my.checkDurationDays = (endDate) => {
        var startDate = new Date().toISOString();
        let days = calcDuration(startDate, endDate);
        if (!days || days < 45) {
            return true;
        } else {
            return false;
        }
    };

    my.calcAmount = (maxAmount) => {
        if(!$scope.model.data && !$scope.model.data.amount) return null;
        var contractAmount = $scope.model.data.purchase && $scope.model.data.purchase.lots && $scope.model.data.purchase.lots.length > 0 ? $scope.model.data.purchase.lots[0].finalAmount : 0;

        if($scope.model.data.kik && $scope.model.data.kik.isConcluded) contractAmount = contractAmount - $scope.model.data.kik.finishWorksAmount;

        if($scope.model.data.kik && $scope.model.data.kik.isPrepayment) contractAmount = contractAmount - $scope.model.data.kik.prepaymentAmount;

        if($scope.model.data.amount > (contractAmount * 0.6)  || $scope.model.data.amount > maxAmount) return null;

        return true;
    };

    my.today = () => today();

    my.calcLotConAmount = lot => (lot.beneficiaries || []).map(x => x.conAmount).reduce((a, b) => a + b, 0);
    my.calcLotBgAmount = lot => (lot.beneficiaries || []).map(x => x.bgAmount).reduce((a, b) => a + b, 0);
    my.calcFullBgAmount = () => (((($scope.model || {}).data || {}).purchase || {}).lots || []).map(lot => my.calcLotBgAmount(lot)).reduce((a, b) => a + b, 0);

    my.addCustomer = lot => {
        if (!lot.beneficiaries) lot.beneficiaries = [];
        lot.beneficiaries.push({});
    }

    my.removeCustomer = (lot, x) => {
        const i = lot.beneficiaries.indexOf(x);
        lot.beneficiaries.splice(i, 1);
    }

    my.addLot = () => {
        if (!$scope.model.data.purchase.lots) $scope.model.data.purchase.lots = [];
        $scope.model.data.purchase.lots.push({
            ikzList: [""],
            products: [{}]
        });
    }

    my.removeLot = lot => {
        const i = $scope.model.data.purchase.lots.indexOf(lot);
        $scope.model.data.purchase.lots.splice(i, 1);
    }

    /**
     * 44-ФЗ. Статья 37. Антидемпинговые меры при проведении конкурса и аукциона
     * 615-ФЗ. Статья 90.
     */
    function isAntidumping(lot) {
        const p = $scope.model.data;
        return p.purchase.law == '615' || (p.purchase.law == '44' &&
            p.bankGuaranteeTypeRefId == 'execution' &&
            lot.startAmount > 1500000000); // 15 млн. рублей
    }

    function isBigDecrease(lot) {
        // снижение на 25 и более процентов от НМЦ
        return lot.finalAmount !== null && !!lot.startAmount && lot.finalAmount / lot.startAmount <= 0.75;
    }

    my.validateAntidumpingAmount = function (lot) {
        return true; // Проверка на антидемпинговые меры отключена по требованию банка
        /*
        if (!isAntidumping(lot) || !isBigDecrease(lot)) return true;
        if (lot.beneficiaries.some(x => x.conAmount === null)) return true;
        const conAmount = my.calcLotConAmount(lot);

        const guaranteeAmount = conAmount * 1.5;
        const actualLotBgAmount = my.calcLotBgAmount(lot);
        const amountOk = actualLotBgAmount >= guaranteeAmount;
        const prepaymentOk = !lot.contractConditions.prepaymentExists || actualLotBgAmount >= lot.contractConditions.prepaymentAmount;
        return amountOk && prepaymentOk;
        */
    }

    my.isReturnAdvanced44 = function () {
        const m = $scope.model;
        return m && m.data && m.data.purchase && m.data.purchase.law === "44" && m.data.bankGuaranteeTypeRefId === "returnAdvance";
    }

    my.startAmountChange = function(lot){
        if(my.showGuaranteeExecution()){
            lot.finalAmount = lot.startAmount;
        }
    }

    $scope.$watch('model.data.kik.repaymentScheduleType', (newType, oldType) => {
        if ($scope.ngReadonly) return;
        if (newType == oldType) return;
        if(!$scope.model.data.kik) $scope.model.data.kik = {};
        if(!$scope.model.data.kik.repaymentSchedules) $scope.model.data.kik.repaymentSchedules = null;
        $scope.model.data.kik.repaymentSchedules = null;
    });
}];

export default ctrl;