import angular from 'angular';

require('../fzp-c-files');
require('@farzoom/fz-files');
require('@farzoom/fz-to-date');
require('@farzoom/fz-to-money');
require('@farzoom/fz-to-number');

import component from './fzpCDocuments';
import component2 from './fzpCDocumentsAct';
import component1 from './fzpChangeCommissionDocuments';

const moduleName = 'com.farzoom.pear.cDocuments';
const componentName = 'fzpCDocuments';
const componentNameAct = 'fzpCDocumentsAct';


angular.module(moduleName, [
        'com.farzoom.coconut.fzFiles',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzFiles'
    ])
    .directive(componentName, component)
    .directive(componentNameAct, component2)
    .directive('fzpChangeCommissionDocuments', component1)

export default moduleName;
