import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

export default ['fzModal', function (fzModal) {
    function open(person, header, readonly, poa) {
        return fzModal.open({
            windowClass: 'fz-c-poa__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                header: header,
                readonly: readonly,
                person: person || {},
                poa: poa
            }
        }).closed;
    }

    this.open = open;
}];
