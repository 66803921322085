import angular from 'angular';
import fzpCDiff from './fzpCDiff';

const moduleName = 'com.farzoom.pear.cDiff';

angular.module(moduleName, [
    ])
    .directive('fzpCDiff', fzpCDiff)

export default moduleName;
