export default ['$scope', '$http', '$timeout', function ($scope, $http, $timeout) {
    const my = this;
    my.refLm = {};
    my.refMdm = {};
    my.refPinEq = {};
    my.refName = {};
    my.refSegment= {};

    my.refLmDict = [];
    my.refMdmDict = [];
    my.refPinEqDict = [];
    my.refNameDict = [];
    my.refSegmentDict= [];

    my.refLmTemp = {};
    my.refMdmTemp = {};
    my.refPinEqTemp = {};
    my.refNameTemp = {};
    my.refSegmentTemp = {};
    my.refPinEqActualDate = {};

    my.changeRef = function(item){
        item.mdmId = '';

        my.refLmTemp[item.companyId] = my.refLm[item.companyId] &&
                                       my.refLm[item.companyId][item.name] &&
                                       my.refLm[item.companyId][item.name][item.pinEq] &&
                                       my.refLm[item.companyId][item.name][item.pinEq][item.mdmId] &&
                                       my.refLm[item.companyId][item.name][item.pinEq][item.mdmId][item.segmentMdm] ?
                                       my.refLm[item.companyId][item.name][item.pinEq][item.mdmId][item.segmentMdm].filter(onlyUnique) : [];

        my.refMdmTemp[item.companyId] = my.refMdm[item.companyId] &&
                                        my.refMdm[item.companyId][item.name] &&
                                        my.refMdm[item.companyId][item.name][item.pinEq] &&
                                        my.refMdm[item.companyId][item.name][item.pinEq][item.lmId] &&
                                        my.refMdm[item.companyId][item.name][item.pinEq][item.lmId][item.segmentMdm] ?
                                        my.refMdm[item.companyId][item.name][item.pinEq][item.lmId][item.segmentMdm].filter(onlyUnique) : [];

        my.refPinEqTemp[item.companyId] = my.refPinEq[item.companyId] &&
                                          my.refPinEq[item.companyId][item.name] &&
                                          my.refPinEq[item.companyId][item.name][item.mdmId] &&
                                          my.refPinEq[item.companyId][item.name][item.mdmId][item.lmId] &&
                                          my.refPinEq[item.companyId][item.name][item.mdmId][item.lmId][item.segmentMdm] ?
                                          my.refPinEq[item.companyId][item.name][item.mdmId][item.lmId][item.segmentMdm].filter(onlyUnique) : [];

        my.refNameTemp[item.companyId] = my.refName[item.companyId] &&
                                         my.refName[item.companyId][item.pinEq] &&
                                         my.refName[item.companyId][item.pinEq][item.mdmId] &&
                                         my.refName[item.companyId][item.pinEq][item.mdmId][item.lmId] &&
                                         my.refName[item.companyId][item.pinEq][item.mdmId][item.lmId][item.segmentMdm] ?
                                         my.refName[item.companyId][item.pinEq][item.mdmId][item.lmId][item.segmentMdm].filter(onlyUnique) : [];

        my.refSegmentTemp[item.companyId] = my.refSegment[item.companyId] &&
                                            my.refSegment[item.companyId][item.name] &&
                                            my.refSegment[item.companyId][item.name][item.pinEq] &&
                                            my.refSegment[item.companyId][item.name][item.pinEq][item.mdmId] &&
                                            my.refSegment[item.companyId][item.name][item.pinEq][item.mdmId][item.lmId] ?
                                            my.refSegment[item.companyId][item.name][item.pinEq][item.mdmId][item.lmId].filter(onlyUnique) : [];

//        if(my.refLmTemp[item.companyId].length == 2) item.lmId = my.refLmTemp[item.companyId][1];
//        if(my.refPinEqTemp[item.companyId].length == 2) item.pinEq = my.refPinEqTemp[item.companyId][1];
//        if(my.refNameTemp[item.companyId].length == 2) item.name = my.refNameTemp[item.companyId][1];
//        if(my.refSegmentTemp[item.companyId].length == 2) item.segmentMdm = my.refSegmentTemp[item.companyId][1];
        if(item.name != '' || item.pinEq != '' || item.segmentMdm != '' || item.lmId != '') item.mdmId = my.refMdm[item.companyId][item.name][item.pinEq][item.lmId][item.segmentMdm].length > 1 ? my.refMdm[item.companyId][item.name][item.pinEq][item.lmId][item.segmentMdm][1] : my.refMdm[item.companyId][item.name][item.pinEq][item.lmId][item.segmentMdm][0];
        item.pinEqActualDate = my.refPinEqActualDate[item.pinEq];
    }

    function convertSegmentToAft(mdmClientSegment) {
        switch (mdmClientSegment) {
            case 'ClientCategorySKS1':
                return 'ММБ';
            case 'ClientCategorySKS8':
            case 'ClientCategorySKS18':
                return 'СБ';
            case 'ClientCategorySKS2':
            case 'ClientCategorySKS3':
            case 'ClientCategorySKS4':
            case 'ClientCategorySKS5':
            case 'ClientCategorySKS6':
            case 'ClientCategorySKS9':
                return 'КИБ';
        }
        return '';
    }

     my.fillRef = function(item){
        my.refLm[item.companyId] = {};
        my.refMdm[item.companyId] = {};
        my.refPinEq[item.companyId] = {};
        my.refSegmentTemp[item.companyId] = {};
        my.refName[item.companyId]  = {};

        item.mdmClientData.forEach(function(data){
            data.idMdm.unshift('');
            data.idEq.unshift({id:'', actualDate:''});
            data.idLm.unshift('');
            data.segments.unshift('');

            my.refLmDict = my.refLmDict.concat(data.idLm);
            my.refMdmDict = my.refMdmDict.concat(data.idMdm);
            //my.refPinEqDict = my.refPinEqDict.concat(data.idEq);
            my.refNameDict = my.refNameDict.concat([data.name]);
            my.refSegmentDict = my.refSegmentDict.concat(data.segments);


            data.idEq.forEach(function(itemIdEq){
                var idEq =  itemIdEq.id;
                my.refPinEqDict.push(itemIdEq.id);
                if(!my.refPinEqActualDate[itemIdEq.id]) my.refPinEqActualDate[itemIdEq.id] = itemIdEq.actualDate;
                if(!my.refLm[item.companyId]) my.refLm[item.companyId] = {};
                if(!my.refLm[item.companyId][data.name]) my.refLm[item.companyId][data.name] = {};
                if(!my.refLm[item.companyId][data.name][idEq]) my.refLm[item.companyId][data.name][idEq] = {};
                if(!my.refLm[item.companyId]['']) my.refLm[item.companyId][''] = {};
                if(!my.refLm[item.companyId][''][idEq]) my.refLm[item.companyId][''][idEq] = {};
                if(!my.refMdm[item.companyId]) my.refMdm[item.companyId] = {};
                if(!my.refMdm[item.companyId][data.name]) my.refMdm[item.companyId][data.name] = {};
                if(!my.refMdm[item.companyId][data.name][idEq]) my.refMdm[item.companyId][data.name][idEq] = {};
                if(!my.refMdm[item.companyId]['']) my.refMdm[item.companyId][''] = {};
                if(!my.refMdm[item.companyId][''][idEq]) my.refMdm[item.companyId][''][idEq] = {};
                if(!my.refName[item.companyId]) my.refMdm[item.companyId] = {};
                if(!my.refName[item.companyId][idEq]) my.refName[item.companyId][idEq] = {};
                if(!my.refSegment[item.companyId]) my.refSegment[item.companyId]= {};
                if(!my.refSegment[item.companyId][data.name]) my.refSegment[item.companyId][data.name]= {};
                if(!my.refSegment[item.companyId][data.name][idEq]) my.refSegment[item.companyId][data.name][idEq]= {};
                if(!my.refSegment[item.companyId]['']) my.refSegment[item.companyId]['']= {};
                if(!my.refSegment[item.companyId][''][idEq]) my.refSegment[item.companyId][''][idEq]= {};

                data.idMdm.forEach(function(idMdm){
                    if(!my.refPinEq[item.companyId]) my.refPinEq[item.companyId] = {};
                    if(!my.refPinEq[item.companyId][data.name]) my.refPinEq[item.companyId][data.name] = {};
                    if(!my.refPinEq[item.companyId][data.name][idMdm]) my.refPinEq[item.companyId][data.name][idMdm] = {};
                    if(!my.refPinEq[item.companyId]['']) my.refPinEq[item.companyId][''] = {};
                    if(!my.refPinEq[item.companyId][''][idMdm]) my.refPinEq[item.companyId][''][idMdm] = {};

                    if(!my.refLm[item.companyId][data.name][idEq][idMdm]) my.refLm[item.companyId][data.name][idEq][idMdm] = {};
                    if(!my.refLm[item.companyId][''][idEq][idMdm]) my.refLm[item.companyId][''][idEq][idMdm] = {};
                    if(!my.refName[item.companyId][idEq][idMdm]) my.refName[item.companyId][idEq][idMdm]= {};
                    if(!my.refSegment[item.companyId][data.name][idEq][idMdm]) my.refSegment[item.companyId][data.name][idEq][idMdm]= {};
                    if(!my.refSegment[item.companyId][''][idEq][idMdm]) my.refSegment[item.companyId][''][idEq][idMdm]= {};

                    data.segments.forEach(function(seg){
                        var segment = convertSegmentToAft(seg);
                        if(!my.refLm[item.companyId][data.name][idEq][idMdm][segment]) my.refLm[item.companyId][data.name][idEq][idMdm][segment] = [];
                        if(!my.refLm[item.companyId][''][idEq][idMdm][segment]) my.refLm[item.companyId][''][idEq][idMdm][segment] = [];

                        my.refLm[item.companyId][data.name][idEq][idMdm][segment] = my.refLm[item.companyId][data.name][idEq][idMdm][segment].concat(data.idLm);
                        my.refLm[item.companyId][''][idEq][idMdm][segment] = my.refLm[item.companyId][''][idEq][idMdm][segment].concat(data.idLm);
                    })
                    data.idLm.forEach(function(idLm){
                        if(!my.refMdm[item.companyId][data.name][idEq][idLm]) my.refMdm[item.companyId][data.name][idEq][idLm] = [];
                        if(!my.refMdm[item.companyId][''][idEq][idLm]) my.refMdm[item.companyId][''][idEq][idLm] = [];
                        if(!my.refPinEq[item.companyId][data.name][idMdm][idLm]) my.refPinEq[item.companyId][data.name][idMdm][idLm] = {};
                        if(!my.refPinEq[item.companyId][''][idMdm][idLm]) my.refPinEq[item.companyId][''][idMdm][idLm] = {};
                        if(!my.refName[item.companyId][idEq][idMdm][idLm]) my.refName[item.companyId][idEq][idMdm][idLm] = [];
                        if(!my.refSegment[item.companyId][data.name][idEq][idMdm][idLm]) my.refSegment[item.companyId][data.name][idEq][idMdm][idLm]= [];
                        if(!my.refSegment[item.companyId][''][idEq][idMdm][idLm]) my.refSegment[item.companyId][''][idEq][idMdm][idLm]= [];

                        data.segments.forEach(function(seg){
                            var segment = convertSegmentToAft(seg);
                            my.refSegment[item.companyId][data.name][idEq][idMdm][idLm].push(segment);
                            my.refSegment[item.companyId][''][idEq][idMdm][idLm].push(segment);
                        })

                        data.segments.forEach(function(seg){
                            var segment = convertSegmentToAft(seg);
                            if(!my.refName[item.companyId][idEq][idMdm][idLm][segment]) my.refName[item.companyId][idEq][idMdm][idLm][segment] = [];
                            if(!my.refPinEq[item.companyId][data.name][idMdm][idLm][segment]) my.refPinEq[item.companyId][data.name][idMdm][idLm][segment] = [];
                            if(!my.refPinEq[item.companyId][''][idMdm][idLm][segment]) my.refPinEq[item.companyId][''][idMdm][idLm][segment] = [];
                            if(!my.refMdm[item.companyId][data.name][idEq][idLm][segment]) my.refMdm[item.companyId][data.name][idEq][idLm][segment] = [];
                            if(!my.refMdm[item.companyId][''][idEq][idLm][segment]) my.refMdm[item.companyId][''][idEq][idLm][segment] = [];
                            my.refMdm[item.companyId][data.name][idEq][idLm][segment] = my.refMdm[item.companyId][data.name][idEq][idLm][segment].concat(data.idMdm);
                            my.refMdm[item.companyId][''][idEq][idLm][segment] = my.refMdm[item.companyId][''][idEq][idLm][segment].concat(data.idMdm);
                            my.refName[item.companyId][idEq][idMdm][idLm][segment] = my.refName[item.companyId][idEq][idMdm][idLm][segment].concat(['']);
                            my.refName[item.companyId][idEq][idMdm][idLm][segment] = my.refName[item.companyId][idEq][idMdm][idLm][segment].concat([data.name]);
                            data.idEq.forEach(function(dataEq){
                                my.refPinEq[item.companyId][data.name][idMdm][idLm][segment].push(dataEq.id);// = my.refPinEq[item.companyId][data.name][idMdm][idLm][segment].push(data.idEq);
                                my.refPinEq[item.companyId][''][idMdm][idLm][segment].push(dataEq.id); //= my.refPinEq[item.companyId][''][idMdm][idLm][segment].concat(data.idEq);
                            });
                        });

                    });
                });
            });
        });
     }

    my.refresh = function(){
        $scope.model.mdmClients.forEach(function(item){
            if(!item.pinEq) item.pinEq = '';
            if(!item.pinEqActualDate) item.pinEqActualDate = '';
            if(!item.mdmId) item.mdmId = '';
            if(!item.lmId) item.lmId = '';
            if(!item.name) item.name = '';
            if(!item.segmentMdm) item.segmentMdm = '';

            my.fillRef(item);

            if(!my.refPinEqDict.includes(item.pinEq)) item.pinEq = '';
            if(!my.refMdmDict.includes(item.mdmId)) item.mdmId = '';
            if(!my.refLmDict.includes(item.lmId)) item.lmId = '';
            if(!my.refNameDict.includes(item.name)) item.name = '';
            if(!my.refSegmentDict.includes(item.segmentMdm)) item.segmentMdm = '';
            //if(!my.refPinEqActualDate.includes(item.pinEqActualDate)) item.pinEqActualDate = '';

            my.refLmTemp[item.companyId] = my.refLm[item.companyId] &&
                                           my.refLm[item.companyId][item.name] &&
                                           my.refLm[item.companyId][item.name][item.pinEq] &&
                                           my.refLm[item.companyId][item.name][item.pinEq][item.mdmId] &&
                                           my.refLm[item.companyId][item.name][item.pinEq][item.mdmId][item.segmentMdm] ?
                                           my.refLm[item.companyId][item.name][item.pinEq][item.mdmId][item.segmentMdm].filter(onlyUnique) : [];

            my.refMdmTemp[item.companyId] = my.refMdm[item.companyId] &&
                                            my.refMdm[item.companyId][item.name] &&
                                            my.refMdm[item.companyId][item.name][item.pinEq] &&
                                            my.refMdm[item.companyId][item.name][item.pinEq][item.lmId] &&
                                            my.refMdm[item.companyId][item.name][item.pinEq][item.lmId][item.segmentMdm] ?
                                            my.refMdm[item.companyId][item.name][item.pinEq][item.lmId][item.segmentMdm].filter(onlyUnique) : [];

            my.refPinEqTemp[item.companyId] = my.refPinEq[item.companyId] &&
                                              my.refPinEq[item.companyId][item.name] &&
                                              my.refPinEq[item.companyId][item.name][item.mdmId] &&
                                              my.refPinEq[item.companyId][item.name][item.mdmId][item.lmId] &&
                                              my.refPinEq[item.companyId][item.name][item.mdmId][item.lmId][item.segmentMdm] ?
                                              my.refPinEq[item.companyId][item.name][item.mdmId][item.lmId][item.segmentMdm].filter(onlyUnique) : [];

            my.refNameTemp[item.companyId] = my.refName[item.companyId] &&
                                             my.refName[item.companyId][item.pinEq] &&
                                             my.refName[item.companyId][item.pinEq][item.mdmId] &&
                                             my.refName[item.companyId][item.pinEq][item.mdmId][item.lmId] &&
                                             my.refName[item.companyId][item.pinEq][item.mdmId][item.lmId][item.segmentMdm] ?
                                             my.refName[item.companyId][item.pinEq][item.mdmId][item.lmId][item.segmentMdm].filter(onlyUnique) : [];

            my.refSegmentTemp[item.companyId] = my.refSegment[item.companyId] &&
                                                my.refSegment[item.companyId][item.name] &&
                                                my.refSegment[item.companyId][item.name][item.pinEq] &&
                                                my.refSegment[item.companyId][item.name][item.pinEq][item.mdmId] &&
                                                my.refSegment[item.companyId][item.name][item.pinEq][item.mdmId][item.lmId] ?
                                                my.refSegment[item.companyId][item.name][item.pinEq][item.mdmId][item.lmId].filter(onlyUnique) : [];

            if(item.pinEq) item.pinEqActualDate = my.refPinEqActualDate[item.pinEq];
        });
    }

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    my.refresh();
}];