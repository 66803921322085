function validate(person, config, fzpSvcAttribute) {
    const d = person;
    if (!d) return false;
    const required = field => !!config[field] && !!config[field].required;
    const show = field => !!config[field] && !!config[field].show;
    const isRf = !config.citizenship || !config.citizenship.show || d.citizenship === '643'; //'rf';

    return !!d.lastName && !!d.firstName && !!d.secondName &&
        (!required('inn') || !!d.inn) &&
        (!show('inn') || !d.inn || d.inn.length == 12) &&
        (!required('birthDate') || !!d.birthDate) &&
        (!required('birthPlace') || !!d.birthPlace) &&
        (!required('registrationAddress') || !!d.registrationAddress) &&
        (!required('actualAddress') || !!d.actualAddress) &&
        (!required('phone') || (d.contacts || []).some(x => !!x.phone)) &&
        (!required('email') || (d.contacts || []).some(x => !!x.email)) &&
        (!required('position') || !!d.position) &&
        (!required('authorizingDoc') || !!d.authorizingDoc) &&
        (!required('citizenship') || !!d.citizenship) &&
        (!required('otherCitizenship') || d.citizenship !== 'other' || !!d.otherCitizenship) &&
        (!required('identityDocument') || (!!d.number && (!isRf || (!!d.series && !!d.issuedDate && !!d.issuingAuthorityCode && !!d.issuingAuthority)))) &&
        (!required('sharePercent') || !!d.sharePercent || d.sharePercent === 0) &&
        (!show('sharePercent') || !d.sharePercent || (d.sharePercent >= 0 && d.sharePercent <= 100)) &&
        (!required('shareSum') || (!!d.shareSum !== 0)) &&
        fzpSvcAttribute.validateGroup(d.personAttributes) &&
        fzpSvcAttribute.validateGroup(d.relationAttributes) &&
        fzpSvcAttribute.validateGroup(d.relationTopAttributes);
};

export default {
    validate: validate
};