import angular from 'angular';
import fzpCAddress from './fzpCAddress';
import fzpCAddresses from './fzpCAddresses';
import fzpCAddressForm from './fzpCAddressForm';
import fzpCAddressInput from './fzpCAddressInput';

const moduleName = 'com.farzoom.pear.fzpCAddress';

angular.module(moduleName, [
        'com.farzoom.pear.cAttributes'
    ])
    .directive('fzpCAddress', fzpCAddress)
    .directive('fzpCAddresses', fzpCAddresses)
    .directive('fzpCAddressForm', fzpCAddressForm)
    .directive('fzpCAddressInput', fzpCAddressInput)

export default moduleName;
