import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

export default ['fzModal', function (fzModal) {
    function open(text, header, readonly) {
        return fzModal.open({
            windowClass: 'fz-c-change-commission__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                text: text,
                header: header,
                readonly: readonly
            }
        }).closed;
    }

    this.open = open;
}];
