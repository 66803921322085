import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.closeOk = function () {
        $modalInstanse.close(my.address);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    // Initialization

    my.address = angular.copy($modalParams.address);
}];