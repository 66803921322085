import tmpl from './tmpl.html';

import {
    today,
    calcDuration
} from './utils';

const cmp = function () {
        var controller = ['$scope', '$http', '$filter', function ($scope, $http, $filter) {
            function validate(){
                if(!$scope.schedules) return false;
                var result = false;

                $scope.totalAmountBalance = Math.round($scope.amount - $scope.repaymentAmount);
                $scope.totalAmount =  Math.round($scope.totalAmount);

                $scope.amountDelta = ($scope.totalAmountBalance - $scope.totalAmount)/100;

                if($scope.type == 'График оплаты комиссии'){
                     if(($scope.totalAmountBalance) != $scope.totalAmount){
                        $scope.errorText = 'Итоговая сумма должна совпадать с суммой предложенной комиссии.';
                        result = true;
                     }
                }

                $scope.schedules.forEach(function(x){
                    if(x.amount < 0){
                        $scope.errorText = 'Сумма платежа не может быть отрицательной.';
                        result = true;
                    }
                });


                $scope.isError = result;
            };

            $scope.isAddNewItem = false;

            $scope.addNew = function(){
                 $scope.newItem = {
                      startDate: null,
                      endDate: null,
                      amount: null
                 };
                 $scope.isAddNewItem = true;
            };

            $scope.addRow = function(newItem){
                $scope.schedules.push(newItem);
                $scope.isAddNewItem = false;
                $scope.newItem = {};
                $scope.calcTotalAmount();
                $scope.fncChange();
            };

            $scope.cancelAdd = function(){
                $scope.isAddNewItem = false;
                $scope.newItem = {};
            };

            $scope.deleteRow = function(item){
                if($scope.schedules.length > 1){
                    var index = 0;
                    for(var i=0; i < $scope.schedules.length; i++) {
                        if(item.amount == $scope.schedules[i].amount && item.startDate == $scope.schedules[i].startDate && item.endDate == $scope.schedules[i].endDate){
                            index = i;
                        }
                    }
                    $scope.schedules.splice(index, 1);
                }else{
                    $scope.schedules[0] = {
                        startDate: $scope.startDate,
                        endDate: $scope.endDate,
                        amount: null
                    };
                }
                $scope.calcTotalAmount();
            };

            $scope.calcTotalAmount = function(){
                $scope.totalAmount = $scope.schedules.length > 0 ? $scope.schedules.map(x => x.amount).reduce((a, b) => a + b) : 0;

                for(var i = 0; i < $scope.schedules.length; i++){
                    $scope.schedules[i].startDate = getMaxStartDate($scope.schedules[i].endDate);
                }
                validate();
            };

            $scope.changeAmount = function(){
                $scope.calcTotalAmount();
                $scope.fncChange();
            }

            function getMaxStartDate(endDate){
                var result = null;
                if(!$scope.schedules) $scope.schedules = [];
                $scope.schedules.forEach(function(item){
                    if(new Date(result) < new Date(item.endDate) && new Date(item.endDate) < new Date(endDate)) result = item.endDate;
                });
                if(!result){
                    $scope.schedules.forEach(function(item){
                        if(new Date(result) < new Date(item.startDate) && new Date(item.startDate) < new Date(endDate)) result = item.startDate;
                    });
                }
                if(!result){
                    result = $scope.startDate;
                }else{
                    result = result < $scope.startDate ? $scope.startDate : result;
                }

                if(result != $scope.startDate) {
                    var dt = new Date(result);
                    result = new Date(dt.setDate(dt.getDate() + 1)).toISOString().substr(0,10);
                }
                return result;
            };

            $scope.getStartDate = function(endDate){
                if(!endDate) return;
                $scope.newItem.startDate = getMaxStartDate(endDate);
            };

            function fncInit(){
                if(!$scope.schedules) return;
                $scope.calcTotalAmount();
            };

            function setMinStartDate(){
                if(!$scope.schedules) return false;
                for(var i = 0; i < $scope.schedules.length; i++){
                    $scope.schedules[i].startDate = $scope.startDate;
                }
                $scope.calcTotalAmount();
            }

            $scope.$watch('schedules', fncInit);
            $scope.$watch('totalAmount', fncInit);
            $scope.$watch('startDate', setMinStartDate);
            $scope.$watch('endDate', validate);
            $scope.$watch('amount', fncInit);
            $scope.$watch('repaymentAmount', fncInit);
        }];

        return {
           restrict: 'E',
           scope: {
              schedules: '=',
              startDate: '=?',
              endDate: '=?',
              repaymentAmount: '=?',
              scheduleType: '=?',
              amount: '=?',
              type: '=',
              tcType: '=',
              ngReadonly: '=',
              fncChange: '&?'
           },
           controller: controller,
           template: tmpl,
           link: function(scope) {
               if(!scope.ngReadOnly &&  (!scope.schedules || scope.schedules && scope.schedules.length == 0)){
                    scope.schedules = [];
                    if(scope.schedules.length == 0){
                        scope.schedules.push({
                            startDate: scope.startDate,
                            endDate: scope.endDate,
                            amount: null
                        });
                    }
               }
           }
       };
};

export default cmp;