import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.closeOk = function () {
        $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.validateSharedPercent = function () {
        return my.readonly ||
            !((my.config || {}).sharePercent || {}).show ||
            !my.data.sharePercent ||
            (my.data.sharePercent >= 0 && my.data.sharePercent <= 100);
    };

    function initData() {
        if (!my.data) {
            my.data = {};
        }

        if (!my.data.company) my.data.company = {};
        my.state.selectedCompany = {
            id: my.data.company.id, // Deprecated. For backwards compatibility only
            INN: my.data.company.INN,
            OGRN: my.data.company.OGRN,
            displayName: my.data.company.displayName,
            fullName: my.data.company.fullName
        };
    };

    $scope.$watch('my.state.selectedCompany', function (newValue) {
        if (!newValue) {
            if (my.data && my.data.company) my.data.company = {};
        } else {
            const okopf = my.data.company && my.data.company.OKOPF;
            my.data.company = {
                id: newValue.id, // Deprecated. For backwards compatibility only
                INN: newValue.INN,
                OGRN: newValue.OGRN,
                displayName: newValue.displayName,
                fullName: newValue.fullName,
                OKOPF: okopf
            };
        }
    });

    // Initialization

    my.state = {
        selectedCompany: null
    };

    my.header = $modalParams.header;
    my.readonly = $modalParams.readonly;
    my.data = angular.copy($modalParams.founder);
    my.config = $modalParams.config;
    my.ref = $modalParams.ref;
    initData();
}];
