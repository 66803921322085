import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', '$http', '$q', function ($scope, $modalInstanse, $modalParams, $http, $q) {
    const my = this;

    my.person = angular.copy($modalParams.person);
    my.header = angular.copy($modalParams.header);
    my.readonly = angular.copy($modalParams.readonly);
    my.poa = angular.copy($modalParams.poa);
    my.checkSave = false;
    my.files = {};
    my.inputFile = {};
    my.mode = 'number';
    my.modeRef = [{
        "code":"number",
        "name":"По номеру"
    },{
        "code":"file",
        "name":"Загрузить файл"
    }];

    my.number = "";

    my.closeOk = function () {
        $modalInstanse.close({refresh:true});
    };

    my.closeCancel = function () {
        if(my.checkSave) {
            deletePoaFile();
        }
        $modalInstanse.close();
    };

    function clearFileInput(el) {
        const $el = $(el);
        $el.wrap('<form>').closest('form').get(0).reset();
        $el.unwrap();
    }

    function saveFile(personId, formData) {
        return $http.post(`/api/cabinet/internal/poa/file?personId=${personId}`, {poaFile: my.files.poaFile,poaFileSignature: my.files.poaFileSignature}, {
              headers: {
                  'Content-Type': 'application/json'
              }
          }).then(res => res.data);
    }

    function deletePoaFile(number, personId) {
        return this.$http.delete(`/api/cabinet/internal/poa/${number}?personId=${personId}`)
    }

    my.uploadFile = function(){
        my.errorMessage = null;
        if (!$scope.model) $scope.model = {};
        $scope.model.poaFiles = null;
        my.isError = false;
        if(my.files && my.files.poaFile &&  my.files.poaFileSignature){
            console.log('Вложены 2 файла')

            var data = {
                poaFile: my.files.poaFile,
                poaFileSignature: my.files.poaFileSignature
            };

            saveFile(my.person.personId, my.files).then(resp => {
                const item = {
                    fileId: resp.fileId,
                    created: resp.created,
                    deletable: resp.deletable,
                    number: resp.number,
                    startDate: resp.startDate,
                    endDate: resp.endDate,
                    status: resp.status == 'ACTIVE' ? 'Действует' : 'Невалидна',
                    issuedByCompany: {}
                };
                if (resp.issuedByCompany !== null) {
                    item.issuedByCompany.name = resp.issuedByCompany.name,
                        item.issuedByCompany.inn = resp.issuedByCompany.inn,
                        item.issuedByCompany.ogrn = resp.issuedByCompany.ogrn,
                        item.issuedByCompany.kpp = resp.issuedByCompany.kpp;
                }
                $scope.model.poaFiles = item;
                my.checkSave = true;
                my.showMchd();
            }).catch(e => {
                my.checkSave = false;
                my.isError = true;
                my.errorMessage = "Произошла ошибка при проверке МЧД";
                if(e && e.data && e.data.message){
                   my.errorMessage = e.data.message;
                }
            });
        }else{
            console.log('Вложены не все файлы')
        }
    }

    my.onFileInputChange = function (input, fileType) {
        if (!input.files.length) return;
        const file = [...input.files][0];
        my.inputFile[fileType] = file;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var fileContents = "";
        reader.onload = function (evt) {
            fileContents = evt.target.result.replace('data:', '').replace(/^.+,/, '');
            $scope.$apply(function () {
                my.files[fileType] = fileContents;
                my.uploadFile();
            });
        }
        reader.onerror = function (evt) {
            $scope.model.poaFiles = {};
            e.errorCode = 'Error save poa file';
            return $q.reject(e);
        }

        //clearFileInput(input);
    };

    my.showMchd = () => {
        if ($scope.model && $scope.model.poaFiles &&$scope.model.poaFiles != null) {
            return true;
        }
        return false;
    }


    function searchPoa(personId, number, inn) {
        return $http.post(`/api/cabinet/internal/poa/${number}/save`, {personId: personId, inn: inn}, {
              headers: {
                  'Content-Type': 'application/json'
              }
          }).then(res => res.data);
    }

    my.searchPoaByNumber = () => {
        my.errorMessage = null;
        if (!$scope.model) $scope.model = {};
        $scope.model.poaFiles = null;
        my.isError = false;
        my.isSearching = true;

        searchPoa(my.person.personId, my.number, my.person.companyInn).then(resp => {
            const item = {
                fileId: resp.fileId,
                created: resp.created,
                deletable: resp.deletable,
                number: resp.number,
                startDate: resp.startDate,
                endDate: resp.endDate,
                status: resp.status == 'ACTIVE' ? 'Действует' : 'Невалидна',
                issuedByCompany: {}
            };
            if (resp.issuedByCompany !== null) {
                item.issuedByCompany.name = resp.issuedByCompany.name,
                item.issuedByCompany.inn = resp.issuedByCompany.inn,
                item.issuedByCompany.ogrn = resp.issuedByCompany.ogrn,
                item.issuedByCompany.kpp = resp.issuedByCompany.kpp;
            }
            $scope.model.poaFiles = item;
            my.checkSave = true;
            my.isSearching = false;
            my.showMchd();
        }).catch(e => {
            my.checkSave = false;
            my.isError = true;
            my.isSearching = false;
            my.errorMessage = "Произошла ошибка при проверке МЧД";
            if(e && e.data && e.data.message){
                my.errorMessage = e.data.message;
            }
        });
    }

}];