import angular from 'angular';

import cSection from './cSection';
import cSwitch from './cSwitch';
import cCompany from './cCompany';
import toNumber from './toNumber';
import cVat from './cVat';

import './cSelectCompany';

export default angular.module('ext.cmps', [])
    .directive('cSection', cSection)
    .directive('cSwitch', cSwitch)
    .directive('fzpCompany', cCompany)
    .directive('toNumber', toNumber)
    .directive('cVat', cVat)
