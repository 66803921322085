const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.state = {
        isIndividual: false,
        schemaId: null
    }

    my.removeStandartRate = function (item) {
        const index = $scope.model.individualSchema.schema.standardRates.indexOf(item);
        if (index > -1) $scope.model.individualSchema.schema.standardRates.splice(index, 1);
    };
    my.addStandartRate = function () {
        if (!$scope.model.individualSchema.schema.standardRates) $scope.model.individualSchema.schema.standardRates = [];
        $scope.model.individualSchema.schema.standardRates.push({});
    };
    //
    my.removeTotalRate = function (item) {
        const index = $scope.model.individualSchema.schema.totalRates.indexOf(item);
        if (index > -1) $scope.model.individualSchema.schema.totalRates.splice(index, 1);
    };
    my.addTotalRate = function () {
        if (!$scope.model.individualSchema.schema.totalRates) $scope.model.individualSchema.schema.totalRates = [];
        $scope.model.individualSchema.schema.totalRates.push({});
    };
    //
    my.removeTotalRateByAmount = function (item) {
        const index = $scope.model.individualSchema.schema.totalRatesByAmount.indexOf(item);
        if (index > -1) $scope.model.individualSchema.schema.totalRatesByAmount.splice(index, 1);
    };
    my.addTotalRateByAmount = function () {
        if (!$scope.model.individualSchema.schema.totalRatesByAmount) $scope.model.individualSchema.schema.totalRatesByAmount = [];
        $scope.model.individualSchema.schema.totalRatesByAmount.push({});
    };
    //
    my.removeTotalDebtRateByAmount = function (item) {
        const index = $scope.model.individualSchema.schema.totalDebtRatesByAmount.indexOf(item);
        if (index > -1) $scope.model.individualSchema.schema.totalDebtRatesByAmount.splice(index, 1);
    };
    my.addTotalDebtRateByAmount = function () {
        if (!$scope.model.individualSchema.schema.totalDebtRatesByAmount) $scope.model.individualSchema.schema.totalDebtRatesByAmount = [];
        $scope.model.individualSchema.schema.totalDebtRatesByAmount.push({});
    };

    my.refillFromBase = function () {
        initSchema();
        if ($scope.model && $scope.model.baseSchemaRef && $scope.model.baseSchemaId) {
            for (const item of $scope.model.baseSchemaRef) {
                if (item.id == $scope.model.baseSchemaId) {
                    if (item.schema.standardRates) {
                        for (const r of item.schema.standardRates) {
                            $scope.model.individualSchema.schema.standardRates.push({ fromAmount: r.fromAmount, rate: r.rate });
                        }
                    }
                    if (item.schema.totalRates) {
                        for (const r of item.schema.totalRates) {
                            $scope.model.individualSchema.schema.totalRates.push({ fromCount: r.fromCount, rate: r.rate });
                       }
                    }   
                    if (item.schema.totalRatesByAmount) {
                        for (const r of item.schema.totalRatesByAmount) {
                            $scope.model.individualSchema.schema.totalRatesByAmount.push({ fromAmount: r.fromAmount, rate: r.rate });
                        }
                    }
                    if (item.schema.totalDebtRatesByAmount) {
                        for (const r of item.schema.totalDebtRatesByAmount) {
                            $scope.model.individualSchema.schema.totalDebtRatesByAmount.push({ fromAmount: r.fromAmount, rate: r.rate });
                        }
                    }
                    $scope.model.individualSchema.schema.excessRate = item.schema.excessRate;
                    $scope.model.individualSchema.schema.decreaseRate = item.schema.decreaseRate;
                    $scope.model.individualSchema.schema.includeVAT = item.schema.includeVAT;
                    $scope.model.individualSchema.productType = item.productType;
                    $scope.model.individualSchema.validFrom = item.validFrom;
                    $scope.model.individualSchema.validTo = item.validTo;
                }
            }
        }
    }

    my.isIndividual = function () {
        return my.state.isIndividual;
    };

    my.filterProduct = function () {
        return function (item) {
            // Это на будущее, когда будут разные типы продуктов
            ///  return  (item.productType == my.state.product)   
          return true 
        };
    };

    $scope.$watch('my.state.isIndividual', function (newValue, oldValue) {
        if (newValue) {
            if ($scope.model.baseSchemaId) {
                my.state.schemaId = $scope.model.baseSchemaId;
                $scope.model.baseSchemaId = null;
                // в инд.схеме оставляем все данные из базового тарифа
            }
        }
        else {
            // если УБРАЛИ признак - всё чистим и заново заполняем из базы
            if (my.state.schemaId) {
                $scope.model.baseSchemaId = my.state.schemaId;
                my.refillFromBase();
            }
        }
    });

    function initSchema() {
        $scope.model.individualSchema.schema = {};
        $scope.model.individualSchema.schema.standardRates = []
        $scope.model.individualSchema.schema.totalRates = []
        $scope.model.individualSchema.schema.totalRatesByAmount = []
        $scope.model.individualSchema.schema.totalDebtRatesByAmount = []
        $scope.model.individualSchema.schema.excessRate = null;
        $scope.model.individualSchema.schema.decreaseRate = null;
        $scope.model.individualSchema.schema.includeVAT = null;
        $scope.model.individualSchema.productType = "BG" ;  // пока всегда BG
    }

    my.state.isIndividual = $scope.model && !$scope.model.baseSchemaId;
    if ($scope.model.baseSchemaId)
        {
            my.refillFromBase(); 
        }
}];

export default ctrl;
