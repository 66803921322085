import angular from 'angular';
import cInstalment from './cInstalment';

const moduleName = 'com.farzoom.coconut.cInstalment';

angular.module(moduleName, [
        'com.farzoom.common.fzSvcNumber',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
    ])
    .directive('fzpCInstalment', cInstalment);

export default moduleName;
