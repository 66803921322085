import angular from 'angular';

import tmpl from './template.html';
import './styles.css';

const moduleName = 'com.farzoom.pear.fzpForm';

const fzpForm = function () {
    return {
        restrict: 'E',
        scope: {
            header: '@?',
            setActive: '=?'
        },
        transclude: true,
        template: tmpl
    };
};

angular.module(moduleName, [])
    .directive('fzpForm', fzpForm)

export default moduleName;
