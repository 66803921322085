import './styles.css';
export default ['$scope', '$modalInstanse', '$modalParams','fzpCPoaModal', '$http', function ($scope, $modalInstanse, $modalParams,fzpCPoaModal, $http) {
    const my = this;

    my.closeOk = function () {
        $modalInstanse.close(my.person);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.removeContact = function (item) {
        const index = my.person.contacts.indexOf(item);
        if (index > -1) my.person.contacts.splice(index, 1);
    };

    my.addContact = function () {
        my.person.contacts.push({email: null, phone: null});
    };

    function show(fld) {
        return !!((my.config || {})[fld] || {}).show;
    }

    function search(personId) {
        return $http.get(`/api/cabinet/internal/poa?personId=${personId}`).then(resp => resp.data);
    }

    my.machineReadablePOA = () => {
        if(!my.state) my.state = {};
        if(!$scope.model) $scope.model = {};
        my.state.isManualPurchase = false;
        my.state.isPoaSearching = true;
        my.state.searchResult = null;
        if(my.person && my.person.personId){
            search(my.person.personId).then(resp => {
                if (!resp || !resp.length) {
                    my.state.poaNotFound = true;
                } else {
                    my.state.poaNotFound = false;
                    my.state.searchResult = resp;
                    my.state.showEditDetails = true;
                    const listPoaFiles = [];
                    for (const element of resp) {
                        const item = {
                            fileId: element.fileId,
                            deletable: element.deletable,
                            number: element.number,
                            startDate: element.startDate,
                            endDate: element.endDate,
                            status: element.status == 'ACTIVE' ? 'Действует' : 'Невалидна',
                            issuedByCompany: {}
                        };
                        if (element.issuedByCompany !== null) {
                            item.issuedByCompany.name = element.issuedByCompany.name;
                            item.issuedByCompany.inn = element.issuedByCompany.inn;
                            item.issuedByCompany.ogrn = element.issuedByCompany.ogrn;
                            item.issuedByCompany.kpp = element.issuedByCompany.kpp;
                        }
                        listPoaFiles.push(item);
                    }
                    $scope.model.poaFiles = listPoaFiles;
                }
                my.state.isPoaSearching = false;
            }).catch(err => {
                $scope.model.poaFiles = [];
                my.state.poaNotFound = true;
                my.state.isPoaSearching = false;
            });
        }
    }

    my.showPOA = () => {
        if (validationRelationAttributes()){
            const item = my.person.relationAttributes.items.find((x) => x.attributeId === 'relation.poa.isActing');
            return item.value && item.value.booleanValue;
        }
        return false;
    }

    my.getPoa = function () {
        const m = $scope.model;
        const r = my.ref;
        if (!m || !m.poa) return null;
        if (!r || !r.poa) return m.poa;
        return (r.poa.find(x => x.code === m.poa) || {}).name;
    };

    function validationRelationAttributes() {
        return my.person && my.person.relationAttributes && my.person.relationAttributes.items;
    }

    my.showMachineReadable = () => {
        if (validationRelationAttributes()){
            const item = my.person.relationAttributes.items.find((x) => x.attributeId === 'relation.poa.type');
            var res = item.value && item.value.stringValue && item.value.stringValue === "MACHINE_READABLE";
            if(res){
                my.needLoadMchd = true;
            }
            return res
        }
        return false;
    }

    my.showPaper = () => {
        if (validationRelationAttributes()){
            const item = my.person.relationAttributes.items.find((x) => x.attributeId === 'relation.poa.type');
            return item.value && item.value.stringValue && item.value.stringValue === "PAPER";
        }
        return false;
    }

    my.onButtonAddPOA = function () {
        fzpCPoaModal.open(
            my.person,
            'Добавить МЧД',
            $scope.ngReadonly
        )
            .then(data => {
                if(data && data.refresh){
                    my.machineReadablePOA();
                }
            });
    };

    function deletePoaFile(number, personId) {
        return $http.delete(`/api/cabinet/internal/poa/${number}?personId=${personId}`)
    }

    my.onButtonDeleteClickHandler = function (item) {
        if(item && item.number && my.person && my.person.personId){
            deletePoaFile(item.number, my.person.personId).then(function(){
                const index = $scope.model.poaFiles.indexOf(item);
                $scope.model.poaFiles.splice(index, 1);
            });
        }
    };

    my.onSelect = function (item) {
        if (item) {
            const refData = angular.copy(item.data);

            if (show('inn')) my.person['inn'] = refData['inn'];
            my.person['lastName'] = refData['lastName'];
            my.person['firstName'] = refData['firstName'];
            my.person['secondName'] = refData['secondName'];
            my.person['personId'] = refData['personId'];

            if (show('birthDate')) my.person['birthDate'] = refData['birthDate'];
            if (show('birthPlace')) my.person['birthPlace'] = refData['birthPlace'];
            if (show('identityDocument')) {
                my.person['series'] = refData['series'];
                my.person['number'] = refData['number'];
                my.person['issuingAuthority'] = refData['issuingAuthority'];
                my.person['issuingAuthorityCode'] = refData['issuingAuthorityCode'];
                my.person['issuedDate'] = refData['issuedDate'];
            } 
            if (show('registrationAddress')) my.person['registrationAddress'] = refData['registrationAddress'];

            // contacts
            if ((show('phone') || show('email')) && refData['contacts']) {
                my.person['contacts'] = [];
                refData['contacts'].forEach(x => {
                    const contact = {email: null, phone: null};
                    if (show('phone')) contact['phone'] = x['phone'];
                    if (show('email')) contact['email'] = x['email'];
                    my.person['contacts'].push(contact);
                });
                // Always show at least one line of contacts
                if (!my.person['contacts'].length) my.person['contacts'].push({email: null, phone: null});
            }

            if (show('position')) my.person['position'] = refData['position'];
            if (show('authorizingDoc')) my.person['authorizingDoc'] = refData['authorizingDoc'];
            if (show('citizenship')) my.person['citizenship'] = refData['citizenship'];
            if (show('otherCitizenship')) my.person['otherCitizenship'] = refData['otherCitizenship'];
            if (show('sharePercent')) my.person['sharePercent'] = refData['sharePercent'];
            if (show('shareSum')) my.person['shareSum'] = refData['shareSum'];
            if (show('poa')) my.person['poa'] = refData['poa'];

            fillAttributes(my.person.personAttributes, refData.personAttributes);
            fillAttributes(my.person.relationAttributes, refData.relationAttributes);
            fillAttributes(my.person.relationTopAttributes, refData.relationTopAttributes);
        }
    };

    function fillAttributes(destAttrs, srcAttrs) {
        if (destAttrs && destAttrs.items && destAttrs.items.length && srcAttrs && srcAttrs.items && srcAttrs.items.length) {
            const destMap = new Map();
            destAttrs.items.forEach(attr => destMap.set(attr.attributeId, attr));
            srcAttrs.items.forEach(attr => {
                if (destMap.has(attr.attributeId)) {
                    const destAttr = destMap.get(attr.attributeId);
                    destAttr.value = attr.value;
                    destAttr.values = attr.values;
                }
            });
        }
    };

    my.isRf = function () {
        const isShown = my.config && my.config.citizenship && my.config.citizenship.show;
        return !isShown || my.person.citizenship === '643'; //'rf';
    };

    my.checkINN12 = function () {
        const isShown = my.config && my.config.inn && my.config.inn.show;
        return my.readonly || !isShown || !my.person.inn || my.person.inn.length == 12;
    };

    my.validateSharedPercent = function () {
        return my.readonly ||
            !((my.config || {}).sharePercent || {}).show ||
            !my.person.sharePercent ||
            (my.person.sharePercent >= 0 && my.person.sharePercent <= 100);
    };

    $scope.$watch('my.person.secondName', secondName => my.noSecondName = secondName === '-');
    $scope.$watch('my.needLoadMchd', function(x,y){
        if(x != y && my.needLoadMchd == true){
            my.machineReadablePOA();
        }
    });

    // Initialization

    my.header = $modalParams.header;
    my.readonly = $modalParams.readonly;
    my.person = angular.copy($modalParams.person);
    my.model = angular.copy($modalParams.item);
    my.config = $modalParams.config;
    my.ref = $modalParams.ref;
    my.suggestPersons = $modalParams.suggestPersons;
    my.needLoadMchd = false;

    if (!my.person) my.person = {};
    if (!my.person.contacts) my.person.contacts = [];
    if (!my.person.contacts.length) my.person.contacts.push({email: null, phone: null});
}];