import angular from 'angular';
import cAgentSchema from './cAgentSchema';

const moduleName = 'com.farzoom.coconut.cAgentSchema';

angular.module(moduleName, [
        'com.farzoom.common.fzSvcNumber',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
    ])
    .directive('fzpCAgentSchema', cAgentSchema);

export default moduleName;
