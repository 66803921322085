import angular from 'angular';
import cBankAccountsEditTab from './cBankAccountsEditTab';
import cBankAccountsViewTab from './cBankAccountsViewTab';
import './styles.css';

const moduleName = 'com.farzoom.common.cBankAccounts';

angular.module(moduleName, [
        'ui.mask',
        'com.farzoom.pear.cAttributes',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectBank',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .filter('fzAccountNumberFilter', function () {
        return function (value) {
            if (!value) return value;

            if (value.length === 20) {
                const p1 = value.substr(0, 5);
                const p2 = value.substr(5, 3);
                const p3 = value.substr(8, 1);
                const p4 = value.substr(9, 4);
                const p5 = value.substr(13);
                value = `${p1}.${p2}.${p3}.${p4}.${p5}`;
            }

            return value;
        }
    })
    .directive('fzpCBankAccountsEditTab', cBankAccountsEditTab)
    .directive('fzpCBankAccountsViewTab', cBankAccountsViewTab)

export default moduleName;