import angular from 'angular';
import fzpCPerson from './fzpCPerson';
import fzpCPersonNew from './fzpCPersonNew';
import fzpCFounder from './fzpCFounder';
import fzpCFounderCompany from './fzpCFounderCompany';
import fzpSvcFounderCompanyEdit from './fzpSvcFounderCompanyEdit';
import fzpSvcPersonEdit from './fzpSvcPersonEdit';
import fzpSvcPersonNewEdit from './fzpSvcPersonNewEdit';
import fzpCBeneficiaries from './fzpCBeneficiaries';
import fzpCFounders from './fzpCFounders';
import fzpCGuarantorsList from './fzpCGuarantorsList';
import fzpCGuarantorCompany from './fzpCGuarantorCompany';
import fzpSvcGuarantorCompanyEdit from './fzpSvcGuarantorCompanyEdit';
import fzpSvcGuarantorNewCompanyEdit from './fzpSvcGuarantorNewCompanyEdit';
import fzpCGuarantorsNewList from './fzpCGuarantorsNewList';
import fzpCGuarantorNewCompany from './fzpCGuarantorNewCompany';
import fzpCCompanyDeposit from './fzpCCompanyDeposit';
import fzpSvcCompanyDepositEdit from './fzpSvcCompanyDepositEdit';
import fzpCPersonDeposit from './fzpCPersonDeposit';
import fzpSvcPersonDepositEdit from './fzpSvcPersonDepositEdit';
import fzpCPoaModal from './fzpCPoaModal';
import fzpCSignersInn from './fzpCSignersInn';

const moduleName = 'com.farzoom.pear.fzpCPerson';

angular.module(moduleName, [
        'com.farzoom.pear.cAttributes'
    ])
    .service('fzpSvcPersonDepositEdit', fzpSvcPersonDepositEdit)
    .service('fzpSvcCompanyDepositEdit', fzpSvcCompanyDepositEdit)
    .service('fzpSvcGuarantorCompanyEdit', fzpSvcGuarantorCompanyEdit)
    .service('fzpSvcGuarantorNewCompanyEdit', fzpSvcGuarantorNewCompanyEdit)
    .service('fzpSvcFounderCompanyEdit', fzpSvcFounderCompanyEdit)
    .service('fzpSvcPersonEdit', fzpSvcPersonEdit)
    .service('fzpSvcPersonNewEdit', fzpSvcPersonNewEdit)
    .service('fzpCPoaModal', fzpCPoaModal)
    .directive('fzpCPerson', fzpCPerson)
    .directive('fzpCPersonNew', fzpCPersonNew)
    .directive('fzpCFounder', fzpCFounder)
    .directive('fzpCFounderCompany', fzpCFounderCompany)
    .directive('fzpCBeneficiaries', fzpCBeneficiaries)
    .directive('fzpCFounders', fzpCFounders)
    .directive('fzpCGuarantorsList', fzpCGuarantorsList)
    .directive('fzpCGuarantorCompany', fzpCGuarantorCompany)
    .directive('fzpCGuarantorsNewList', fzpCGuarantorsNewList)
    .directive('fzpCGuarantorNewCompany', fzpCGuarantorNewCompany)
    .directive('fzpCCompanyDeposit', fzpCCompanyDeposit)
    .directive('fzpCPersonDeposit', fzpCPersonDeposit)
    .directive('fzpCSignersInn', fzpCSignersInn)

export default moduleName;
