var _ = require('lodash');

const ctrl = ['$scope', '$element', function ($scope, $element) {
    const my = this;

    function getDraftVarName(doc) {
        return doc.entityId ? `${doc.docType}@${doc.entityId}` : doc.docType;
    };

    my.docXml = {};
    my.docXml.invalid = !$scope.ngReadonly && $scope.ngRequired && my.docXml.signed;

    my.isDocInvalid = function (doc) {
        const draftVarName = getDraftVarName(doc);
        const isNotUploaded = doc.canUpload &&
            doc.isRequired &&
            (!doc.files || !doc.files.filter(f => !f.toBeDeleted).length) &&
            (!$scope.drafts || !$scope.drafts[draftVarName] || !$scope.drafts[draftVarName].length);
        const isNotSigned = $scope.canSign && !my.isFilesSigned(doc);
        return isNotUploaded || isNotSigned;
    };

    my.docDraft = function (doc) {
        const draftVarName = getDraftVarName(doc);
        if (!$scope.drafts) $scope.drafts = {};
        if (!$scope.drafts[draftVarName]) $scope.drafts[draftVarName] = []
        return $scope.drafts[draftVarName];
    };

    my.onChangeHandler = function (doc, drafts) {
        if ($scope.drafts) {
            const draftVarName = getDraftVarName(doc);
            if (drafts && drafts.length) $scope.drafts[draftVarName] = drafts;
            else $scope.drafts[draftVarName] = [];
        }
    };

    my.getEntities = function (entities) {
        if ($scope.canSign) {
            return entities.filter(e => my.getDocsAsArray(e.docs).length);
        }
        return entities;
    };

    my.getDocsAsArray = function (docs) {
        const docsArray = Object.values(docs || {});
        if ($scope.canSign) {
            return docsArray.filter(d => (d.files || []).length);
        }
        return docsArray;
    };

    my.isEntitiesSigned = function () {
        return _.every($scope.model.entities, x => my.isDocsSigned(x));
    }

    my.isDocsSigned = function (entity) {
        const docs = my.getDocsAsArray(entity.docs);
        const result = _.every(docs, x => my.isFilesSigned(x));
        return result;
    }

    my.isFilesSigned = function (doc) {
        const drafts = my.docDraft(doc) || [];
        const draftsNames = drafts.reduce((map, file) => {
            map[file.name] = true;
            return map;
        }, {});
        const result = _.every(doc.files || [], x => {
            return my.fileIsSigned(x) || draftsNames[x.name + '.sig'] || (x.name.includes(".pdf") && draftsNames[x.name])
        });
        return result;
    }

    my.fileIsSigned = function (file) {
        return file.signed || !!file.signInfo;
    }
}];

export default ctrl;
