const ctrl = ['$scope', 'fzpSvcBgTurnover', function ($scope, fzpSvcBgTurnover) {
    const my = this;

    my.changeTurnover = function(){
        var readonly = false;
        fzpSvcBgTurnover.open(
            angular.copy($scope.model || {}),
            'Запрос изменения оборотов',
            readonly,
            $scope.camForm,
            $scope.docDrafts
        )
        .then(data => {
            if (!data) return;
            $scope.model = data.model;
            $scope.model.docs = data.docs;
            $scope.model.turnoverProposed = data.turnoverProposed;
            $scope.docDrafts = data.docDrafts;
            $scope.onSave();
        });
    };
}];

export default ctrl;
