import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';
import _ from 'lodash';

const defaultScopeOptions = {
    showLender: true,
    showAmount: true,
    showCurrency: false,
    canEdit: false,
    canRemove: false,
    canAdd: false,
}

const setOptionsIfNotExits = (target, options) => {
    for(let key in options){
        if(!_.has(target, key)){
            _.set(target, key, _.get(options, key));
        }
    }
}

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;
    my.state = {
        itemsInvalid: []
    }
    setOptionsIfNotExits($scope, defaultScopeOptions);

    my.onButtonAddClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-liabilities__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: {},
                ref: $scope.model.ref
            }
        }).closed.then(result => {
            if (!result) return;
            if (!$scope.model) $scope.model = {};
            if (!$scope.model.data) $scope.model.data = [];
            $scope.model.data.push(result);
        });
    };

    my.onButtonEditClickHandler = function (item) {
        fzModal.open({
            windowClass: 'fz-c-liabilities__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: item,
                ref: $scope.model.ref
            }
        }).closed.then(result => {
            if (!result) return;

            const index = $scope.model.data.indexOf(item);
            if (index > -1) $scope.model.data[index] = result;
        });
    };

    my.onButtonDeleteClickHandler = function (item) {
        const index = $scope.model.data.indexOf(item);
        $scope.model.data.splice(index, 1);
    };

    my.getRefName = function (refName, value) {
        if (!value) return 'нет сведений';
        const m = $scope.model;
        if (!m || !m.ref || !m.ref[refName]) return null;
        const refItem = m.ref[refName].find(x => x.value === value) || {};
        return refItem.name;
    };

    my.validate = function () {
        const x = $scope.model;
        if (!x || !x.data || !x.data.length) return true; // Если нет ни одной записи - это ОК, обязательств может не быть
        my.state.itemsInvalid = x.data.map(d => !validateLiability(d));
        return !my.state.itemsInvalid.some(x => x);
    };

    function validateLiability(d) {
        if (!d) return false;
        return !!d.lenderName && !!d.productTypeRefId && !!d.totalAmount &&
        !!d.currencyCode && !!d.startDate && !!d.endDate && !!d.currentAmount;
    };

    my.isInvalid = function (x) {
        const index = $scope.model.data.indexOf(x);
        return my.state.itemsInvalid[index];
    }

}];

export default ctrl;