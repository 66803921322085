import './styles.css';
import {
    calcDuration
} from './utils';

export default ['$scope', '$q', '$http', '$modalInstanse', '$modalParams', '$timeout', 'camunda', function ($scope, $q, $http, $modalInstanse, $modalParams, $timeout, camunda) {
    const my = this;

    my.closeOk = function () {
        var data = {
            turnoverProposed: my.turnoverProposed,
            model: my.model
        };

        var formData = {
            messageName : 'ORDER_TURNOVER_TO_APPROVE',
            processVariables:{
                StartForm_result:{
                    value: JSON.stringify(data),
                    type: 'Json'
                },
                INITIATOR_GROUP:{
                    value: my.initiatorGroup,
                    type: 'String'
                },
                taskId:{
                    value: $scope.camForm.taskId,
                    type: 'String'
                }
            },
           businessKey: my.orderId
        };

        $http.post('/camunda/api/engine/engine/default/message', formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function(resp){
            my.model.statusChangeTurnover = 'ON_APPROVAL';
            $modalInstanse.close({model: my.model, docs: my.docs, docDrafts: my.docDrafts, turnoverProposed: my.turnoverProposed});
        })
        .catch(e => {
            my.model.statusChangeTurnover = 'ON_APPROVAL';
            $modalInstanse.close({model: my.model, docs: my.docs, docDrafts: my.docDrafts, turnoverProposed: my.turnoverProposed});
            e.errorCode = 'PARSE_FAILED';
        });
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.header = $modalParams.header;
    my.readonly = $modalParams.readonly;
    my.model = angular.copy($modalParams.model);
    my.orderId = angular.copy($modalParams.model.orderId);
    my.initiatorGroup = angular.copy($modalParams.model.initiatorGroup);
    my.docs = angular.copy($modalParams.model.docs);
    my.turnoverProposed = angular.copy($modalParams.model.turnoverProposed);
    my.docDrafts = angular.copy($modalParams.docDrafts);
    $scope.camForm =  $modalParams.camForm;
}];