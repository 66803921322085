import './styles.css';
import {
    calcDuration
} from './utils';

export default ['$scope', '$q', '$http', '$timeout', 'fzSvcNumber', 'camunda', function ($scope, $q, $http, $timeout, fzSvcNumber, camunda) {
    const my = this;

    let timeoutHandler;
    let lookupCanceler = null;
    my.showWaiting = false;

    function debounce(fnc, debounceTimeout) {
        if (timeoutHandler) $timeout.cancel(timeoutHandler);
        timeoutHandler = $timeout(fnc, debounceTimeout);
    };

    camunda.getCurrentUserId().then(function (resp) {
        my.userId = resp;
    });

    my.repaymentAmount = 0;

    my.str = '_' + Math.round((Math.random() + Math.random()) * 10000000);

    my.calcAmountCommValue = function(){
        var res =  Math.round((my.isExcessCommission() ? $scope.model.product.bg.bankCommission.calcAmount : $scope.model.product.bg.bankCommission.proposedAmount) * ($scope.model.product.bg.bankCommission.changeCommissionPercent/10000));
        return res;
    }

    my.isExcessCommission = function(){
        return $scope.model.product.bg.bankCommission.proposedAmount > $scope.model.product.bg.bankCommission.calcAmount;
    }

    my.setManualProposedPercent = function(delta){
        $scope.model.product.bg.bankCommission.proposedPercent = Math.round($scope.model.product.bg.bankCommission.calcPercent + $scope.model.product.bg.bankCommission.calcPercent * delta)
        my.calcCommissionByPropPercent($scope.model.product.bg.bankCommission.proposedPercent);
    }

    my.checkHideUser = function(){
        if(!my.userId) return true;
        if(!$scope.model.hideCommissionUser) $scope.model.hideCommissionUser = [];
        return $scope.model.hideCommissionUser.includes(my.userId);
    }

    my.calcCommissionByPropAmount = function(pAmount){
        var request = {
            orderId: $scope.model.orderId,
            proposedAmount: pAmount
        };
        if($scope.model.product.bg.bankCommission.isInstallments){
            request.isInstallments = true;
            request.repaymentSchedules = $scope.model.product.bg.bankCommission.repaymentSchedules;
        }
        if($scope.model.product.bg.bankCommission.isChangeCommissionRewards){
            request.isChangeCommissionRewards = true;
            request.changeCommissionPercent = $scope.model.product.bg.bankCommission.changeCommissionPercent;
        }
        if($scope.model.product.bg.bankCommission.isChangeExcessCommissionRewards){
            request.isChangeExcessCommissionRewards = true;
            request.changeCommissionExcessPercent = $scope.model.product.bg.bankCommission.changeCommissionExcessPercent;
        }

        debounce(() => {
            if (lookupCanceler) lookupCanceler.resolve(); // cancel request if it is already in progress
            lookupCanceler = $q.defer();
            my.calcCommission(request, lookupCanceler).then(function (res) {
                done(res);
            });
        }, 1);
    }

    my.calcCommissionByPropPercent = function(pPercent){
        var request = {
            orderId: $scope.model.orderId,
            proposedPercent: pPercent
        };
        if($scope.model.product.bg.bankCommission.isInstallments){
            request.isInstallments = true;
            request.repaymentSchedules = $scope.model.product.bg.bankCommission.repaymentSchedules;
        }
        if($scope.model.product.bg.bankCommission.isChangeCommissionRewards){
            request.isChangeCommissionRewards = true;
            request.changeCommissionPercent = $scope.model.product.bg.bankCommission.changeCommissionPercent;
        }
        if($scope.model.product.bg.bankCommission.isChangeExcessCommissionRewards){
            request.isChangeExcessCommissionRewards = true;
            request.changeCommissionExcessPercent = $scope.model.product.bg.bankCommission.changeCommissionExcessPercent;
        }

        debounce(() => {
            if (lookupCanceler) lookupCanceler.resolve(); // cancel request if it is already in progress
            lookupCanceler = $q.defer();
            my.calcCommission(request, lookupCanceler).then(function (res) {
                done(res);
            });
        }, 1);
    }

    my.calcCommissionInit = function(){
        var request = {
            orderId: $scope.model.orderId
        };
        if($scope.model.product.bg.bankCommission.proposedPercent){
            request.proposedPercent = $scope.model.product.bg.bankCommission.proposedPercent;
        }
        if($scope.model.product.bg.bankCommission.isInstallments){
            request.isInstallments = true;
            request.repaymentSchedules = $scope.model.product.bg.bankCommission.repaymentSchedules;
        }
        if($scope.model.product.bg.bankCommission.isChangeCommissionRewards){
            request.isChangeCommissionRewards = true;
            request.changeCommissionPercent = $scope.model.product.bg.bankCommission.changeCommissionPercent;
        }
        if($scope.model.product.bg.bankCommission.isChangeExcessCommissionRewards){
            request.isChangeExcessCommissionRewards = true;
            request.changeCommissionExcessPercent = $scope.model.product.bg.bankCommission.changeCommissionExcessPercent;
        }

        debounce(() => {
           if (lookupCanceler) lookupCanceler.resolve(); // cancel request if it is already in progress
           lookupCanceler = $q.defer();
           my.calcCommission(request, lookupCanceler).then(function (res) {
               done(res);
           });
        }, 1);
    }

    my.calcCommission = function(request, cancelPromise){
        var url = "/api/common-api-commission/commission/calcdmn";
        my.showWaiting = true;
        return $http.post(url, request, {
           timeout: cancelPromise.promise,
           headers: {
               'Content-Type': 'application/json'
           }
        });
    }

    function done(respData){
        var resp = respData.data;
        $scope.model.product.bg.bankCommission.calcAmount = resp.standard.amount;
        $scope.model.product.bg.bankCommission.calcPercent =  resp.standard.percent;
        if(resp.suggested && (resp.suggested.amount != null || resp.suggested.percent != null)){
            $scope.model.product.bg.bankCommission.proposedAmount = resp.suggested.amount;
            $scope.model.product.bg.bankCommission.proposedPercent = resp.suggested.percent;
        }else{
            $scope.model.product.bg.bankCommission.proposedAmount = null;
            $scope.model.product.bg.bankCommission.proposedPercent = null;
        }
        $scope.model.product.bg.bankCommission.changePercent = resp.changePercent;
        if(resp.reward && (resp.reward.amount != null || resp.reward.percent != null)){
            $scope.model.product.bg.bankCommission.changeCommissionAmount = resp.reward.amount;
            $scope.model.product.bg.bankCommission.changeCommissionPercent = resp.reward.percent;
        }else{
            $scope.model.product.bg.bankCommission.changeCommissionAmount = null;
            $scope.model.product.bg.bankCommission.changeCommissionPercent = null;
        }
        if(resp.excess && (resp.excess.amount != null || resp.excess.percent != null)){
            $scope.model.product.bg.bankCommission.changeCommissionExcessAmount = resp.excess.amount;
            $scope.model.product.bg.bankCommission.changeCommissionExcessPercent = resp.excess.percent;
        }else{
            $scope.model.product.bg.bankCommission.changeCommissionExcessAmount = null;
            $scope.model.product.bg.bankCommission.changeCommissionExcessPercent = null;
        }
        if(resp.isInstallments){
            $scope.model.product.bg.bankCommission.isInstallments = true;
            $scope.model.product.bg.bankCommission.repaymentSchedules = [];
            resp.installment.forEach(function(x){
                $scope.model.product.bg.bankCommission.repaymentSchedules.push({startDate: x.startDate, endDate: x.endDate, amount: x.amount, amountAv: x. amountAv});
            });
        }else{
            $scope.model.product.bg.bankCommission.isInstallments = false;
            $scope.model.product.bg.bankCommission.repaymentSchedules = null;
        }
        my.minRate = resp.minRate;
        $scope.model.product.bg.bankCommission.nettoRate = resp.nettoRate ? resp.nettoRate : 0;
        $scope.model.amountDelivery = resp.amountDelivery ? resp.amountDelivery : 0;
        my.showWaiting = false;
    }
}];