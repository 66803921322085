import _ from 'lodash';

const getPostalCode = data => _.get(data, 'address.postal_code');
const getRegion = data => _.get(data, 'address.region');
const getCity = data => _.get(data, 'address.city');
const getSettlement = data => _.get(data, 'address.settlement');
const getCityDistrict = data => _.get(data, 'address.city_district_with_type');
const getStreet = data => _.get(data, 'address.street');
const getHouse = data => {
    const prefix = _.has(data, 'address.house_type_full') ? _.get(data, 'address.house_type_full') + ' ' : '';
    const value = _.has(data, 'address.house') ? _.get(data, 'address.house') : undefined;
    return value ? (prefix + value) : value;
};
const getBlock = data => {
    const prefix = _.has(data, 'address.block_type_full') ? _.get(data, 'address.block_type_full') + ' ' : '';
    const value = _.has(data, 'address.block') ? _.get(data, 'address.block') : undefined;
    return value ? (prefix + value) : value;
};
const getFlat = data => _.get(data, 'address.flat');

const ctrl = ['$scope', 'fzpSvcAttribute', function ($scope, fzpSvcAttribute) {
    const my = this;

    $scope.$watch('model', () => {
        const model = _.get($scope, 'model');
        my.rows = [
            {
                title: 'Почтовый индекс',
                value: getPostalCode(model)
            },
            {
                title: 'Субъект Российской Федерации',
                value: getRegion(model)
            },
            {
                title: 'Город (волость и т.п.)',
                value: getCity(model)
            },
            {
                title: 'Посёлок',
                value: getSettlement(model)
            },
            {
                title: 'Район города',
                value: getCityDistrict(model)
            },
            {
                title: 'Улица (проспект. переулок и т.д)',
                value: getStreet(model)
            },
            {
                title: 'Дом (владение и т.п.)',
                value: getHouse(model)
            },
            {
                title: 'Корпус (строение и т.п.)',
                value: getBlock(model)
            },
            {
                title: 'Офис (квартира и т.п.)',
                value: getFlat(model)
            },
        ].filter(x => x.value);
    });

    my.isValid = function () {
        if ($scope.ngReadonly) return true;
        const d = $scope.model;
        const cfg = $scope.config || {};
        const show = field => !!cfg[field] && !!cfg[field].show;
        const requiredAddresses = {
            legalAddress: true,
            actualAddress: !cfg.actualAddress || (cfg.actualAddress.show && (cfg.actualAddress || {}).required), // required by default
            postalAddress: (cfg.postalAddress || {}).show && (cfg.postalAddress || {}).required
        };

        const isRequired = $scope.ngRequired && requiredAddresses[d.address.addressTypeRefId];
        const isFilled = d.address.result || (show('equalsLegalAddress') && d.equalsLegalAddress);

        if (!isFilled) return !isRequired;
        if (show('equalsLegalAddress') && d.equalsLegalAddress) return true;
        return !!d.address.result && !!d.addressOwnTypeRefId && fzpSvcAttribute.validateGroup(d);
    };
}];

export default ctrl;
