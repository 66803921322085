import angular from 'angular';
import cBgSelectSourceId from './cBgSelectSourceId';

const moduleName = 'com.farzoom.pear.cBgSelectSourceId';

angular.module(moduleName, [
        'com.farzoom.pear.cAttributes'
    ])
    .directive('cBgSelectSourceId', cBgSelectSourceId);

export default moduleName;
