import angular from 'angular';

const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.isDataFilled = function (model) {
        if ($scope.model && $scope.model.isSetLimit){
            if($scope.model.guaranteeTypes && $scope.model.guaranteeTypes.filter(x => x.value === true).length > 0 && $scope.model.laws && $scope.model.laws.filter(x => x.value === true).length > 0){
                return true;
            }else{
                return false;
            }
        }else{
            return true;
        }
    };

    my.isDataFilledMaxOrderAmount = function (model) {
        if ($scope.model && $scope.model.isSetLimit){
            if($scope.model.maxOrderAmount && $scope.model.maxOrderAmount <= 1000000000){
                return true;
            }else{
                return false;
            }
        }else{
            return true;
        }
    };
}];

export default ctrl;