import _ from 'lodash';

const cmp = function () {
    return {
        require: 'ngModel',
        scope: {
            fzpPattern: '=?'
        },
        link: function(scope, elm, attrs, ctrl) {
            const validate = _.debounce( () => {
                if(scope.fzpPattern){
                    const pattern = scope.fzpPattern.regexp;
                    const regexp = new RegExp(pattern);
                    ctrl.$setValidity('fzpPattern', regexp.test(ctrl.$modelValue));
                }
            }, 50);
            elm.on('blur keyup change', () => {
                scope.$evalAsync(validate);
            })
            validate();
        }
    };
};

export default cmp;
