import angular from 'angular';

function calcTotalAmount(items) {
    return items.reduce((total, item) => total + getCorrectAmount(item.matchedAmount), 0);
}

function getCorrectAmount(value) {
    const v = parseFloat(value);
    return isFinite(v) ? v : 0;
}

function toRub(kopick) {
    return kopick / 100;
}

function toKopick(rub) {
    return Math.round(rub * 100);
}

const ctrl = ['$scope', 'fzpSvcPayments', 'fzpSvcModalPayment', 'fzpSvcModalSelectAcct', function ($scope, service, fzpSvcModalPayment, fzpSvcModalSelectAcct) {
    const my = this;

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    $scope.search = {
        onlyAvailable: true,
        dateFrom: new Date(new Date() - 10 * _MS_PER_DAY).toISOString().substring(0, 10),
        dateTo: new Date().toISOString().substring(0, 10)
    };
    $scope.results = [];
    $scope.items = [];
    $scope.searchFetching = false;
    $scope.itemsFetching = false;
    $scope.totalAmount = 0;

    my.round = item => Math.round(item)

    my.isSelected = item => {
        const i = $scope.items.find(el => el.payment.id === item.id);
        return !!i;
    }

    my.totalAmountClass = () => {
        if ($scope.model.requiredAmount === toKopick($scope.totalAmount)) return 'text-success';
        if ($scope.model.requiredAmount < toKopick($scope.totalAmount)) return 'text-info';
        if ($scope.model.requiredAmount > toKopick($scope.totalAmount)) return 'text-danger';
    }

    my.resetSearch = () => {
        for (let key of Object.keys($scope.search)) {
            delete $scope.search[key];
        }
    }

    my.search = () => {
        if (!$scope.search.dateFrom) return;
        $scope.results.length = 0;
        $scope.searchFetching = true;
        service.search({
            payerINN: !$scope.search.payerINN ? undefined : $scope.search.payerINN,
            purpose: !$scope.search.purpose ? undefined : $scope.search.purpose,
            dateFrom: !$scope.search.dateFrom ? undefined : $scope.search.dateFrom,
            dateTo: !$scope.search.dateTo ? undefined : $scope.search.dateTo,
            fullyMatched: $scope.search.onlyAvailable ? false : null
        }).then(res => {
            $scope.results = angular.copy(res.map(x => {
                x.status = '';
                return x;
            }));
            $scope.searchFetching = false;
        });
    }


    my.loadPayments = () => {
        service.getOrderPaymentsByOrder($scope.model.orderId).then(res =>{
            $scope.model.installments = res.length > 1;
            $scope.model.repaymentSchedules = angular.copy(res.reduce(function(filtered, x){
                if(!$scope.ngReadonly && (x.isNew || x.unmatchedAmount != 0)){
                    filtered.push(x);
                }
                if($scope.ngReadonly && (x.isNew && x.matchedAmount != 0)){
                    filtered.push(x);
                }
                return filtered;
            },[]));
        });
    }

    my.loadItems = () => {
        $scope.items.length = 0;
        $scope.itemsFetching = true;
        service.getMappedPaymentsByOrder($scope.model.orderId).then(res => {
            $scope.items = angular.copy(res.reduce(function(filtered, x){
                if(x.isNew){
                    x.status = 'matched';
                    filtered.push(x);
                }
                return filtered;
            },[]));
            $scope.itemsFetching = false;
        });
        my.loadPayments();
    }

    function calcAddAmount(payment, isFull) {
        if(isFull){
            const delta = $scope.model.repaymentSchedules.map(i=>i.unmatchedAmount).reduce((a,b)=>a+b);
            return Math.min(Math.round(delta * 100) / 100.0, payment.unmatchedAmount);
        }else{
            const delta = toRub($scope.model.requiredAmount) - $scope.totalAmount;
            return Math.min(delta, payment.unmatchedAmount);
        }
    }

    function updatePaymentInSearch(item, matchedAmount) {
        item.unmatchedAmount -= matchedAmount;
        if (item.unmatchedAmount === 0) {
            item.fullyMatched = true;
        }
    }

    my.select = (item, isFull) => {
        const amountToMatching = calcAddAmount(item, isFull);
        item.status = 'adding';
        service.addMappedPayment(item.id, $scope.model.orderId, $scope.model.orderNumber, amountToMatching, toRub($scope.model.requiredAmount), $scope.model.initiatorLogin).then(res => {
            item.status = '';
            my.loadItems();
            updatePaymentInSearch(item, amountToMatching);
            my.search();
        }).catch(err => {
            item.status = '';
        })
    }

    my.removeFromSearch = item => {
        const matched = $scope.items.find(x => x.payment.id === item.id);
        if (matched) {
            item.status = 'removing';
            my.remove(matched).then(x => {
                item.status = '';
            }).catch(err => {
                item.status = '';
            });
        }
    }

    my.removeMatch = item => {
        return service.deleteMappedOrderPayment(item.id).then(res => {
            if (!$scope.ngReadonly) my.search();
            my.loadItems();
        });
    }

    my.remove = item => {
        item.status = 'removing';
        return service.deleteMappedPayment(item.id).then(res => {
            const index = $scope.items.indexOf(item);
            if (index > -1) {
                $scope.items.splice(index, 1);
            }
            my.search();
            my.loadPayments();
        }).catch(err => {
            item.status = '';
        });
    }

    my.calcUnmatchedAmount = item =>{
        return item.amount - item.matchedAmount
    }

    $scope.$watchCollection('items', (items) => {
        $scope.totalAmount = calcTotalAmount(items);
    });

    $scope.$watch('model', model => {
        if (model) {
            $scope.search.purpose = model.purpose;
            $scope.search.payerINN = model.payer;
            if (!$scope.ngReadonly) my.search();
            my.loadItems();
        }
    });

    my.callModal = function(item){
        fzpSvcModalPayment.open(
            'Сквитовать всю сумму платежа?',
            'Квитовка комиссии',
            $scope.ngReadonly
        )
        .then(data => {
            if (!data) return;
            if(data.result == 'yes'){
                my.select(item, true);
            }else{
                my.select(item, false);
            };
        });
    };

    my.createPayment = function(){
        fzpSvcModalSelectAcct.open(
            angular.copy($scope.model || {}),
            'Списать с расчетного счета клиента',
            false
        )
        .then(data => {
            if (!data) return;
            if(data.result == 'yes'){
                my.select(data.item, false);
            }
        });
    };
}];


export default ctrl;
