import angular from 'angular';
import {
    today,
    calcDuration,
} from './utils';

const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.isDataFilled = function (model) {
        if ($scope.model){
            if($scope.model.guaranteeTypes && $scope.model.guaranteeTypes.filter(x => x.value === true).length > 0 && $scope.model.laws && $scope.model.laws.filter(x => x.value === true).length > 0){
                return true;
            }else{
                return false;
            }
        }else{
            return true;
        }
    };

    my.calcDuration = (startDate, endDate, maxDuration) => {
        let days = calcDuration(startDate, endDate);
        if (!days || days > maxDuration) {
            return null;
        } else {
            return days;
        }
    };
    my.today = () => today();
}];

export default ctrl;