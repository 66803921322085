const ctrl = ['$scope', function ($scope) {
    $scope.$watch('model', newModel => {
        if ($scope.model) {
            $scope.model.result = getResult(newModel);
            $scope.model.source = $scope.model.result;
        }
    }, true);

    function getResult(addr) {
        return [
            addr.region,
            addr.area,
            addr.city,
            addr.settlement,
            addr.street,
            addr.house,
            addr.block,
            addr.flat,
        ].filter(x => x != null && x.trim() != '').join(', ');
    }
}];

export default ctrl;
