import angular from 'angular';
import component from './aftBgSwift';

const moduleName = 'com.farzoom.pear.aftBgSwift';

angular.module(moduleName, [
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber'
    ])
    .directive('aftBgSwift', component)

export default moduleName;
