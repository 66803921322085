export default ['$scope', '$http', '$timeout', 'fzpSvcBgCommission', function ($scope, $http, $timeout, fzpSvcBgCommission) {
    const my = this;
    my.changeCommission = function(){
        fzpSvcBgCommission.open(
            angular.copy($scope.model || {}),
            'Запрос изменения комиссии',
            $scope.ngReadonly,
            $scope.camForm,
            $scope.docDrafts
        )
        .then(data => {
            if (!data) return;
            $scope.model.product.bg.bankCommission = data.product.bg.bankCommission;
            $scope.model.docs = data.docs;
            $scope.docDrafts = data.docDrafts;
            $scope.onSave();
        });
    };
}];