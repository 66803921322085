import angular from 'angular';
import component from './fzpCBgLimit';
import componentSet from './fzpCBgSetLimit';

const moduleName = 'com.farzoom.pear.fzpCBgLimit';

angular.module(moduleName, [
'com.farzoom.common.fzToDate',
'com.farzoom.common.fzToMoney',
'com.farzoom.common.fzToNumber'
])
.directive('fzpCBgLimit', component)
.directive('fzpCBgSetLimit', componentSet)

export default moduleName;