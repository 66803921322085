import tmpl from './tmpl.html';

import {
    today,
    calcDuration
} from './utils';

const cmp = function () {
        var controller = ['$scope', '$http', '$filter', function ($scope, $http, $filter) {
            function validate(){
                if(!$scope.schedules) return false;
                var result = false;

                $scope.totalAmountBalance = Math.round($scope.amount - $scope.repaymentAmount);
                $scope.totalAmount =  Math.round($scope.totalAmount);

                $scope.amountDelta = ($scope.totalAmountBalance - $scope.totalAmount)/100;

                if($scope.type == 'График оплаты комиссии'){
                     if(($scope.totalAmountBalance) != $scope.totalAmount){
                        $scope.errorText = 'Итоговая сумма должна совпадать с суммой предложенной комиссии.';
                        result = true;
                     }
                }else{
                    if(($scope.totalAmountBalance) != $scope.totalAmount && $scope.type == 'График погашения основного долга' && $scope.tcType != 'Невозобновляемая кредитная линия' || ($scope.totalAmountBalance) < $scope.totalAmount && $scope.type != 'График погашения основного долга'){
                        $scope.errorText = $scope.type != 'График погашения основного долга' ? 'Итоговая сумма превышает сумму кредита за вычетом суммы к погашению в конце срока.' : 'Итоговая сумма должна совпадать с суммой кредита за вычетом суммы к погашению в конце срока.(' + $scope.amountDelta + ' руб.)';
                        result = true;
                    }


                    if($scope.type == 'График погашения основного долга' && $scope.tcType == 'Невозобновляемая кредитная линия' && $scope.totalAmount != 10000){
                        $scope.errorText = 'Итоговая сумма погашения должна составлять 100.00%.';
                        result = true;
                    }

                    var minStartDate = $scope.schedules[0].startDate;
                    var maxEndDate = $scope.schedules[0].endDate;

                    $scope.schedules.forEach(function(x){
                        if(x.startDate < $scope.startDate || x.startDate > $scope.endDate){
                            $scope.errorText = 'Дата начала должна быть в пределах Требуемой даты выдачи и Даты погашения.';
                            result = true;
                        }
                        if(x.endDate < $scope.startDate || x.endDate > $scope.endDate){
                            $scope.errorText = 'Дата окончания должна быть в пределах Требуемой даты выдачи и Даты погашения.';
                            result = true;
                        }
                        if(x.amount < 0){
                            $scope.errorText = 'Сумма платежа не может быть отрицательной.';
                            result = true;
                        }
                    });
                }

                $scope.isError = result;
            };

            $scope.isAddNewItem = false;

            $scope.addNew = function(){
                 $scope.newItem = {
                      startDate: null,
                      endDate: null,
                      amount: null
                 };
                 $scope.isAddNewItem = true;
            };

            $scope.addRow = function(newItem){
                if(newItem.endDate < $scope.startDate || newItem.endDate > $scope.endDate){
                    var msg = newItem.endDate < $scope.startDate ? 'Дата не может быть меньше Требуемой дата выдачи' : 'Дата не может быть больше Даты погашения';
                    //uas.flash.error(msg);
                    return false;
                }
                $scope.schedules.push(newItem);
                $scope.isAddNewItem = false;
                $scope.newItem = {};
                $scope.calcTotalAmount();
            };

            $scope.cancelAdd = function(){
                $scope.isAddNewItem = false;
                $scope.newItem = {};
            };

            $scope.deleteRow = function(item){
                if($scope.schedules.length > 1){
                    var index = 0;
                    for(var i=0; i < $scope.schedules.length; i++) {
                        if(item.amount == $scope.schedules[i].amount && item.startDate == $scope.schedules[i].startDate && item.endDate == $scope.schedules[i].endDate){
                            index = i;
                        }
                    }
                    $scope.schedules.splice(index, 1);
                }else{
                    $scope.schedules[0] = {
                        startDate: $scope.startDate,
                        endDate: $scope.endDate,
                        amount: null
                    };
                }
                $scope.calcTotalAmount();
            };

            $scope.calcTotalAmount = function(){
                if($scope.type == 'График лимита задолженности'){
                    var maxAmount = 0;
                    $scope.schedules.forEach(function(x){
                        if(x.amount > maxAmount) maxAmount = x.amount;
                    });

                    $scope.totalAmount = maxAmount;
                }else{
                    $scope.totalAmount = $scope.schedules.length > 0 ? $scope.schedules.map(x => x.amount).reduce((a, b) => a + b) : 0;
                }

                for(var i = 0; i < $scope.schedules.length; i++){
                    $scope.schedules[i].startDate = getMaxStartDate($scope.schedules[i].endDate);
                }
                validate();
            };

            function getMaxStartDate(endDate){
                var result = null;
                if(!$scope.schedules) $scope.schedules = [];
                $scope.schedules.forEach(function(item){
                    if(new Date(result) < new Date(item.endDate) && new Date(item.endDate) < new Date(endDate)) result = item.endDate;
                });
                if(!result){
                    $scope.schedules.forEach(function(item){
                        if(new Date(result) < new Date(item.startDate) && new Date(item.startDate) < new Date(endDate)) result = item.startDate;
                    });
                }
                if(!result){
                    result = $scope.startDate;
                }else{
                    result = result < $scope.startDate ? $scope.startDate : result;
                }

                if(result != $scope.startDate) {
                    var dt = new Date(result);
                    result = new Date(dt.setDate(dt.getDate() + 1)).toISOString().substr(0,10);
                }
                return result;
            };

            $scope.getStartDate = function(endDate){
                if(!endDate) return;
                $scope.newItem.startDate = getMaxStartDate(endDate);
            };

            function fncInit(){
                if(!$scope.schedules) return;
                $scope.calcTotalAmount();
            };

            function setMinStartDate(){
                if(!$scope.schedules) return false;
                for(var i = 0; i < $scope.schedules.length; i++){
                    $scope.schedules[i].startDate = $scope.startDate;
                }
                $scope.calcTotalAmount();
            }
            function fncCalcScheduler(){
                if(!$scope.ngReadOnly &&  !$scope.schedules){
                    $scope.schedules = [];
                    if($scope.schedules.length == 0){
                        let days = calcDuration($scope.startDate, $scope.endDate);
                        if(days > 0){
                            if($scope.scheduleType == 'ежемесячный' || $scope.scheduleType == 'ежеквартальный'){
                                var countMonthAdd = $scope.scheduleType == 'ежемесячный' ? 1 : 3;
                                var dt = new Date($scope.startDate);
                                dt.setMonth(dt.getMonth() + 1);
                                var dtEnd = new Date($scope.endDate);
                                dtEnd.setMonth(dtEnd.getMonth() - countMonthAdd)
                                var count = 0;
                                while (dt < dtEnd) {
                                  count++;
                                  dt.setMonth(dt.getMonth() + countMonthAdd);
                                  dt.setDate(21);
                                }
                                var amountByPeriod = Math.floor(($scope.amount - $scope.repaymentAmount)/count);
                                var amountRound = ($scope.amount - $scope.repaymentAmount) - Math.floor(($scope.amount - $scope.repaymentAmount)/count)*count;
                                dt = new Date($scope.startDate);
                                dt.setMonth(dt.getMonth() + 1);
                                var countIteration = 0;
                                while (dt < dtEnd) {
                                    dt.setMonth(dt.getMonth() + countMonthAdd);
                                    dt.setDate(21);
                                    countIteration++;
                                    if(countIteration == count){
                                        $scope.schedules.push({
                                            startDate: $scope.startDate,
                                            endDate: dt.toISOString().substring(0, 10),
                                            amount: (amountByPeriod + amountRound)
                                        });
                                    }else{
                                        $scope.schedules.push({
                                            startDate: $scope.startDate,
                                            endDate: dt.toISOString().substring(0, 10),
                                            amount: amountByPeriod
                                        });
                                    }
                                }
                            }else{
                                $scope.schedules.push({
                                    startDate: $scope.startDate,
                                    endDate: $scope.endDate,
                                    amount: null
                                });
                            }
                        }else{
                            $scope.schedules.push({
                                startDate: $scope.startDate,
                                endDate: $scope.endDate,
                                amount: null
                            });
                        }
                    }
               }
            }

            $scope.$watch('schedules', fncInit);
            $scope.$watch('totalAmount', fncInit);
            $scope.$watch('startDate', setMinStartDate);
            $scope.$watch('endDate', validate);
            $scope.$watch('amount', fncInit);
            $scope.$watch('repaymentAmount', fncInit);
            $scope.$watch('scheduleType', fncCalcScheduler);
        }];

        return {
           restrict: 'E',
           scope: {
              schedules: '=',
              startDate: '=?',
              endDate: '=?',
              repaymentAmount: '=?',
              scheduleType: '=?',
              amount: '=?',
              type: '=',
              tcType: '=',
              ngReadonly: '='
           },
           controller: controller,
           template: tmpl,
           link: function(scope) {
               if(!scope.ngReadOnly &&  !scope.schedules){
                    scope.schedules = [];
                    if(scope.schedules.length == 0){
                        let days = calcDuration(scope.startDate, scope.endDate);
                        if(days > 0){
                            if(scope.scheduleType == 'ежемесячный' || scope.scheduleType == 'ежеквартальный'){
                                var countMonthAdd = scope.scheduleType == 'ежемесячный' ? 1 : 3;
                                var dt = new Date(scope.startDate);
                                dt.setMonth(dt.getMonth() + 1);
                                var dtEnd = new Date(scope.endDate);
                                dtEnd.setMonth(dtEnd.getMonth() - countMonthAdd)
                                var count = 0;
                                while (dt < dtEnd) {
                                  count++;
                                  dt.setMonth(dt.getMonth() + countMonthAdd);
                                  dt.setDate(21);
                                }
                                var amountByPeriod = Math.floor((scope.amount - scope.repaymentAmount)/count);
                                var amountRound = (scope.amount - scope.repaymentAmount) - Math.floor((scope.amount - scope.repaymentAmount)/count)*count;
                                dt = new Date(scope.startDate);
                                dt.setMonth(dt.getMonth() + 1);
                                var countIteration = 0;
                                while (dt < dtEnd) {
                                    dt.setMonth(dt.getMonth() + countMonthAdd);
                                    dt.setDate(21);
                                    countIteration++;
                                    if(countIteration == count){
                                        scope.schedules.push({
                                            startDate: scope.startDate,
                                            endDate: dt.toISOString().substring(0, 10),
                                            amount: (amountByPeriod + amountRound)
                                        });
                                    }else{
                                        scope.schedules.push({
                                            startDate: scope.startDate,
                                            endDate: dt.toISOString().substring(0, 10),
                                            amount: amountByPeriod
                                        });
                                    }
                                }
                            }
                        }else{
                            scope.schedules.push({
                                startDate: scope.startDate,
                                endDate: scope.endDate,
                                amount: null
                            });
                        }
                    }
               }
           }
       };
};

export default cmp;