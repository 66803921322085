import angular from 'angular';

import './styles.css';

export default angular.module('ext.task-forms', [
    require('@farzoom/fz-c-comments'),
    require('@farzoom/fz-c-select-assignee'),
    require('@farzoom/fz-c-staff-info'),
    require('@farzoom/fz-svc-es'),

    // COCONUT
    require('./fzp-c-diff').default,
    require('./fzp-c-attributes').default,
    require('./fzp-c-company-buh').default,
    require('./fzp-c-bg-commission').default,
    require('./fzp-c-agent-schema').default,
    require('./fzp-c-instalment').default,
    require('./fzp-c-order').default,
    require('./fzp-c-product-bg').default,
    require('./fzp-c-product-limit').default,
    require('./fzp-c-product-kik').default,
    require('./fzp-c-scoring-result').default,
    require('./fzp-form').default,
    require('./fzp-tabs').default,
    require('./fzp-verification').default,
    require('./fzp-c-documents').default,
    require('./fzp-c-files').default,
    require('./fzp-c-person').default,
    require('./fzp-c-company').default,
    require('./fzp-c-management').default,
    require('./fzp-c-address').default,
    require('./fzp-c-sign-docs').default,
    require('./fzp-c-liabilities').default,
    require('./fzp-c-bank-accounts').default,
    require('./fzp-ck-editor').default,
    require('./fzp-c-payments').default,
    require('./fzp-c-bg-select-source-id').default,
    require('./fzp-c-bg-limit').default,
    require('./fzp-c-turnover').default,
    require('./aft-bg-swift').default
]);
