const ctrl = ['$scope', 'fzpSvcPersonEdit', '$http', function ($scope, fzpSvcPersonEdit, $http) {
    const my = this;

    function search(personId) {
        return $http.get(`/api/cabinet/internal/poa?personId=${personId}`).then(resp => resp.data);
    }

    my.machineReadablePOA = (personId) => {
        if(!$scope.model) $scope.model = {};
        if(!$scope.model.poaFiles) $scope.model.poaFiles = {};
        search(personId).then(resp => {
            if (!resp || !resp.length) {
                $scope.model.poaFiles[personId] = [];
            } else {
                const listPoaFiles = [];
                for (const element of resp) {
                    const item = {
                        fileId: element.fileId,
                        deletable: element.deletable,
                        number: element.number,
                        startDate: element.startDate,
                        endDate: element.endDate,
                        status: element.status == 'ACTIVE' ? 'Действует' : 'Невалидна',
                        issuedByCompany: {}
                    };
                    if (element.issuedByCompany !== null) {
                        item.issuedByCompany.name = element.issuedByCompany.name;
                        item.issuedByCompany.inn = element.issuedByCompany.inn;
                        item.issuedByCompany.ogrn = element.issuedByCompany.ogrn;
                        item.issuedByCompany.kpp = element.issuedByCompany.kpp;
                    }
                    listPoaFiles.push(item);
                }
                $scope.model.poaFiles[personId] = listPoaFiles;
            }
        }).catch(err => {
            $scope.model.poaFiles[personId] = [];
        });
    }

    $scope.$watch('model', function(){
        if($scope.model && $scope.model.signersInn){
            $scope.model.signersInn.forEach(function(item){
                if(item.isMchd){
                    my.machineReadablePOA(item.personId);
                }
            });
        }
    });

}];

export default ctrl;