import tmpl from './template.html';
import ctrl from './controller';

const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            header: '@',
            model: '=',
            checkValid: '=?'
        },
        template: tmpl,
        controller: ctrl,
        controllerAs: 'my'
    };
};

export default cmp;