import selectLotTmpl from "./select-lot/template.html";
import selectLotCtrl from "./select-lot/controller";

import {calcDeadline, calcDuration, mapServiceLotToModel, today} from './utils';

const ctrl = ['$scope', 'fzModal', '$http', '$timeout', 'camunda', function ($scope, fzModal, $http, $timeout, camunda) {
    const my = this;
    $scope.maxDuration = angular.isDefined($scope.maxDuration) ? $scope.maxDuration : 2200;
    my.ref = {
        auctionType: [{
                code: 'public',
                name: 'Открытый'
            },
            {
                code: 'private',
                name: 'Закрытый'
            },
        ]
    };
    my.state = {
        isZgrSearching: false,
        searchResult: null,
        zgrNotFound: false,
        isManualPurchase: false,
        showEditDetails: false,
        isGuaranteeSearching: false,
        guaranteeFound: false
    }

    $scope.$watch('model', function () {
        // reset
        my.state = {
            isZgrSearching: false,
            searchResult: null,
            zgrNotFound: false,
            isManualPurchase: false,
            showEditDetails: false,
            isGuaranteeSearching: false,
        }
        $scope.model.isLeed = false;
        $scope.model.isSourceOfIncome = false;
        $scope.model.fio = '';
        $scope.model.email = '';
        $scope.model.workPhone = '';
        $scope.model.leedComment = '';
        if(!$scope.model.ifns) $scope.model.ifns= {};
        $scope.model.pattern = '+7(999)999-99-99';
        camunda.getCurrentUserGroups().then(function (resp) {
            var userGroups = resp;
            userGroups.forEach(function(group){
                if(group.id == 'MOOS_BGCLIENT' || group.id == 'AFT_AGENT_LEAD') $scope.model.isLeed = true;
                if(group.id == 'MOOS_LEAD') $scope.model.isSourceOfIncome = true;
            });
        });
        $scope.model.isSourceOfIncome = true;

        if(!$scope.model.data){
            $scope.model.data = {};
        }

        if(!$scope.model.data.purchase){
            $scope.model.data.purchase = {};
        }

        if($scope.model.data && $scope.model.data.purchase && $scope.model.data.purchase.lots && $scope.model.data.purchase.lots.length == 1){
            if(!$scope.model.data.purchase.lots[0].lotNumber) $scope.model.data.purchase.lots = [];
            if(!$scope.model.data.purchase.lots[0].ikzList || $scope.model.data.purchase.lots[0].ikzList && $scope.model.data.purchase.lots[0].ikzList.length < 1) $scope.model.data.purchase.lots[0].ikzList = [""];
            if($scope.model.data.purchase.lots[0] && $scope.model.data.purchase.lots[0].beneficiaries){
                $scope.model.data.purchase.lots[0].beneficiaries.forEach(function(item){ if(!item.ikz) item.ikz = '';});
            }
        }

    });

    my.filterLaw = item => {
        const res = !($scope.model.data.bankGuaranteeTypeRefId === 'participation' && item.code === '185-615') &&
            !($scope.model.data.bankGuaranteeTypeRefId === 'returnAdvance' && item.code === '44')
        return res;
    }

    function search(purchaseNumber) {
        return $http.get('/api/zgr-search?purchaseNumber=' + purchaseNumber).then(resp => resp.data);
    };


    function searchGuarantee(dealDocNumber) {
        return $http.get('/api/approval-statistics/product/search?contractNumber=' + dealDocNumber).then(resp => resp.data);
    };

    my.guaranteeSearch = () => {
        my.state.isGuaranteeSearching = true;
        $scope.model.relationGuarantees = [];
        searchGuarantee($scope.model.dealDocNumber).then(resp => {
            if(resp && resp.id){
                my.state.guaranteeFound = true;
                $scope.model.data.purchase = resp.bg.purchase;
                $scope.model.data.startDate = resp.startDate;
                $scope.model.data.endDate = resp.endDate;
                $scope.model.data.parentProductId = resp.id;
                $scope.model.data.auctionType = resp.auctionType;
                $scope.model.relationGuarantees = resp.relationGuarantees;
                my.changeAuctionTypeAndLaw();
            }else{
                my.state.guaranteeFound = false;
            }
            my.state.isGuaranteeSearching = false;
            $scope.$apply();
        }).catch(err => {
            my.state.guaranteeFound = false;
            my.state.isGuaranteeSearching = false;
            $scope.$apply();
        });
    }

    my.zgrSearch = () => {
        my.state.isManualPurchase = false;
        my.state.isGuaranteeSearching = false;
        my.state.isZgrSearching = true;
        my.state.searchResult = null;

        search($scope.model.data.purchase.purchaseNumber).then(resp => {
            if (!resp.lots || !resp.lots.length) {
                my.state.zgrNotFound = true;
            } else {
                my.state.zgrNotFound = false;
                my.state.searchResult = resp;
                my.state.showEditDetails = true;

                $scope.model.data.purchase.marketPlace = resp.etp.name;
                $scope.model.data.purchase.law = resp.federalLawRefId;
                $scope.model.data.purchase.tenderType = resp.placingWay;
                $scope.model.data.purchase.tenderDate = resp.publishDate;
                $scope.model.data.purchase.purchaseLink = resp.url;
                $scope.model.data.purchase.purchaseSubject = resp.subject;

                if (resp.lots.length === 1) {
                    $scope.model.data.purchase.lots = resp.lots.map(lot => mapServiceLotToModel(lot, $scope.model.data.bankGuaranteeTypeRefId));
                } else {
                    my.selectLot();
                }
            }
            my.state.isZgrSearching = false;
        }).catch(err => {
            my.state.zgrNotFound = true;
            my.state.isZgrSearching = false;
        });
    }

    my.setManualPurchase = () => {
        my.state.searchResult = null;
        my.state.isManualPurchase = true;
        my.state.showEditDetails = true;
        my.state.zgrNotFound = false;
        $scope.model.data.purchase = {
            purchaseNumber: $scope.model.data.purchase.purchaseNumber,
            lots: [{
                lotNumber: "1",
                beneficiaries: [{}],
                ikzList: [""]
            }]
        };
    }

    my.changeAuctionTypeAndLaw = () => {
        if ($scope.ngReadonly) return;

        if (!$scope.model.data.purchase || !$scope.model.data.purchase.law || $scope.model.data.purchase.law != '44') {
            $scope.model.data.publicationRegistry = '';
        }

        if (!$scope.model.data.purchase || !$scope.model.data.purchase.auctionType) {
            $scope.model.data.publicationRegistry = '';
        }

        if ($scope.model.data.purchase && $scope.model.data.purchase.law && $scope.model.data.purchase.law == '44') {
            if ($scope.model.data.auctionType == 'public') {
                if (!$scope.model.data.publicationRegistry || $scope.model.data.publicationRegistry == '') {
                    $scope.model.data.publicationRegistry = 'eis';
                }
            } else if ($scope.model.data.auctionType == 'private') {
                if (!$scope.model.data.publicationRegistry || $scope.model.data.publicationRegistry == '') {
                    $scope.model.data.publicationRegistry = 'fedtreasury';
                }
            }
        }
    }

    my.withoutPurchaseNumber = (x) => {
        if ($scope.ngReadonly) return;
        if (x) {
            my.state.searchResult = null;
            my.state.isManualPurchase = true;
            my.state.showEditDetails = true;
            my.state.zgrNotFound = false;
            if( !$scope.model.data ||
                !$scope.model.data.purchase ||
                !$scope.model.data.purchase.lots ||
                !$scope.model.data.purchase.lots[0]
            ){
                $scope.model.data.purchase.lots = [{
                    lotNumber: "1",
                    beneficiaries: [{}],
                    ikzList: [""]
                }];
            }

            $timeout(() => {
                $scope.$apply()
            }, 20);
        } else {
            my.state.searchResult = null;
            my.state.isManualPurchase = false;
            my.state.showEditDetails = false;
            my.state.zgrNotFound = false;
            $timeout(() => {
                $scope.$apply()
            }, 20);
        }
    }

    my.editReissued = () => {
        my.state.searchResult = null;
        my.state.isManualPurchase = false;
        my.state.showEditDetails = false;
        my.state.guaranteeFound = false;
        my.state.zgrNotFound = false;
        my.state.isGuaranteeSearching = false;
        $scope.model.data.purchase = {};
        $scope.model.data.startDate = null;
        $scope.model.data.fillingDate = null;
        $scope.model.data.issueDate = null;
        $scope.model.data.endDate = null;
        $scope.model.data.parentProductId = null;
        $scope.model.data.auctionType = null;
    }

    my.editPurchase = () => {
        my.state.searchResult = null;
        my.state.isManualPurchase = false;
        my.state.showEditDetails = false;
        my.state.zgrNotFound = false;
        $scope.model.data.purchase = {
            purchaseNumber: $scope.model.data.purchase.purchaseNumber
        };
    }

    my.addProduct = (lot) => {
        if (!lot.products) lot.products = [];
        lot.products.push({})
    }

    my.removeProduct = (lot, prod) => {
        const index = lot.products.indexOf(prod);
        if (index === -1) return;
        lot.products.splice(index, 1);
    }

    my.addIkz = (lot) => {
        if (!lot.ikzList) lot.ikzList = [];
        lot.ikzList.push("");
    }

    my.removeIkz = (lot, index) => {
        lot.ikzList.splice(index, 1);
    }

    my.selectLot = function () {
        fzModal.open({
            windowClass: 'fz-modal__window-lots',
            template: selectLotTmpl,
            controller: selectLotCtrl,
            controllerAs: 'my',
            scope: {
                modalTitle: 'Выберите лоты'
            },
            params: {
                lots: my.state.searchResult.lots
            }
        }).closed.then(result => {
            if (result.resultCode != 'OK') return;
            if (!result.selectedLots || !result.selectedLots.length) {
                $scope.model.data.purchase.lots = [];
            } else {
                $scope.model.data.purchase.lots = result.selectedLots.map(lot => mapServiceLotToModel(lot, $scope.model.data.bankGuaranteeTypeRefId));
            }
        });
    }

    // Калькулируемое поле - %% снижения от начальной цены контракта
    my.calcDeltaPrice = function calcDeltaPrice(lot) {
        let deltaPrice = null;
        if (!lot.startAmount || lot.startAmount == 0) {
            deltaPrice = null;
        } else if (!lot.finalAmount || lot.finalAmount == 0) {
            deltaPrice = null;
        } else {
            deltaPrice = Math.round(((lot.startAmount - lot.finalAmount) / lot.startAmount * 100) * 100) / 100;
        }
        return deltaPrice;
    }

    my.showIsRequiredSecurityForGuaranteePeriod = () => $scope.model.data.bankGuaranteeTypeRefId === 'execution' || $scope.model.data.bankGuaranteeTypeRefId === 'payment'; // Гарантийный период
    my.showIsIncludedForfeit = () => $scope.model.data.bankGuaranteeTypeRefId === 'execution' || $scope.model.data.bankGuaranteeTypeRefId === 'period' || $scope.model.data.bankGuaranteeTypeRefId === 'payment'; // Неустойки
    my.showPrepaymentExists = () => $scope.model.data.bankGuaranteeTypeRefId === 'execution' || $scope.model.data.bankGuaranteeTypeRefId === 'payment'; // Aванс
    my.showGuaranteePeriod = () => $scope.model.data.bankGuaranteeTypeRefId === 'execution' || $scope.model.data.bankGuaranteeTypeRefId === 'payment'; // Гарантийный период
    my.showVat = () => $scope.model && $scope.model.data && $scope.model.data.bankGuaranteeTypeRefId && $scope.model.data.bankGuaranteeTypeRefId === 'vat'; // НДС

    my.calcDuration = (startDate, endDate, maxDuration) => {
        let days = calcDuration(startDate, endDate);
        if (!days || days > maxDuration) {
            return null;
        } else {
            return days;
        }
    };

    my.calcMonthBetween = (a, b) => {
        var d1 = new Date(a);
        var d2 = new Date(b);
        var month;
        month = (d2.getFullYear() - d1.getFullYear()) * 12;
        month -= d1.getMonth() + 1;
        month += d2.getMonth();
        if(d2.getDate() >= d1.getDate()){
            month++;
        }
        return month <= 0 ? 0 : month;
    }

    my.fillStartDate = () => {
        if($scope.model.data.issueDate && (!$scope.model.data.startDate || $scope.model.data.startDate == null)){
            $scope.model.data.startDate = angular.copy($scope.model.data.issueDate);
        }
        if(!!$scope.model.data.issueDate && !!$scope.model.data.startDate && !$scope.model.data.endDate){
           var date = angular.copy($scope.model.data.startDate);
           var dt = new Date(date);
           var utc = Date.UTC(dt.getFullYear(), dt.getMonth()+ 11, dt.getDate())
           $scope.model.data.endDate = new Date(utc).toISOString().substr(0,10);
        }
    }

    my.today = () => today();

    my.calcLotConAmount = lot => (lot.beneficiaries || []).map(x => x.conAmount).reduce((a, b) => a + b, 0);
    my.calcLotBgAmount = lot => (lot.beneficiaries || []).map(x => x.bgAmount).reduce((a, b) => a + b, 0);
    my.calcFullBgAmount = () => (((($scope.model || {}).data || {}).purchase || {}).lots || []).map(lot => my.calcLotBgAmount(lot)).reduce((a, b) => a + b, 0);

    my.addCustomer = lot => {
        if (!lot.beneficiaries) lot.beneficiaries = [];
        lot.beneficiaries.push({});
    }

    my.removeCustomer = (lot, x) => {
        const i = lot.beneficiaries.indexOf(x);
        lot.beneficiaries.splice(i, 1);
    }

    my.needValidate = (x) => {
        return x && x.companyVat && (x.companyVat.amount != null || x.companyVat.amountApproved != null || x.companyVat.amountDecline != null);
    }

    my.calcDeclineRate = (x) => {
        x.declineRate = !!x.amount && x.amount != 0 ? x.amountDecline/x.amount * 10000 : 0
    }

    my.onChangeAmountVat = (x) => {
        if(!!x.amount && ( !!x.amountApproved  || !!x.amountDecline)){
            if(!!x.amountApproved) {
                x.amountDecline = x.amount - x.amountApproved
            }else{
                x.amountApproved = x.amount - x.amountDecline
            }
            my.calcDeclineRate(x);
        }
    }

    my.onChangeNoDecisionVat = (x) => {
        if(x.noDecision){
            x.amountApproved = 0;
            x.amountDecline = 0;
            x.declineRate = 0;
        }
    }

    my.onChangeAmountApprovedVat = (x) => {
        if(!!x.amountApproved && (!!x.amount || !!x.amountDecline)){
            if(!!x.amount) {
                x.amountDecline = x.amount - x.amountApproved
            }else{
                x.amount = x.amountDecline + x.amountApproved
            }
            my.calcDeclineRate(x);
        }
    }

    my.onChangeAmountDeclineVat = (x) => {
        if(!!x.amountDecline && ( !!x.amount || !!x.amountApproved)){
            if(!!x.amount) {
                x.amountApproved = x.amount - x.amountDecline
            }else{
                x.amount = x.amountDecline + x.amountApproved
            }
            my.calcDeclineRate(x);
        }
    }

    my.addLot = () => {
        if (!$scope.model.data.purchase.lots) $scope.model.data.purchase.lots = [];
        $scope.model.data.purchase.lots.push({
            ikzList: [""],
            products: [{}]
        });
    }

    my.removeLot = lot => {
        const i = $scope.model.data.purchase.lots.indexOf(lot);
        $scope.model.data.purchase.lots.splice(i, 1);
    }

    /**
     * 44-ФЗ. Статья 37. Антидемпинговые меры при проведении конкурса и аукциона
     * 615-ФЗ. Статья 90.
     */
    function isAntidumping(lot) {
        const p = $scope.model.data;
        return p.purchase.law == '615' || (p.purchase.law == '44' &&
            p.bankGuaranteeTypeRefId == 'execution' &&
            lot.startAmount > 1500000000); // 15 млн. рублей
    }

    function isBigDecrease(lot) {
        // снижение на 25 и более процентов от НМЦ
        return lot.finalAmount !== null && !!lot.startAmount && lot.finalAmount / lot.startAmount <= 0.75;
    }

    my.validateAntidumpingAmount = function (lot) {
        return true; // Проверка на антидемпинговые меры отключена по требованию банка
        /*
        if (!isAntidumping(lot) || !isBigDecrease(lot)) return true;
        if (lot.beneficiaries.some(x => x.conAmount === null)) return true;
        const conAmount = my.calcLotConAmount(lot);

        const guaranteeAmount = conAmount * 1.5;
        const actualLotBgAmount = my.calcLotBgAmount(lot);
        const amountOk = actualLotBgAmount >= guaranteeAmount;
        const prepaymentOk = !lot.contractConditions.prepaymentExists || actualLotBgAmount >= lot.contractConditions.prepaymentAmount;
        return amountOk && prepaymentOk;
        */
    }

    my.isReturnAdvanced44 = function () {
        const m = $scope.model;
        return m && m.data && m.data.purchase && m.data.purchase.law === "44" && m.data.bankGuaranteeTypeRefId === "returnAdvance";
    }

    my.startAmountChange = function(lot){
        if(my.showGuaranteeExecution()){
            lot.finalAmount = lot.startAmount;
        }
    }


    /*
        bankGuaranteeTypeRefId:
        1 - Обеспечение исполнения обязательств по контракту (execution)
        2 - Обеспечение заявки на участие в торгах (participation)
        3 - Обеспечение возврата аванса (refund)
        4 - Гарантия платежа (commitment)
        5 - Гарантийный период (period)
    */

    $scope.$watch('model.data.bankGuaranteeTypeRefId', (newType, oldType) => {
        if ($scope.ngReadonly) return;
        // Пересчитываем сумму гарантии при изменении вида гарантии, но только если её не правили руками
        (((($scope.model || {}).data || {}).purchase || {}).lots || []).forEach(lot => {

            if (newType === 'period' && lot.contractConditions) {
                // эти поля не показываем на форме - очистим их, на случай если они заполнены
                lot.contractConditions.isRequiredSecurityForGuaranteePeriod = false;
                lot.contractConditions.securityForGuaranteePeriodDays = null;
            }

            (lot.beneficiaries || []).forEach(ben => {
                switch (newType) {
                    case 'execution': {
                        if (ben.conAmount && (!ben.bgAmount || (oldType == 'participation' && ben.bgAmount === ben.appAmount))) {
                            ben.bgAmount = ben.conAmount;
                        }
                        break;
                    }
                    case 'participation': {
                        if (ben.appAmount && (!ben.bgAmount || (oldType == 'execution' && ben.bgAmount === ben.conAmount))) {
                            ben.bgAmount = ben.appAmount;
                        }
                        lot.finalAmount = lot.startAmount;
                        break;
                    }
                }
            })
        });
    });

    my.onFileInputChange = function (input, fileType) {
        if (!input.files.length) return;
        const file = [...input.files][0];
        $scope.model.guaranteeLot = file;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var fileContents = "";
        reader.onload = function (evt) {
            fileContents = evt.target.result.replace('data:', '').replace(/^.+,/, '');
            $scope.$apply(function () {
                $scope.model.guaranteeLot.content = fileContents;
            });

        }
        reader.onerror = function (evt) {
            $scope.model.garanteeLot = {};
            e.errorCode = 'Ошибка вложения файла';
            return $q.reject(e);
        }
    };

    my.download = () =>{
       var blob = new Blob([$scope.model.guaranteeLot]);
       const downloadUrl = URL.createObjectURL(blob);
       const link = document.createElement("a");
       link.href = downloadUrl;
       link.download = $scope.model.guaranteeLot.name;
       link.click();
       URL.revokeObjectURL(downloadUrl);
    }

    my.ngReadonlyReissued = () => {
        return $scope.model.data.isReissue && ($scope.ngReadonly || !$scope.ngReadonly && (my.state.guaranteeFound || $scope.model.data.parentProductId));
    };

    my.canChangeDurationReissued = () => {
        return $scope.model.data.isReissue && (!$scope.ngReadonly && $scope.model.data.changeGuaranteeParams && $scope.model.data.changeGuaranteeParams.isChangeGuarantee && $scope.model.data.changeGuaranteeParams.isChangeDate && (my.state.guaranteeFound || $scope.model.data.parentProductId) || !$scope.ngReadonly && !(my.state.guaranteeFound || $scope.model.data.parentProductId));
    };

    my.canChangeAmountReissued = () => {
        return $scope.model.data.isReissue && (!$scope.ngReadonly && $scope.model.data.changeGuaranteeParams && $scope.model.data.changeGuaranteeParams.isChangeGuarantee && $scope.model.data.changeGuaranteeParams.isChangeSum && (my.state.guaranteeFound || $scope.model.data.parentProductId) || !$scope.ngReadonly && !(my.state.guaranteeFound || $scope.model.data.parentProductId));
    };

    my.canChangeBeneficiaryReissued = () => {
        return $scope.model.data.isReissue && (!$scope.ngReadonly && $scope.model.data.changeGuaranteeParams && $scope.model.data.changeGuaranteeParams.isChangeGuarantee && $scope.model.data.changeGuaranteeParams.isChangeBeneficiary && (my.state.guaranteeFound || $scope.model.data.parentProductId) || !$scope.ngReadonly && !(my.state.guaranteeFound || $scope.model.data.parentProductId));
    };
}];

export default ctrl;