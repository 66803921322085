const ctrl = ['$scope', '$sanitize', function ($scope, $sanitize) {
    const my = this;

    const re = /({.+?})/g;
    my.nodeShow = {};
    my.main = null;
    my.showTable = false;

    my.changeValue = function(node){
        node.value = $scope.model.keys[node.key].value;
        my.initShow(node);
    }

    my.initShow = function(node){
        if(!my.nodeShow[node.key]) my.nodeShow[node.key] = {};
        if(node.children) node.children.forEach(my.initShow);
        if(node.parent && node.parent.children){
            node.parent.children.forEach(function(children){
                my.nodeShow[children.key].visible = eval(children.visible);
            });
        }

        if(!!node.visible && node.parent && node.parent.key){
            my.nodeShow[node.key].visible = eval(node.visible);
        }

         switch(node.mainParent) {
            case 'wsrmResult':
                 if(!my.nodeShow['wsrmResult']) my.nodeShow['wsrmResult'] = {};
                 if($scope.model.keys['wsrmResult'].value == 'заявка не прошла скоринг') {
                     my.nodeShow['wsrmResult'].showStyle = 'fz-c-scoring__row-value-bad';
                 }else{
                     my.nodeShow['wsrmResult'].showStyle = '';
                 }
                 break;
            case 'segmentCode':
                 if(!my.nodeShow['segmentCode']) my.nodeShow['segmentCode'] = {};
                 if($scope.model.keys['segmentCode'].value == 'КИБ') {
                     my.nodeShow['segmentCode'].showStyle = 'fz-c-scoring__row-value-bad';
                 }else{
                     my.nodeShow['segmentCode'].showStyle = '';
                 }
                 break;
            case 'finalFinState':
            case 'finState':
                if(node.value == 'Хорошее')   my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-good';
                if(node.value == 'Среднее')   my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-normal';
                if(node.value == 'Плохое')   my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-bad';
                if(node.type=='boolean'){
                    if(node.value){
                        my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-bad';
                        my.nodeShow[node.key].showStyleCheckbox = 'red';
                    }else{
                        my.nodeShow[node.key].showStyleCheckbox = '';
                        my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-good';
                    }
                }
                break;
            case  'stopFactor':
                if(node.value){
                    my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-bad';
                    my.nodeShow[node.key].showStyleCheckbox = 'red';
                }else{
                    my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-good';
                    my.nodeShow[node.key].showStyleCheckbox = '';
                }
                break;
            case  'productConditions':
            case  'conditionalStopFactor':
                if(node.value){
                    my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-normal';
                    my.nodeShow[node.key].showStyleCheckbox = 'normal';
                }else{
                    my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-good';
                    my.nodeShow[node.key].showStyleCheckbox = '';
                }
                break;
            case  'calcMaxBG':
                if(node.value == $scope.model.keys['productAmountBg'].value)   my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-good';
                if(node.value /  $scope.model.keys['productAmountBg'].value >= 0.75 && node.value /  $scope.model.keys['productAmountBg'].value < 1)   my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-normal';
                if(node.value /  $scope.model.keys['productAmountBg'].value < 0.75 )   my.nodeShow[node.key].showStyle = 'fz-c-scoring__row-value-bad';
                break;
            case 'finScorResult':
                    if(!my.nodeShow['finScorResult']) my.nodeShow['finScorResult'] = {};
                    if(!my.nodeShow['responsiblePerson']) my.nodeShow['responsiblePerson'] = {};
                    if(!my.nodeShow['finScorCatigory']) my.nodeShow['finScorCatigory'] = {};
                    var arr = ['D3', 'D2', 'D1', 'E'];
                    if($scope.model.keys['responsiblePerson'].value == 'Нет полномочий' || arr.indexOf($scope.model.keys['finScorCatigory'].value) >= 0 ) {
                        my.nodeShow['finScorResult'].showStyle = 'fz-c-scoring__row-value-bad';
                        my.nodeShow['responsiblePerson'].showStyle = 'fz-c-scoring__row-value-bad';
                    }else{
                        my.nodeShow['finScorResult'].showStyle = '';
                        my.nodeShow['responsiblePerson'].showStyle = '';
                    }
                break;
            case 'watchList':
                if(!my.nodeShow['watchList']) my.nodeShow['watchList'] = {};
                if($scope.model.keys['watchList'].value == 'ОТКАЗ') {
                     my.nodeShow['watchList'].showStyle = 'fz-c-scoring__row-value-bad';
                }else if($scope.model.keys['watchList'].value == 'Требуется согласование КА') {
                      my.nodeShow['watchList'].showStyle = 'fz-c-scoring__row-value-normal';
                }else{
                    my.nodeShow['watchList'].showStyle = 'fz-c-scoring__row-value-good';
                }
                if(!my.nodeShow['watchListClient']) my.nodeShow['watchListClient'] = {};
                if($scope.model.keys['watchListClient'].value == "Красная" || $scope.model.keys['watchListClient'].value == "Красная зона: наблюдение" || $scope.model.keys['watchListClient'].value == "Проблемный") {
                     my.nodeShow['watchListClient'].showStyle = 'fz-c-scoring__row-value-bad';
                }else{
                    my.nodeShow['watchListClient'].showStyle = 'fz-c-scoring__row-value-good';
                }
                if(!my.nodeShow['watchListGroup']) my.nodeShow['watchListGroup'] = {};
                if($scope.model.keys['watchListGroup'].value == "Красная" || $scope.model.keys['watchListGroup'].value == "Красная зона: наблюдение" || $scope.model.keys['watchListGroup'].value == "Проблемный") {
                     my.nodeShow['watchListGroup'].showStyle = 'fz-c-scoring__row-value-bad';
                }else{
                    my.nodeShow['watchListGroup'].showStyle = 'fz-c-scoring__row-value-good';
                }
                break;
            default:
                my.nodeShow[node.key].showStyle = '';
                my.nodeShow[node.key].showStyleCheckbox = '';
                break;
          }
    }

    my.fncShow = function(node){
        if(!my.nodeShow[node.key]) my.nodeShow[node.key] = {};
        my.nodeShow[node.key].showChild = !my.nodeShow[node.key].showChild;
    }

    function getValueText(key) {
        switch (key.type) {
            case 'string': return key.value;
            case 'number': return key.value;
            case 'money': return key.value;
            case 'dateMonth': return key.value;
            case 'boolean': return key.value ? 'ДА' : 'НЕТ';
        }
        return key.value;
    };

    my.addKey = function (node, k, recursive) {
        const keysMap = $scope.model.keys;

        const key = angular.copy(keysMap[k]);
        if(key){
            key.key = k;
            key.parent = node;
            key.mainParent = (node.parent && node.parent.key ? node.parent.key : node.key) || k;
            key.hasMax = (Object.keys(key).indexOf('max') > -1);
            if (key.hasMax) {
                for (let x = key.parent; !!x && !x.childHasMax; x = x.parent) {
                    x.childHasMax = true;
                }
            }

            key.hasOriginal = (Object.keys(key).indexOf('original') > -1);
            if (key.hasOriginal) {
                key.typeOfOriginal = typeof key.original;
            }

            if (!!key.formula) {
                const formula = $sanitize(key.formula);
                const subKs = formula.match(re);

                let tmpl = `<span class="fz-c-scoring__formula">${k} = ${formula}</span>`;
                if(subKs && subKs.length && subKs.length > 0){
                    subKs.forEach(x => {
                        try{
                            const xd = x.substr(1, x.length - 2);
                            tmpl = tmpl.replace(x, `<a href ng-click="my.addKey(node, '${xd}')" title="${keysMap[xd].name}: ${xd} = ${getValueText(keysMap[xd])}" class="c-scoring__key">${xd}</a>`);
                            if (recursive) my.addKey(key, xd, recursive);
                        }catch(e){
                            console.log('Invalid field: ' + x);
                        }
                    });
                }

                key.tmpl = tmpl;
            } else {
                key.tmpl = `<span class="fz-c-scoring__formula">${k}</span>`
            }

            node.children = node.children || [];
            if (!node.children.some(x => x.key === k)) {
                node.children.push(key);
                my.initShow(key);
            }
            my.initShow(node);
        }
    };

    my.removeKey = function (node) {
        const index = node.parent.children.indexOf(node);
        node.parent.children.splice(index, 1);
    };

    $scope.$watch('model', function () {
        if (!$scope.model) return;
        my.main = {};
        const recursive = true;
        $scope.model.main.forEach(k => my.addKey(my.main, k, recursive));
    });
}];

export default ctrl;