const ctrl = function ($scope) {
    const my = this;
    my.state = {
        selectedValue: null
    };

    function equals(model, vm) {
        return !!model && !!vm && model.displayName === vm.displayName && model.inn === vm.INN
            && model.name === vm.NAME && model.kpp === vm.KPP && model.address === vm.ADDRESS
            && model.code === vm.CODE;
    }

    // Order of watches is important! Watch for "model" first.
    $scope.$watch('model', function (newModel) {
        if (!newModel) {
            my.state.selectedValue = null;
        } else if (!equals(newModel, my.state.selectedValue)) {
            my.state.selectedValue = {
                displayName: newModel.name,
                INN: newModel.inn,
                NAME: newModel.name,
                KPP: newModel.kpp,
                ADDRESS: newModel.address,
                CODE: newModel.code
            };
        }
    });

    $scope.$watch('my.state.selectedValue', function (newValue) {
        if (!newValue) {
            if ($scope.model) {
                delete $scope.model.displayName;
                delete $scope.model.inn;
                delete $scope.model.name;
                delete $scope.model.kpp;
                delete $scope.model.address;
                delete $scope.model.code;
                if ($scope.model.companyId) delete $scope.model.companyId;
            }
        } else if (!equals($scope.model, newValue)) {
            if (!$scope.model) {
                $scope.model = {
                    displayName: newValue.displayName,
                    inn: newValue.INN,
                    name: newValue.NAME,
                    kpp: newValue.KPP,
                    address: newValue.ADDRESS,
                    code: newValue.CODE
                };
            } else {
                $scope.model.displayName = newValue.displayName;
                $scope.model.inn = newValue.INN;
                $scope.model.name = newValue.NAME;
                $scope.model.kpp = newValue.KPP;
                $scope.model.address = newValue.ADDRESS;
                $scope.model.code = newValue.CODE;
            }
            if ($scope.model.companyId) delete $scope.model.companyId;
        }
    });
};

ctrl.$inject = ['$scope'];
export default ctrl;