import angular from 'angular';
import cTurnover from './cTurnover';
import fzpSvcBgTurnover from './fzpSvcBgTurnover';
const moduleName = 'com.farzoom.pear.cTurnover';

angular.module(moduleName, [
        'com.farzoom.common.fzSvcNumber',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
    ])
    .directive('fzpCTurnover', cTurnover)
    .service('fzpSvcBgTurnover', fzpSvcBgTurnover);

export default moduleName;
