const ctrl = function ($scope) {
    const my = this;
    my.state = {
        selectedValue: null
    };

    function equals(model, vm) {
        return !!model && !!vm && model.displayName === vm.displayName && model.inn === vm.INN && model.ogrn === vm.OGRN;
    }

    // Order of watches is important! Watch for "model" first.
    $scope.$watch('model', function (newModel) {
        if (!newModel) {
            my.state.selectedValue = null;
        } else if (!equals(newModel, my.state.selectedValue)) {
            my.state.selectedValue = {
                displayName: newModel.displayName,
                INN: newModel.inn,
                OGRN: newModel.ogrn
            };
        }
    });

    $scope.$watch('my.state.selectedValue', function (newValue) {
        if (!newValue) {
            $scope.model = null;
        } else if (!equals($scope.model, newValue)) {
            $scope.model = {
                displayName: newValue.displayName,
                inn: newValue.INN,
                ogrn: newValue.OGRN
            };
        }
    });
};

ctrl.$inject = ['$scope'];
export default ctrl;
