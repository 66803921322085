import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    my.openModal = function () {
        fzModal.open({
            windowClass: 'fzp-c-diff__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                diff: $scope.diff
            }
        });
    };
}];

export default ctrl;
