const ctrl = ['$scope', 'fzpSvcGuarantorNewCompanyEdit', '$timeout', function ($scope, fzpSvcGuarantorNewCompanyEdit, $timeout) {
    const my = this;

    my.showModal = function () {
        fzpSvcGuarantorNewCompanyEdit.open(
            $scope.model,
            $scope.suggestPersons,
            $scope.ngReadonly,
            $scope.ngRequired,
            $scope.camForm,
            $scope.docDrafts
        ).then(result => {
            if (!result) return;
            $scope.onChange({
                $item: result
            });
            $timeout(() => {
                $scope.$apply()
            }, 20);
        });
    };

    my.validate = function () {
        if (!$scope.model) return true;
        return $scope.model.isValidData;
    };
}];

export default ctrl;
