const ctrl = ['$scope', 'fzpSvcPersonEdit', 'fzpSvcGuarantorCompanyEdit', function ($scope, fzpSvcPersonEdit, fzpSvcGuarantorCompanyEdit) {
    const my = this;

    my.onDeleteCompany = function (item) {
        const i = $scope.model.guarantorCompanies.indexOf(item);
        $scope.model.guarantorCompanies.splice(i, 1);
    };

    my.onDeletePerson = function (item) {
        const i = $scope.model.guarantorPersons.indexOf(item);
        $scope.model.guarantorPersons.splice(i, 1);
    };

    my.onChangeCompany = function (oldItem, newItem) {
        const i = $scope.model.guarantorCompanies.indexOf(oldItem);
        $scope.model.guarantorCompanies[i] = newItem;
    };

    my.onChangePerson = function (oldItem, newItem) {
        const i = $scope.model.guarantorPersons.indexOf(oldItem);
        $scope.model.guarantorPersons[i].person = newItem;
    };

    my.onCreatePerson = function () {
        if (!$scope.model) return;
        fzpSvcPersonEdit.open(
            angular.copy($scope.model.guarantorPersonTemplate.person || {}),
            $scope.model.guarantorPersonConfig,
            $scope.model.ref,
            $scope.suggestPersons,
            'Поручитель',
            false
        )
            .then(person => {
                if (!person) return;
                if (!$scope.model.guarantorPersons) $scope.model.guarantorPersons = [];
                $scope.model.guarantorPersons.push({ person: person });
            });
    };

    my.onCreateCompany = function () {
        if (!$scope.model) return;
        fzpSvcGuarantorCompanyEdit.open($scope.model.guarantorCompanyTemplate, $scope.suggestPersons)
            .then(companyInfo => {
                if (!companyInfo) return;
                if (!$scope.model.guarantorCompanies) $scope.model.guarantorCompanies = [];
                $scope.model.guarantorCompanies.push(companyInfo);
            });
    };

    $scope.$watch('ngRequired', newValue => {
        $scope.model.resultForm = newValue;
    });

    my.filterStatus = function(item){
        return item.status == 'current';
    }

    my.filterStatusIncluded = function(item){
        return item.status == 'included';
    }

    my.filterStatusExcluded = function(item){
        return item.status == 'excluded';
    }

    my.hasValue = function(status){
        return $scope.model.guarantorCompanies.filter(x => x.status == status).length > 0 || $scope.model.guarantorPersons.filter(x => x.status == status).length > 0;
    }
}];

export default ctrl;