const ctrl = ['$scope', '$http', '$q', 'fzFilesConfig', '$element', function ($scope, $http, $q, fzFilesConfig, $element) {
    const my = this;

    my.isCompanyBuhFilled = function (companyBuh) {
        if (!companyBuh || !companyBuh.periods || !companyBuh.latestPeriod) return false;

        const p = companyBuh.periods[companyBuh.latestPeriod];
        if (!p) return false;
        return true;
    };

    my.ref = {
        periods: [],
        taxationType: [{
                value: 'osn',
                name: 'ОСН'
            }
        ],
        taxationAccountingType: [{
                value: 'f1f2',
                name: 'Баланс'
            },
            {
                value: 'kudir',
                name: 'КУДиР'
            }
        ],
        taxationUSNType: [{
                value: 'usn6',
                name: 'доходы (6%)'
            },
            {
                value: 'usn15',
                name: 'доходы - расходы (15%)'
            }
        ]
    };
    my.ps = [];
    my.periodsConfig = {};

    function getPeriodName(dateMonth) {
        const ps = dateMonth.split('-');
        const year = ps[0];
        const month = ps[1];

        switch (month) {
            case '03':
                return `I кв ${year}`;
            case '06':
                return `II кв ${year}`;
            case '09':
                return `III кв ${year}`;
            case '12':
                return `IV кв ${year}`;
        }

        throw new Error(`Unexpected period: ${dateMonth}`);
    };

    function initPeriods() {
        if (!$scope.model || !$scope.model.config || !$scope.model.config.periods) return;

        my.ref.periods = [];
        let maxLatestPeriod = '';
        for (let ps of $scope.model.config.periods) {
            let latestPeriod = '';
            const periods = []
            for (let p of ps) {
                if (!p) {
                    periods.push(null);
                } else {
                    if (latestPeriod < p) latestPeriod = p;
                    periods.push({
                        dateMonth: p,
                        name: getPeriodName(p)
                    });
                }
            }
            if (maxLatestPeriod < latestPeriod) maxLatestPeriod = latestPeriod;
            my.periodsConfig[latestPeriod] = periods;
            my.ref.periods.push({
                period: latestPeriod,
                name: getPeriodName(latestPeriod)
            });
        }

        $scope.model.latestPeriod = $scope.model && $scope.model.latestPeriod && my.ref.periods.some(x => x.period === $scope.model.latestPeriod) ? $scope.model.latestPeriod : maxLatestPeriod;
    };

    function refreshPeriods() {
        if (!$scope.model.latestPeriod) my.ps = [];
        my.ps = my.periodsConfig[$scope.model.latestPeriod];
    };

    $scope.$watch('model.latestPeriod', refreshPeriods);

    my.isOsn = function () {
        return $scope.model && $scope.model.taxationTypeRefId === 'osn';
    };

    my.isUsnBalance = function () {
        return $scope.model && $scope.model.taxationTypeRefId === 'usn' && $scope.model.taxationAccountingTypeRefId === 'f1f2';
    };

    my.isUsnKudir = function () {
        return $scope.model && $scope.model.taxationTypeRefId === 'usn' && $scope.model.taxationAccountingTypeRefId === 'kudir';
    };

    my.isUsn6Kudir = function () {
        return $scope.model && my.isUsnKudir() && $scope.model.taxationUSNTypeRefId === 'usn6';
    };

    my.isUsn15Kudir = function () {
        return $scope.model && my.isUsnKudir() && $scope.model.taxationUSNTypeRefId === 'usn15';
    };

    $scope.$watch('model', onModelChange);

    function onModelChange() {
        if (!$scope.model) return;

        my.ref = {
            periods: [],
            taxationType: [{
                    value: 'osn',
                    name: 'ОСН'
                }
            ],
            taxationAccountingType: [{
                    value: 'f1f2',
                    name: 'Баланс'
                },
                {
                    value: 'kudir',
                    name: 'КУДиР'
                }
            ],
            taxationUSNType: [{
                    value: 'usn6',
                    name: 'доходы (6%)'
                },
                {
                    value: 'usn15',
                    name: 'доходы - расходы (15%)'
                }
            ]
        };

        if($scope.model.config && $scope.model.config.canSelectUsn){
            my.ref.taxationType = [{
                    value: 'osn',
                    name: 'ОСН'
                },
                {
                    value: 'usn',
                    name: 'УСН'
                }
            ]
        }

        initPeriods();
        refreshPeriods();

        if ($scope.model.config && $scope.model.config.taxationAccountingType) {
            my.ref.taxationAccountingType = [];
            if ($scope.model.config.taxationAccountingType.f1f2) {
                my.ref.taxationAccountingType.push({
                    value: 'f1f2',
                    name: 'Баланс'
                });
            }
            if ($scope.model.config.taxationAccountingType.kudir) {
                my.ref.taxationAccountingType.push({
                    value: 'kudir',
                    name: 'КУДиР'
                });
            }
        }
    }

    function clearFileInput(el) {
        const $el = $(el);
        $el.wrap('<form>').closest('form').get(0).reset();
        $el.unwrap();
    }

    my.onFileInputChange = function (input) {
        if (!input.files.length) return;
        const file = [...input.files][0];
        clearFileInput(input);
        processFile(file);
    };

    my.onFileDrop = function (files) {
        if (!files || !files.length) return;
        const file = [...files][0];
        processFile(file);
    };

    //---------------------------------------------------------------------------------------------
    const errorCodes = {
        'FILE_TOO_LARGE': 'Размер файла превышает максимально разрешённое значение',
        'PARSE_FAILED': 'Не удалось распознать отчетность',
        'NO_PERIODS': 'В приложенном документе нет ни одного из необходимых периодов отчётности. Укажите другой период в поле "Последний квартал" либо выберите другой файл',
        'WRONG_COMPANY': 'Отчётность в приложенном документе относится к другой компании',
        'READ_FILE_FAILED': 'Не удалось считать файл',
        'UPLOAD_FAILED': 'Не удалось сохранить файл',
        'UNEXPECTED': 'Произошла непредвиденная ошибка',
    };

    function processFile(file) {
        const taskId = getTaskId();
        const companyId = $scope.model.companyId;
        const companyInn = $scope.model.companyInn;
        const companyKpp = $scope.model.companyKpp;
        const maxSizeInBytes = fzFilesConfig.getMaxSizeInBytes();
        const actualPeriods = my.ps;
        const modelPeriods = $scope.model.periods;
        const drafts = $scope.drafts;

        my.isFinParsing = true;
        my.isFinParsingError = false;
        my.finParsingMessage = '';
        return checkFileSize(file, maxSizeInBytes) // Проверить размер файла
            .then(() => parseFile(file)) // Распарсить файл
            .then(periods => selectPeriods(periods, actualPeriods)) // Проверить наличие нужных периодов
            // .then(periods => !companyInn || validateInn(periods, companyInn, companyKpp)) // Проверить ИНН и КПП
            .then(periods => fillData(periods, modelPeriods)) // Заполнить отчётность
            .then(() => readFile(file)) // Считать файл
            .then(content => companyId && uploadToDrafts(taskId, companyId, file.name, content, drafts)) // Загрузить файл в черновики
            .finally(() => my.isFinParsing = false)
            .then(() => my.finParsingMessage = `Отчётность успешно импортирована из файла ${file.name}`)
            .catch(e => {
                my.isFinParsingError = true;
                const msg = errorCodes[e.errorCode || 'UNEXPECTED'];
                my.finParsingMessage = msg;
            });
    }

    function getTaskId() {
        return $element.closest('form').scope().camForm.taskId; // HACK to get parent Form`s scope
    };

    function checkFileSize(file, maxSizeInBytes) {
        if (file.size > maxSizeInBytes) {
            const err = new Error("File size too large");
            err.errorCode = 'FILE_TOO_LARGE';
            return $q.reject(err);
        }
        return $q.all([]);
    }

    function parseFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        return $http.post('/api/fin-report-parse-fns/parse/osn', formData, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            })
            .then(resp => (resp.data || {}).companyBuhs || [])
            .catch(e => {
                e.errorCode = 'PARSE_FAILED';
                return $q.reject(e);
            });
    }

    function selectPeriods(periods, actualPeriods) {
        const selectedPeriods = periods.filter(dataForPeriod => actualPeriods.some(x => x != null && x.dateMonth === dataForPeriod.dateMonth));
        if (!selectedPeriods.length) {
            const err = new Error('No suitable periods found');
            err.errorCode = 'NO_PERIODS';
            return $q.reject(err);
        }
        return $q.when(selectedPeriods);
    }

    function validateInn(periods, companyInn, companyKpp) {
        if (periods.some(p => p.inn !== companyInn || p.kpp !== companyKpp)) {
            const err = new Error('Wrong company');
            err.errorCode = 'WRONG_COMPANY';
            return $q.reject(err);
        }
        return $q.when(periods);
    }

    function fillData(periods, modelPeriods) {
        periods.forEach(dataForPeriod => {
            const period = {};
            for (let row of (dataForPeriod.buhRecords || [])) {
                const code = `b${row.code}`;
                const amount = row.value * 100000;
                period[code] = amount;
            }
            modelPeriods[dataForPeriod.dateMonth] = period;
        });
    }

    function readFile(file) {
        const d = $q.defer();

        const reader = new FileReader();
        reader.onerror = function (e) {
            e.errorCode = 'READ_FILE_FAILED';
            d.reject(e);
        };

        reader.onload = function (e) {
            const cnt = e.target.result.substr(5).split(';');
            const content = cnt[1].substr(7);
            d.resolve(content);
        };

        reader.readAsDataURL(file);

        return d.promise;
    }

    function uploadToDrafts(taskId, companyId, filename, content, drafts) {
        const docType = 'finReportYear';
        const camundaApiBaseUrl = '/camunda/api/engine/engine/default';

        const draftName = `${docType}@${companyId}`;
        const nextNum = getNextNum(draftName, drafts);
        const numSuffix = nextNum == 1 ? '' : `!${nextNum}`;
        const varName = `$locFileDraft_${draftName}${numSuffix}`;
        const url = `${camundaApiBaseUrl}/task/${taskId}/localVariables/${encodeURIComponent(varName)}`;

        const data = {
            value: content,
            type: 'File',
            valueInfo: {
                filename: filename
            }
        }

        return $http.put(url, data).catch(e => {
            e.errorCode = 'UPLOAD_FAILED';
            return $q.reject(e);
        }).then(() => {
            if (drafts) {
                const draftFiles = angular.copy(drafts[draftName] || []);
                const draft = {
                    name: filename,
                    varName: varName
                };
                draftFiles.push(draft);
                drafts[draftName] = draftFiles;
            }
        });
    }

    function getNextNum(draftName, drafts) {
        const draftFiles = (drafts || {})[draftName] || [];
        if (!draftFiles.length) return 1;
        const numbers = draftFiles.map(x => {
            const ps = x.varName.split('!');
            if (ps.length < 2) return 1;
            return Number(ps[1]);
        });
        return Math.max(...numbers) + 1;
    }

    //---------------------------------------------------------------------------------------------

    if ($scope.model) onModelChange();
}];

export default ctrl;