import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.closeOk = function () {
        var form = $('.fz-c-employee-new__modal');
        if($('.ng-invalid', form).length > 0){
            my.guarantorPerson.isValidData = false;
            alert('Заполните обязательные поля');
        }else{
            my.guarantorPerson.isValidData = true;
            $modalInstanse.close(my.guarantorPerson);
        }
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.removeContact = function (item) {
        const index = my.guarantorPerson.person.contacts.indexOf(item);
        if (index > -1) my.guarantorPerson.person.contacts.splice(index, 1);
    };

    my.addContact = function () {
        my.guarantorPerson.person.contacts.push({email: null, phone: null});
    };

    function show(fld) {
        return !!((my.config || {})[fld] || {}).show;
    }

    my.onSelect = function (item) {
        if (item) {
            const refData = angular.copy(item.data);

            if (show('inn')) my.guarantorPerson.person['inn'] = refData['inn'];
            my.guarantorPerson.person['lastName'] = refData['lastName'];
            my.guarantorPerson.person['firstName'] = refData['firstName'];
            my.guarantorPerson.person['secondName'] = refData['secondName'];

            if (show('birthDate')) my.guarantorPerson.person['birthDate'] = refData['birthDate'];
            if (show('birthPlace')) my.guarantorPerson.person['birthPlace'] = refData['birthPlace'];
            if (show('identityDocument')) {
                my.guarantorPerson.person['series'] = refData['series'];
                my.guarantorPerson.person['number'] = refData['number'];
                my.guarantorPerson.person['issuingAuthority'] = refData['issuingAuthority'];
                my.guarantorPerson.person['issuingAuthorityCode'] = refData['issuingAuthorityCode'];
                my.guarantorPerson.person['issuedDate'] = refData['issuedDate'];
            } 
            if (show('registrationAddress')) my.guarantorPerson.person['registrationAddress'] = refData['registrationAddress'];

            // contacts
            if ((show('phone') || show('email')) && refData['contacts']) {
                my.guarantorPerson.person['contacts'] = [];
                refData['contacts'].forEach(x => {
                    const contact = {email: null, phone: null};
                    if (show('phone')) contact['phone'] = x['phone'];
                    if (show('email')) contact['email'] = x['email'];
                    my.guarantorPerson.person['contacts'].push(contact);
                });
                // Always show at least one line of contacts
                if (!my.guarantorPerson.person['contacts'].length) my.guarantorPerson.person['contacts'].push({email: null, phone: null});
            }

            if (show('position')) my.guarantorPerson.person['position'] = refData['position'];
            if (show('authorizingDoc')) my.guarantorPerson.person['authorizingDoc'] = refData['authorizingDoc'];
            if (show('citizenship')) my.guarantorPerson.person['citizenship'] = refData['citizenship'];
            if (show('otherCitizenship')) my.guarantorPerson.person['otherCitizenship'] = refData['otherCitizenship'];
            if (show('sharePercent')) my.guarantorPerson.person['sharePercent'] = refData['sharePercent'];
            if (show('shareSum')) my.guarantorPerson.person['shareSum'] = refData['shareSum'];

            fillAttributes(my.guarantorPerson.person.personAttributes, refData.personAttributes);
            fillAttributes(my.guarantorPerson.person.relationAttributes, refData.relationAttributes);
            fillAttributes(my.guarantorPerson.person.relationTopAttributes, refData.relationTopAttributes);
        }
    };

    function fillAttributes(destAttrs, srcAttrs) {
        if (destAttrs && destAttrs.items && destAttrs.items.length && srcAttrs && srcAttrs.items && srcAttrs.items.length) {
            const destMap = new Map();
            destAttrs.items.forEach(attr => destMap.set(attr.attributeId, attr));
            srcAttrs.items.forEach(attr => {
                if (destMap.has(attr.attributeId)) {
                    const destAttr = destMap.get(attr.attributeId);
                    destAttr.value = attr.value;
                    destAttr.values = attr.values;
                }
            });
        }
    };

    my.isRf = function () {
        const isShown = my.config && my.config.citizenship && my.config.citizenship.show;
        return !isShown || my.guarantorPerson.person.citizenship === '643'; //'rf';
    };

    my.checkINN12 = function () {
        const isShown = my.config && my.config.inn && my.config.inn.show;
        return my.readonly || !isShown || !my.guarantorPerson.person.inn || my.guarantorPerson.person.inn.length == 12;
    };

    my.validateSharedPercent = function () {
        return my.readonly ||
            !((my.config || {}).sharePercent || {}).show ||
            !my.guarantorPerson.person.sharePercent ||
            (my.guarantorPerson.person.sharePercent >= 0 && my.guarantorPerson.person.sharePercent <= 100);
    };

    $scope.$watch('my.guarantorPerson.person.secondName', secondName => my.noSecondName = secondName === '-');

    // Initialization

    my.header = $modalParams.header;
    my.readonly = $modalParams.readonly;
    my.guarantorPerson = angular.copy($modalParams.guarantorPerson);
    my.config = $modalParams.config;
    my.ref = $modalParams.ref;
    my.suggestPersons = $modalParams.suggestPersons;
    $scope.camForm =  $modalParams.camForm;
    my.docDrafts = $modalParams.docDrafts;

    if (!my.guarantorPerson.person) my.guarantorPerson.person = {};
    if (!my.guarantorPerson.person.contacts) my.guarantorPerson.person.contacts = [];
    if (!my.guarantorPerson.person.contacts.length) my.guarantorPerson.person.contacts.push({email: null, phone: null});
}];