import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.closeOk = function () {
        $modalInstanse.close({result: "yes"});
    };

    my.closeCancel = function () {
        $modalInstanse.close({result: "no"});
    };

    my.header = $modalParams.header;
    my.readonly = $modalParams.readonly;
    my.text = $modalParams.text;
}];