import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.closeOk = function () {
        var form = $('.fzp-c-guarantor-company__modal');
        if($('.ng-invalid', form).length > 0){
            my.model.isValidData = false;
            alert('Заполните обязательные поля');
        }else{
            my.model.isValidData = true;
            $modalInstanse.close(my.model);
        }
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

//    $scope.$watch('my.state.selectedCompany', function (newValue) {
//        if (!newValue) {
//            if (my.model && my.model.company) my.model.company = {};
//        } else {
//            my.model.company = {
//                INN: newValue.INN,
//                OGRN: newValue.OGRN,
//                displayName: newValue.displayName,
//                fullName: newValue.fullName
//            };
//        }
//    });

    // Initialization

    my.state = {
        selectedCompany: null
    };

    function initData() {
        if (!my.model) {
            my.model = {};
        }

        if (!my.model.company) my.model.company = {};
        my.state.selectedCompany = {
            INN: my.model.company.INN,
            OGRN: my.model.company.OGRN,
            displayName: my.model.company.displayName,
            fullName: my.model.company.fullName
        };
    };

    my.readonly = $modalParams.readonly;
    my.required = $modalParams.required;
    my.docDrafts = $modalParams.docDrafts;
    my.model = angular.copy($modalParams.model);
    my.suggestPersons = $modalParams.suggestPersons;
    $scope.camForm =  $modalParams.camForm;
    //initData();
}];