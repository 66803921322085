import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

export default ['fzModal', function (fzModal) {
    function open(guarantorPerson, config, ref, suggestPersons, header, readonly, camForm, docDrafts) {
        return fzModal.open({
            windowClass: 'fz-c-employee-new__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                header: header,
                readonly: readonly,
                guarantorPerson: guarantorPerson || {},
                config: config || {},
                ref: ref || {},
                suggestPersons: suggestPersons,
                camForm: camForm,
                docDrafts: docDrafts
            }
        }).closed;
    }

    this.open = open;
}];
