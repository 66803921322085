import angular from 'angular';
import fzpCCompanyBuh from './fzpCCompanyBuh';
import fzpCCompanyBuhManual from './fzpCCompanyBuhManual';
import './styles.css';

const moduleName = 'com.farzoom.pear.cCompanyBuh';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCCompanyBuh', fzpCCompanyBuh)
    .directive('fzpCCompanyBuhManual', fzpCCompanyBuhManual)

export default moduleName;
