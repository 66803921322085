import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    my.showModal = function () {
        return fzModal.open({
            windowClass: 'fz-c-employee__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                address: $scope.model || {},
            }
        }).closed.then(result => {
            if (!result) return;
            $scope.model = result;
        });
    }
}];

export default ctrl;
