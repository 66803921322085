import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller'

const ctrl = ['$scope', 'fzModal', 'fzpSvcAttribute', function ($scope, fzModal, fzpSvcAttribute) {
    const my = this;

    my.onButtonAddClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-bank-accounts__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: angular.copy(($scope.model || {}).itemTemplate || {}),
                config: angular.copy(($scope.model || {}).config || {}),
            }
        }).closed.then(result => {
            if (!result) return;
            if (!$scope.model) $scope.model = {};
            if (!$scope.model.data) $scope.model.data = [];
            $scope.model.data.push(result);
        });
    };

    my.onButtonEditClickHandler = function (item) {
        fzModal.open({
            windowClass: 'fz-c-bank-accounts__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: item,
                config: angular.copy(($scope.model || {}).config || {}),
            }
        }).closed.then(result => {
            if (!result) return;

            const index = $scope.model.data.indexOf(item);
            if (index > -1) $scope.model.data[index] = result;
        });
    };

    my.onButtonDeleteClickHandler = function (item) {
        const index = $scope.model.data.indexOf(item);
        $scope.model.data.splice(index, 1);
    };

    my.validate = function () {
        if (!$scope.model) return false;
        const d = $scope.model.data;
        return !!d && !!d.length && d.every(x => my.validateBankAccount(x));
    };

    my.validateBankAccount = function (x) {
        return !!x.account.bankName &&
               !!x.account.number &&
               fzpSvcAttribute.validateGroup(x);
    };
}];

export default ctrl;