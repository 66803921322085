import angular from 'angular';

const ctrl = ['$scope', '$element', function ($scope, $element) {
    const my = this;

    my.fileMeta = {};

    my.toggleSignsShown = function (file) {
        my.fileMeta[file.id] = my.fileMeta[file.id] || {};
        my.fileMeta[file.id].showSigns = !my.fileMeta[file.id].showSigns;
    };

    my.isSignsShown = function (file) {
        return !!(my.fileMeta[file.id] || {}).showSigns;
    };

    my.isShowSignCheck = function (file) {
        return !!file.checkSignature;
    };

    my.deleteFile = function (file) {
        file.toBeDeleted = true;
    };

    my.cancelDelete = function (file) {
        file.toBeDeleted = false;
    };

    my.markForResign = function (file) {
        file.toBeResigned = true;
    };

    my.cancelMarkForResign = function (file) {
        file.toBeResigned = false;
    };

    my.getName = function(name){
        return name.includes('.sig') ? name.substr(0, a.length-4) : a;
    }

}];

export default ctrl;