import jQuery from 'jquery';

export default ['$timeout', '$http', '$q', 'fzSvcAutocomplete', function ($timeout, $http, $q, fzSvcAutocomplete) {
    // debounce prevents from calling fnc several times in a row
    let timeoutHandler;

    function debounce(fnc, debounceTimeout) {
        if (timeoutHandler) $timeout.cancel(timeoutHandler);
        timeoutHandler = $timeout(fnc, debounceTimeout);
    };

    function search(query, cancelPromise) {
        return $http.post('/api/dadata/suggest/party', {
            query: query
        }, {
            timeout: cancelPromise.promise
        }).then(resp => resp.data);
    }

    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {
            model: '=?'
        },
        link: function ($scope, $element, attrs, ngModel) {
            function updateValueFromModel() {
                const value = $scope.model ? $scope.model.displayName || '' : '';
                const ac = $element.fzAutocomplete();
                ac.currentValue = value;
                $element.val(value);
                refreshNgModel(value);
            }

            function refreshNgModel(value) {
                if (ngModel) {
                    ngModel.$setViewValue(value);
                    ngModel.$render();
                }
            }

            let isModalOpened = false,
                suggestContainer = null;

            function validateInput(event) {
                // Check if input element lost focus and focus is on suggestions
                if (event) {
                    const isFocusOnSuggestions = jQuery(suggestContainer).is(':hover');
                    if (isFocusOnSuggestions) return;
                }

                // Check if modal is opened - no need to validate input value
                if (isModalOpened) return;

                if (!$element.val()) {
                    $scope.$apply(function () {
                        $scope.model = undefined;
                        refreshNgModel(undefined);
                    });
                } else {
                    updateValueFromModel();
                }
            };

            jQuery($element).focusout(validateInput);
            $scope.$watch('model', updateValueFromModel);

            let lookupCanceler = null;
            fzSvcAutocomplete.bootstrap($element, {
                lookup: function (query, done) {
                    debounce(() => {
                        if (lookupCanceler) lookupCanceler.resolve(); // cancel request if it is already in progress
                        lookupCanceler = $q.defer();
                        search(query, lookupCanceler).then(function (res) {
                            done(res);
                        });
                    }, 300);
                },
                minChars: 3,
                maxHeight: 300,
                tabDisabled: true,
                orientation: 'auto',
                noSuggestionNotice: 'Компания не найдена',
                showNoSuggestionNotice: true,
                triggerSelectOnValidInput: false,
                searchOnFocus: false,
                formatResult: function (suggestion, currentValue) {
                    var html = [];

                    if (suggestion.data['inn']) {
                        html.push('<p>ИНН: ' + suggestion.data['inn'] + '</p>');
                    }

                    return '<div class="client">' +
                        '<h5>' + suggestion.value + '</h5>' +
                        html.join(' ') +
                        '</div>';
                },
                beforeRender: function (container, suggestions) {
                    const words = this.value.split(' ');
                    fzSvcAutocomplete.highlight(container, words);
                },
                onSelect: function (item) {
                    $scope.$apply(function () {
                        $scope.model = {
                            INN: item.data.inn,
                            OGRN: item.data.ogrn,
                            KPP: item.data.kpp,
                            displayName: item && item.data && item.data.name && item.data.name.short_with_opf ? item.data.name.short_with_opf : (item.data.name || {}).full_with_opf,
                            fullName: (item.data.name || {}).full_with_opf
                        };
                    });
                },
                onReady: function (container) {
                    suggestContainer = container;
                }
            });

            const instance = jQuery($element).fzAutocomplete();
            $scope.$on('$destroy', function cleanup() {
                instance.dispose();
            });
        }
    }
}];