import './styles.css';
import {
    calcDuration
} from './utils';

export default ['$scope', '$q', '$http', '$modalInstanse', '$modalParams', '$timeout', 'camunda', function ($scope, $q, $http, $modalInstanse, $modalParams, $timeout, camunda) {
    const my = this;
    let timeoutHandler;
    let lookupCanceler = null;
    my.showWaiting = false;

    function debounce(fnc, debounceTimeout) {
        if (timeoutHandler) $timeout.cancel(timeoutHandler);
        timeoutHandler = $timeout(fnc, debounceTimeout);
    };

    camunda.getCurrentUserId().then(function (resp) {
        my.userId = resp;
    });

    my.closeOk = function () {
        if(my.calcAmountCommValue() < 0 || my.product.bg.bankCommission.proposedPercent < my.minRate){
            $scope.errorText = 'Ставка по комиссии за выдачу БГ не может быть меньше ' + my.minRate/100 + ' %';
            $scope.isError = true;
            return;
        }

        if(!my.product.bg.bankCommission.proposedAmount || !my.product.bg.bankCommission.proposedPercent){
            my.product.bg.bankCommission.proposedAmount = 0;
            my.product.bg.bankCommission.proposedPercent = 0;
            $scope.errorText = 'Заполните данные формы';
            $scope.isError = true;
            return;
        }

        my.product.bg.bankCommission.isFixProposedAmount = true;

        var data = {
            bankCommission: my.product.bg.bankCommission,
            promotions: my.promotions
        };

        var formData = {
            messageName : 'ORDER_COMMISSION_TO_APPROVE',
            processVariables:{
                StartForm_result:{
                    value: JSON.stringify(data),
                    type: 'Json'
                },
                INITIATOR_GROUP:{
                    value: my.initiatorGroup,
                    type: 'String'
                },
                taskId:{
                    value: $scope.camForm.taskId,
                    type: 'String'
                }
            },
           businessKey: my.orderId
        };

        $http.post('/camunda/api/engine/engine/default/message', formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function(resp){
            my.product.bg.bankCommission.statusChangeCommission = 'На согласовании';
            $modalInstanse.close({product: my.product, promotions: my.promotions, docs: my.docs, docDrafts: my.docDrafts});
        })
        .catch(e => {
            my.product.bg.bankCommission.statusChangeCommission = 'На согласовании';
            $modalInstanse.close({product: my.product, promotions: my.promotions, docs: my.docs, docDrafts: my.docDrafts});
            e.errorCode = 'PARSE_FAILED';
        });
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.header = $modalParams.header;
    my.readonly = $modalParams.readonly;
    my.product = angular.copy($modalParams.model.product);
    my.decisionDate = angular.copy($modalParams.model.decisionDate);
    my.orderId = angular.copy($modalParams.model.orderId);
    my.amountDelivery = angular.copy($modalParams.model.amountDelivery);
    my.repaymentAmount = 0;
    my.promotions = angular.copy($modalParams.model.promotions);
    my.initiatorGroup = angular.copy($modalParams.model.initiatorGroup);
    my.docs = angular.copy($modalParams.model.docs);
    my.docDrafts = angular.copy($modalParams.docDrafts);
    my.initiatorGroup = angular.copy($modalParams.model.initiatorGroup);
    my.initiatorType = angular.copy($modalParams.model.initiatorType);
    my.initiatorLoginName = angular.copy($modalParams.model.initiatorLoginName);
    my.initiatorAgentManagerName = angular.copy($modalParams.model.initiatorAgentManagerName);
    my.orderTypeRefId = angular.copy($modalParams.model.orderTypeRefId);
    my.hideCommissionUser = angular.copy($modalParams.model.hideCommissionUser);
    my.hasAccreditation = angular.copy($modalParams.model.hasAccreditation);
    my.rating = angular.copy($modalParams.model.rating);
    $scope.camForm =  $modalParams.camForm;

    if (!my.product) my.product = {};
    if (!my.product.bg) my.product.bg = [];

    my.calcAmountCommValue = function(){
        var res =  Math.round((my.isExcessCommission() ? my.product.bg.bankCommission.calcAmount : my.product.bg.bankCommission.proposedAmount) * (my.product.bg.bankCommission.changeCommissionPercent/10000));
        return res;
    }

    my.setManualProposedPercent = function(delta){
        my.product.bg.bankCommission.proposedPercent = Math.round(my.product.bg.bankCommission.calcPercent + my.product.bg.bankCommission.calcPercent * delta)
        my.calcCommissionByPropPercent(my.product.bg.bankCommission.proposedPercent);
    }

    my.isExcessCommission = function(){
        return my.product.bg.bankCommission.proposedAmount > my.product.bg.bankCommission.calcAmount;
    }

    my.checkHideUser = function(){
        if(!my.userId) return true;
        if(!my.hideCommissionUser) my.hideCommissionUser = [];
        return my.hideCommissionUser.includes(my.userId);
    }

    my.calcCommissionByPropAmount = function(pAmount){
        var request = {
            orderId: my.orderId,
            proposedAmount: pAmount
        };
        if(my.product.bg.bankCommission.isInstallments){
            request.isInstallments = true;
            request.repaymentSchedules = my.product.bg.bankCommission.repaymentSchedules;
        }
        if(my.product.bg.bankCommission.isChangeCommissionRewards){
            request.isChangeCommissionRewards = true;
            request.changeCommissionPercent = my.product.bg.bankCommission.changeCommissionPercent;
        }
        if(my.product.bg.bankCommission.isChangeExcessCommissionRewards){
            request.isChangeExcessCommissionRewards = true;
            request.changeCommissionExcessPercent = my.product.bg.bankCommission.changeCommissionExcessPercent;
        }

        debounce(() => {
            if (lookupCanceler) lookupCanceler.resolve(); // cancel request if it is already in progress
            lookupCanceler = $q.defer();
            my.calcCommission(request, lookupCanceler).then(function (res) {
                done(res);
            });
        }, 1);
    }

    my.calcCommissionByPropPercent = function(pPercent){
        var request = {
            orderId: my.orderId,
            proposedPercent: pPercent
        };
        if(my.product.bg.bankCommission.isInstallments){
            request.isInstallments = true;
            request.repaymentSchedules = my.product.bg.bankCommission.repaymentSchedules;
        }
        if(my.product.bg.bankCommission.isChangeCommissionRewards){
            request.isChangeCommissionRewards = true;
            request.changeCommissionPercent = my.product.bg.bankCommission.changeCommissionPercent;
        }
        if(my.product.bg.bankCommission.isChangeExcessCommissionRewards){
            request.isChangeExcessCommissionRewards = true;
            request.changeCommissionExcessPercent = my.product.bg.bankCommission.changeCommissionExcessPercent;
        }

        debounce(() => {
            if (lookupCanceler) lookupCanceler.resolve(); // cancel request if it is already in progress
            lookupCanceler = $q.defer();
            my.calcCommission(request, lookupCanceler).then(function (res) {
                done(res);
            });
        }, 1);
    }

    my.calcCommissionInit = function(){
        var request = {
            orderId: my.orderId
        };
        if(my.product.bg.bankCommission.proposedPercent){
            request.proposedPercent = my.product.bg.bankCommission.proposedPercent;
        }
        if(my.product.bg.bankCommission.isInstallments){
            request.isInstallments = true;
            request.repaymentSchedules = my.product.bg.bankCommission.repaymentSchedules;
        }
        if(my.product.bg.bankCommission.isChangeCommissionRewards){
            request.isChangeCommissionRewards = true;
            request.changeCommissionPercent = my.product.bg.bankCommission.changeCommissionPercent;
        }
        if(my.product.bg.bankCommission.isChangeExcessCommissionRewards){
            request.isChangeExcessCommissionRewards = true;
            request.changeCommissionExcessPercent = my.product.bg.bankCommission.changeCommissionExcessPercent;
        }

        debounce(() => {
           if (lookupCanceler) lookupCanceler.resolve(); // cancel request if it is already in progress
           lookupCanceler = $q.defer();
           my.calcCommission(request, lookupCanceler).then(function (res) {
               done(res);
           });
        }, 1);
    }

    my.calcCommission = function(request, cancelPromise){
        var url = "/api/common-api-commission/commission/calcdmn";
        my.showWaiting = true;
        return $http.post(url, request, {
           timeout: cancelPromise.promise,
           headers: {
               'Content-Type': 'application/json'
           }
        });
    }

    function done(respData){
        var resp = respData.data;
        my.product.bg.bankCommission.calcAmount = resp.standard.amount;
        my.product.bg.bankCommission.calcPercent =  resp.standard.percent;
        if(resp.suggested && (resp.suggested.amount != null || resp.suggested.percent != null)){
            my.product.bg.bankCommission.proposedAmount = resp.suggested.amount;
            my.product.bg.bankCommission.proposedPercent = resp.suggested.percent;
        }else{
            my.product.bg.bankCommission.proposedAmount = null;
            my.product.bg.bankCommission.proposedPercent = null;
        }
        my.product.bg.bankCommission.changePercent = resp.changePercent;
        if(resp.reward && (resp.reward.amount != null || resp.reward.percent != null)){
            my.product.bg.bankCommission.changeCommissionAmount = resp.reward.amount;
            my.product.bg.bankCommission.changeCommissionPercent = resp.reward.percent;
        }else{
            my.product.bg.bankCommission.changeCommissionAmount = null;
            my.product.bg.bankCommission.changeCommissionPercent = null;
        }
        if(resp.excess && (resp.excess.amount != null || resp.excess.percent != null)){
            my.product.bg.bankCommission.changeCommissionExcessAmount = resp.excess.amount;
            my.product.bg.bankCommission.changeCommissionExcessPercent = resp.excess.percent;
        }else{
            my.product.bg.bankCommission.changeCommissionExcessAmount = null;
            my.product.bg.bankCommission.changeCommissionExcessPercent = null;
        }
        if(resp.isInstallments){
            my.product.bg.bankCommission.isInstallments = true;
            my.product.bg.bankCommission.repaymentSchedules = [];
            resp.installment.forEach(function(x){
                my.product.bg.bankCommission.repaymentSchedules.push({startDate:x.startDate, endDate: x.endDate, amount: x.amount, amountAv: x. amountAv});
            });
        }else{
            my.product.bg.bankCommission.isInstallments = false;
            my.product.bg.bankCommission.repaymentSchedules = null;
        }
        my.minRate = resp.minRate;
        my.product.bg.bankCommission.nettoRate = resp.nettoRate ? resp.nettoRate : 0;
        my.amountDelivery = resp.amountDelivery ? resp.amountDelivery : 0;
        my.showWaiting = false;
    }
}];