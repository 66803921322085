import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

export default ['fzModal', function (fzModal) {
    function open(model, header, readonly, camForm, docDrafts) {
        return fzModal.open({
            windowClass: 'fz-c-change-turnover__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                model: model || {},
                header: header,
                readonly: readonly,
                camForm: camForm,
                docDrafts: docDrafts
            }
        }).closed;
    }

    this.open = open;
}];
