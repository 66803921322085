import angular from 'angular';
import component from './fzpCProductBg';
import componentCh from './fzpCProductBgChange';
import componentRe from './fzpCProductBgReissue';
import componentVat from './fzpCProductVatExp';
import './styles.css';

const moduleName = 'com.farzoom.pear.fzpCProductBg';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCProductBg', component)
    .directive('fzpCProductBgChange', componentCh)
    .directive('fzpCProductBgReissue', componentRe)
    .directive('fzpCProductVatExp', componentVat)

export default moduleName;
