import angular from 'angular';

function calcTotalAmount(items) {
    return items.reduce((total, item) => total + getCorrectAmount(item.matchedAmount), 0);
}

function getCorrectAmount(value) {
    const v = parseFloat(value);
    return isFinite(v) ? v : 0;
}

function toRub(kopick) {
    return kopick / 100;
}

function toKopick(rub) {
    return Math.round(rub * 100);
}

const ctrl = ['$scope', 'fzpSvcPayments', 'fzpSvcModalPayment', 'fzpSvcModalSelectAcct', function ($scope, service, fzpSvcModalPayment, fzpSvcModalSelectAcct) {
    const my = this;

    my.loadPayments = () => {
        service.getOrderPaymentsByOrder($scope.model.orderId).then(res =>{
            $scope.model.installments = res.length > 1;
            $scope.model.repaymentSchedules = angular.copy(res.reduce(function(filtered, x){
                if(!$scope.ngReadonly && (x.isNew || x.unmatchedAmount != 0)){
                    filtered.push(x);
                }
                if($scope.ngReadonly && (x.isNew && x.matchedAmount != 0)){
                    filtered.push(x);
                }
                return filtered;
            },[]));
        });
    }

    $scope.$watch('model', model => {
        if (model) {
            my.loadPayments();
        }
    });

}];


export default ctrl;
