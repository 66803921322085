import angular from 'angular';
import cBgCommission from './cBgCommission';
import fzpSvcBgCommission from './fzpSvcBgCommission';
import cBgChangeCommission from './cBgChangeCommission';
import cChangeCommission from './cChangeCommission';
import fzpCommissionRepaymentSchedule from './fzpCommissionRepaymentSchedule';

const moduleName = 'com.farzoom.pear.cBgCommission';

angular.module(moduleName, [
        'com.farzoom.common.fzSvcNumber',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
    ])
    .directive('fzpCBgCommission', cBgCommission)
    .directive('fzpCBgChangeCommission', cBgChangeCommission)
    .directive('cChangeCommission', cChangeCommission)
    .service('fzpSvcBgCommission', fzpSvcBgCommission)
    .directive('fzpCommissionRepaymentSchedule', fzpCommissionRepaymentSchedule);

export default moduleName;
