import angular from 'angular';

import '@farzoom/fz-svc-autocomplete';
import '@farzoom/fz-svc-company';

import fzSelectCompanyDirective from './select-company.directive';
import fzSelectCompanyVatDirective from './select-company-vat.directive';

angular.module('com.farzoom.common.fzSelectCompany', [
    'com.farzoom.common.fzSvcAutocomplete',
    'com.farzoom.common.fzSvcCompany'
])
.directive('fzSelectCompany', fzSelectCompanyDirective)
.directive('fzSelectVat', fzSelectCompanyVatDirective);