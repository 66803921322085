import 'babel-polyfill';
import angular from 'angular';
import CamSDK from 'cam-sdk';
import 'oclazyload';

import './components';
import './services';
import './task-forms';

angular.module('ext.app', [
    'oc.lazyLoad',
    'ext.cmps',
    'ext.svcs',
    'ext.task-forms'
]).config(['$anchorScrollProvider', function ($anchorScrollProvider) {
    // HACK: fix for ng-include.
    // ngIncludeDirective requires $anchorScroll. When $anchorScroll is injected (with DI) it always scrolls window to top (BUG in $anchorScroll maybe)
    $anchorScrollProvider.disableAutoScrolling();
}]).run(['$ocLazyLoad', function ($ocLazyLoad) {
    configureCamSDKClient = function (camAPI) {
        var Task = camAPI.resource('task');
        if (Task.isPatchedForMoos) return;
        Task.isPatchedForMoos = true;

        // Monkey patch for task form initialization. Trying to load required modules
        var orig_taskForm = Task.form;
        Task.form = function (taskId, cb) {
            return orig_taskForm.call(this, taskId, function (taskFormErr, taskFormData) {
                if (taskFormErr) return cb(taskFormErr, taskFormData);

                console.log(`Task.form called for taskId: ${taskId}, contextPath: ${taskFormData.contextPath}, key: ${taskFormData.key}`);

                if (taskFormData.key && taskFormData.key.indexOf('{taskId}') > 0) {
                    taskFormData.key = taskFormData.key.replace('{taskId}', taskId);
                }

                loadConfig(taskFormData.contextPath).then(config => {
                    return loadModules(config).then(() => {
                        cb(null, taskFormData)
                    });
                }).catch(configErr => {
                    console.log(`Failed to load config.json for ${taskFormData.contextPath}. Legacy process maybe?`);
                    cb(null, taskFormData);
                });
            });
        };

        // Monkey patch for start form initialization. Trying to load required modules
        var ProcessDefinition = camAPI.resource('process-definition');
        var orig_processDefinitionStartForm = ProcessDefinition.startForm;
        ProcessDefinition.startForm = function (data, cb) {
            orig_processDefinitionStartForm.call(this, data, function (startFormErr, startFormData) {
                if (startFormErr) return cb(startFormErr, startFormData);

                console.log(`ProcessDefinition.startForm called for contextPath: ${startFormData.contextPath}`);

                loadConfig(startFormData.contextPath).then(config => {
                    return loadModules(config).then(() => {
                        cb(null, startFormData)
                    });
                }).catch(configErr => {
                    console.log(`Failed to load config.json for ${startFormData.contextPath}`);
                    cb(null, startFormData);
                });
            });
        };

        function loadConfigFromProcessApplication(contextPath) {
            // Convert jQuery deferred to es6 Promise
            return new Promise((resolve, reject) => {
                $.getJSON(`${contextPath}/config.json`).done(resolve).fail(reject);
            });
        }

        function loadConfig(contextPath) {
            if (!contextPath) {
                return Promise.resolve(null);
            }

            return loadConfigFromProcessApplication(contextPath);
        }

        function loadModules(config) {
            return Promise.all(config.modules.map(m => loadModule(m)));
        };

        function loadModule(m) {
            return $ocLazyLoad.load(m.files, {cache: false });
        }
    };

    // This Client is required only to access Task resource
    var camAPI = new CamSDK.Client({
        apiUri: 'stub'
    });
    configureCamSDKClient(camAPI);
}]);

function isFunction(obj) {
    return obj && {}.toString.call(obj) === '[object Function]';
}

export var CamundaSDK = CamSDK;

export var configureCamSDKClient = function () {
    throw new Error("ngapp.configureCamSDKClient() is not ready yet. If must be called after angular.module('ext.app').run is executed");
};

export var init = function (el, deps, cb) {
    if (isFunction(deps)) { // For backwards compatibility
        cb = deps;
        deps = null;
    }

    var rootEl = $('html');
    var isInitialized = angular.element(rootEl).injector();
    if (!isInitialized) {
        angular.bootstrap(rootEl, ['ext.app']);
    }

    if (cb) cb(CamSDK);
};
