import angular from 'angular';
import fzpCPayments from './fzpCPayments';
import aftPayments from './aftPayments';
import service from './fzpSvcPayments';
import serviceModal from './fzpSvcModalPayment';
import fzpSvcModalSelectAcct from './fzpSvcModalSelectAcct';
const moduleName = 'com.farzoom.pear.cPayments';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzpCPayments', fzpCPayments)
    .directive('aftPayments', aftPayments)
    .service('fzpSvcPayments', service)
    .service('fzpSvcModalPayment', serviceModal)
    .service('fzpSvcModalSelectAcct', fzpSvcModalSelectAcct)

export default moduleName;
