const ctrl = ['$scope', '$http', '$q', 'fzFilesConfig', '$element', '$timeout', function ($scope, $http, $q, fzFilesConfig, $element, $timeout) {
    const my = this;

    my.isCompanyBuhFilled = function (companyBuh) {
        if($scope.model.config && $scope.model.config.isExpress) return true;
        if($scope.model.config && $scope.model.config.isExpress && ($scope.model.taxationAccountingTypeRefId == null || $scope.model.taxationAccountingTypeRefId == '')) return true;
        if (!companyBuh || !companyBuh.periods || !companyBuh.latestPeriod) return false;

        const p = companyBuh.periods[companyBuh.latestPeriod];
        if (!p) return false;
        return true;
    };

    my.ref = {
        periods: [],
        taxationType: [{
                value: 'osn',
                name: 'ОСН'
            },
            {
              value: 'usn',
              name: 'УСН'
            }
        ],
        taxationAccountingType: [{
                value: 'extended',
                name: 'Расширенный баланс'
            }
        ],
        taxationUSNType: [{
                value: 'usn6',
                name: 'доходы (6%)'
            },
            {
                value: 'usn15',
                name: 'доходы - расходы (15%)'
            }
        ]
    };
    my.ps = [];
    my.periodsConfig = {};

    function getPeriodName(dateMonth) {
        const ps = dateMonth.split('-');
        const year = ps[0];
        const month = ps[1];

        switch (month) {
            case '03':
                return `I кв ${year}`;
            case '06':
                return `II кв ${year}`;
            case '09':
                return `III кв ${year}`;
            case '12':
                return `IV кв ${year}`;
        }

        throw new Error(`Unexpected period: ${dateMonth}`);
    };

    function initPeriods() {
        if (!$scope.model || !$scope.model.config || !$scope.model.config.periods) return;

        my.ref.periods = [];
        let maxLatestPeriod = '';
        for (let ps of $scope.model.config.periods) {
            let latestPeriod = '';
            const periods = []
            for (let p of ps) {
                if (!p) {
                    periods.push(null);
                } else {
                    if (latestPeriod < p) latestPeriod = p;
                    periods.push({
                        dateMonth: p,
                        name: getPeriodName(p)
                    });
                }
            }
            if (maxLatestPeriod < latestPeriod) maxLatestPeriod = latestPeriod;
            my.periodsConfig[latestPeriod] = periods;
            my.ref.periods.push({
                period: latestPeriod,
                name: getPeriodName(latestPeriod)
            });
        }

        $scope.model.latestPeriod = $scope.model && $scope.model.latestPeriod && my.ref.periods.some(x => x.period === $scope.model.latestPeriod) ? $scope.model.latestPeriod : maxLatestPeriod;
        refreshPeriods();
    };

    function refreshPeriods() {
        if (!$scope.model.latestPeriod) my.ps = [];
        my.ps = my.periodsConfig[$scope.model.latestPeriod];
    };

    $scope.$watch('model.latestPeriod', refreshPeriods);

    function initSummaries() {
        var ts = {};
        // БУХГАЛТЕРСКИЙ БАЛАНС
        // АКТИВ
        // I. ВНЕОБОРОТНЫЕ АКТИВЫ
        ts.b1100 = function (q) {
            q = q || {};
            return (q.b1110 || 0) + (q.b1120 || 0) + (q.b1130 || 0) + (q.b1140 || 0) + (q.b1150 || 0) + (q.b1160 || 0) + (q.b1170 || 0) + (q.b1180 || 0) + (q.b1190 || 0);
        }
        // II. ОБОРОТНЫЕ АКТИВЫ
        ts.b1200 = function (q) {
            q = q || {};
            return (q.b1210 || 0) + (q.b1220 || 0) + (q.b1230 || 0) + (q.b1240 || 0) + (q.b1250 || 0) + (q.b1260 || 0);
        }
        // БАЛАНС
        ts.b1600 = function (q) {
            q = q || {};
            return ts.b1100(q) + ts.b1200(q);
        }

        // ПАССИВ
        // III. КАПИТАЛ И РЕЗЕРВЫ
        ts.b1300 = function (q) {
            q = q || {};
            return (q.b1310 || 0) + (q.b1320 || 0) + (q.b1340 || 0) + (q.b1350 || 0) + (q.b1360 || 0) + (q.b1370 || 0);
        }

        // IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА
        ts.b1400 = function (q) {
            q = q || {};
            return (q.b1410 || 0) + (q.b1420 || 0) + (q.b1430 || 0) + (q.b1450 || 0);
        }

        // V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА
        ts.b1500 = function (q) {
            q = q || {};
            return (q.b1510 || 0) + (q.b1520 || 0) + (q.b1530 || 0) + (q.b1540 || 0) + (q.b1550 || 0);
        }

        // БАЛАНС
        ts.b1700 = function (q) {
            q = q || {};
            if(my.isOsn()){
                return ts.b1300(q) + ts.b1400(q) + ts.b1500(q);
            }else{
                return (q.b1300 || 0) + ts.b1400(q) + ts.b1500(q);
            }
        }

        // Расхождение
        ts.bDiff = function (q) {
            q = q || {};
            return ts.b1700(q) - ts.b1600(q);
        }

        ts.bDiffShow = function (ps) {
            return ps.map(p => ts.bDiff(q) !== 0).reduce((a, b) => a || b, false);
        }

        // ОТЧЕТ О ФИНАНСОВЫХ РЕЗУЛЬТАТАХ
        // Валовая прибыль
        ts.b2100 = function (q) {
            q = q || {};
            return (q.b2110 || 0) - (q.b2120 || 0);
        }

        // Прибыль (убыток) от продаж
        ts.b2200 = function (q) {
            q = q || {};
            return ts.b2100(q) - (q.b2210 || 0) - (q.b2220 || 0);
        }

        // Прибыль (убыток) до налогообложения
        ts.b2300 = function (q) {
            q = q || {};
            return ts.b2200(q) + (q.b2320 || 0) - (q.b2330 || 0) + (q.b2310 || 0) + (q.b2340 || 0) - (q.b2350 || 0);
        }

        // Чистая прибыль (убыток) отчетного периода
        ts.b2400 = function (q) {
            q = q || {};
            if (my.isOsn() || my.isUsnBalance()) return ts.b2300(q) + (q.b2450 || 0) + (q.b2430 || 0) - (q.b2410 || 0) + (q.b2460 || 0);
            if (my.isUsn6Kudir()) return (q.b2110 || 0) * 0.94;
            if (my.isUsn15Kudir()) return ((q.b2110 || 0) - (q.b2210 || 0)) * 0.85;
        }

        ts.notEqual = function (f1, f2, q) {
            q = q || 0;
            return ts[f1](q) !== ts[f2](q);
        }

        my.total = ts;
    };

    my.isOsn = function () {
        return $scope.model && ($scope.model.taxationTypeRefId === 'osn' || $scope.model.taxationTypeRefId === 'usn') &&  $scope.model.taxationAccountingTypeRefId === 'extended';
    };

    my.isUsnBalance = function () {
        return $scope.model && ($scope.model.taxationTypeRefId === 'usn' || $scope.model.taxationTypeRefId === 'osn') && $scope.model.taxationAccountingTypeRefId === 'simple';
    };

    my.isUsnKudir = function () {
        return $scope.model && $scope.model.taxationTypeRefId === 'usn' && $scope.model.taxationAccountingTypeRefId === 'kudir';
    };

    my.isUsn6Kudir = function () {
        return $scope.model && my.isUsnKudir() && $scope.model.taxationUSNTypeRefId === 'usn6';
    };

    my.isUsn15Kudir = function () {
        return $scope.model && my.isUsnKudir() && $scope.model.taxationUSNTypeRefId === 'usn15';
    };

    function updateModelCalc() {
        const model = $scope.model;
        if (!model || !model.periods) return;

        const isOsn = (model.taxationTypeRefId === 'osn' || model.taxationTypeRefId === 'usn') && model.taxationAccountingTypeRefId === 'extended';
        const isUsnBalance = (model.taxationTypeRefId === 'osn' || model.taxationTypeRefId === 'usn') && model.taxationAccountingTypeRefId === 'simple';

        const isUsnKudir = model.taxationTypeRefId === 'usn' && model.taxationAccountingTypeRefId === 'kudir';
        const isUsn6Kudir = isUsnKudir && model.taxationUSNTypeRefId === 'usn6';
        const isUsn15Kudir = isUsnKudir && model.taxationUSNTypeRefId === 'usn15';

        // Fill totals
        let totals = [];
        if (isOsn){
            totals = ['b1100', 'b1200', 'b1600', 'b1300', 'b1400', 'b1500', 'b1700', 'b2100', 'b2200', 'b2300', 'b2400'];
        }else if(isUsnBalance){
             totals = ['b1100', 'b1200', 'b1600','b1400', 'b1500', 'b1700', 'b2100', 'b2200', 'b2300', 'b2400'];
        }
        else if (isUsn6Kudir || isUsn15Kudir) totals = [];

        for (let dateMonth of Object.keys(model.periods)) {
            // Remove all unnecessary rows
            // if (isUsn6Kudir) {
            //     model.periods[dateMonth] = {
            //         'b2110': model.periods[dateMonth].b2110
            //     };
            // } else if (isUsn15Kudir) {
            //     model.periods[dateMonth] = {
            //         'b2110': model.periods[dateMonth].b2110,
            //         'b2210': model.periods[dateMonth].b2210
            //     };
            // }

            const period = model.periods[dateMonth];
            for (let code of totals) {
                const value = my.total[code](period);
                if (value) {
                    period[code] = my.total[code](period);
                } else {
                    delete period[code];
                }
            }

            for (let code of Object.keys(period)) {
                if (period[code] === null) {
                    delete period[code];
                }
            }
            if (!Object.keys(period).length) {
                delete model.periods[dateMonth];
            }
        }
    }

    initSummaries();

    $scope.$watch('model', onModelChange);

    $scope.$watch('model.taxationTypeRefId', function (newType) {
        if (newType) updateModelCalc();
    });

    my.onValueChange = () => updateModelCalc();

    function onModelChange() {
        if (!$scope.model) return;

        if ($scope.model.config && $scope.model.config.isExpress) {
            my.ref = {
                periods: [],
                taxationType: [{
                       value: 'osn',
                       name: 'ОСН'
                   },
                   {
                       value: 'usn',
                       name: 'УСН'
                   }
                ],
                taxationAccountingType: [{
                        value: null,
                        name: ''
                    },{
                        value: 'extended',
                        name: 'Расширенный баланс'
                    }
                ],
                taxationUSNType: [{
                        value: 'usn6',
                        name: 'доходы (6%)'
                    },
                    {
                        value: 'usn15',
                        name: 'доходы - расходы (15%)'
                    }
                ]
            };
        }else{
            my.ref = {
                periods: [],
                taxationType: [{
                       value: 'osn',
                       name: 'ОСН'
                   },
                   {
                       value: 'usn',
                       name: 'УСН'
                   }
                ],
                taxationAccountingType: [{
                        value: 'extended',
                        name: 'Расширенный баланс'
                    }
                ],
                taxationUSNType: [{
                        value: 'usn6',
                        name: 'доходы (6%)'
                    },
                    {
                        value: 'usn15',
                        name: 'доходы - расходы (15%)'
                    }
                ]
            };
        }

        initPeriods();

        if ($scope.model.config && $scope.model.config.taxationAccountingType) {
            if($scope.model.config && $scope.model.config.isExpress){
                my.ref.taxationAccountingType = [{
                        value: null,
                        name: ''
                    },{
                        value: 'extended',
                        name: 'Расширенный баланс'
                    }
                ];
            }else{
                my.ref.taxationAccountingType = [{
                    value: 'extended',
                    name: 'Расширенный баланс'
                }];
            }

            /*if ($scope.model.config.taxationAccountingType.f1f2) {
                my.ref.taxationAccountingType.push({
                    value: 'f1f2',
                    name: 'Баланс'
                });
            }

            if ($scope.model.config.taxationAccountingType.kudir) {
                my.ref.taxationAccountingType.push({
                    value: 'kudir',
                    name: 'КУДиР'
                });
            }
            */

            if($scope.model.config && $scope.model.config.canSelectUsn){
                my.ref.taxationAccountingType.push({
                    value: 'simple',
                    name: 'Упрощенный баланс'
                });
            }
        }
    }

    function clearFileInput(el) {
        const $el = $(el);
        $el.wrap('<form>').closest('form').get(0).reset();
        $el.unwrap();
    }

    my.onFileInputChange = function (input) {
        if (!input.files.length) return;
        const file = [...input.files][0];
        clearFileInput(input);
        processFile(file);
    };

    my.onFileDrop = function (files) {
        if (!files || !files.length) return;
        const file = [...files][0];
        processFile(file);
    };

    //---------------------------------------------------------------------------------------------
    const errorCodes = {
        'FILE_TOO_LARGE': 'Размер файла превышает максимально разрешённое значение',
        'PARSE_FAILED': 'Не удалось распознать отчетность',
        'NO_PERIODS': 'В приложенном документе нет ни одного из необходимых периодов отчётности. Укажите другой период в поле "Последний квартал" либо выберите другой файл',
        'WRONG_COMPANY': 'Отчётность в приложенном документе относится к другой компании',
        'READ_FILE_FAILED': 'Не удалось считать файл',
        'UPLOAD_FAILED': 'Не удалось сохранить файл',
        'UNEXPECTED': 'Произошла непредвиденная ошибка',
    };

    function processFile(file) {
        const taskId = getTaskId();
        const companyId = $scope.model.companyId;
        const companyInn = $scope.model.companyInn;
        const companyKpp = $scope.model.companyKpp;
        const maxSizeInBytes = fzFilesConfig.getMaxSizeInBytes();
        const actualPeriods = my.ps;
        const modelPeriods = $scope.model.periods;
        const drafts = $scope.drafts;

        my.isFinParsing = true;
        my.isFinParsingError = false;
        my.finParsingMessage = '';
        return checkFileSize(file, maxSizeInBytes) // Проверить размер файла
            .then(() => parseFile(file)) // Распарсить файл
            .then(periods => selectPeriods(periods, actualPeriods)) // Проверить наличие нужных периодов
            // .then(periods => validateInn(periods, companyInn, companyKpp)) // Проверить ИНН и КПП
            .then(periods => fillData(periods, modelPeriods)).then(() => updateModelCalc()) // Заполнить отчётность
            .then(() => readFile(file)) // Считать файл
            .then(content => uploadToDrafts(taskId, companyId, file.name, content, drafts)) // Загрузить файл в черновики
            .finally(() => my.isFinParsing = false)
            .then(() => my.finParsingMessage = `Отчётность успешно импортирована из файла ${file.name}`)
            .catch(e => {
                my.isFinParsingError = true;
                const msg = errorCodes[e.errorCode || 'UNEXPECTED'];
                my.finParsingMessage = msg;
            });
    }

    function getTaskId() {
        return $element.closest('form').scope().camForm.taskId; // HACK to get parent Form`s scope
    };

    function checkFileSize(file, maxSizeInBytes) {
        if (file.size > maxSizeInBytes) {
            const err = new Error("File size too large");
            err.errorCode = 'FILE_TOO_LARGE';
            return $q.reject(err);
        }
        return $q.all([]);
    }

    function parseFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        return $http.post('/api/fin-report-parse-fns/parse/osn', formData, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            })
            .then(resp => (resp.data || {}).companyBuhs || [])
            .catch(e => {
                e.errorCode = 'PARSE_FAILED';
                return $q.reject(e);
            });
    }

    function selectPeriods(periods, actualPeriods) {
        const parsedPeriods = periods.sort((a,b) => {
           var aa = a.dateMonth.split('-');
           var bb = b.dateMonth.split('-');
           var yearA = parseInt(aa[0]);
           var monthA = parseInt(aa[1]);
           var yearB = parseInt(bb[0]);
           var monthB = parseInt(bb[1]);

           if (yearA < yearB || yearA == yearB && monthA < monthB) {
             return -1;
           }

           if (yearA > yearB || yearA == yearB && monthA > monthB) {
             return 1;
           }

           return 0;
         }).reverse();

         if(!parsedPeriods.length){
            const err = new Error('No suitable periods found');
            err.errorCode = 'NO_PERIODS';
            return $q.reject(err);
         }

        const selectedPeriods = [parsedPeriods[0]].filter(dataForPeriod => actualPeriods.some(x => x != null && x.dateMonth === dataForPeriod.dateMonth))

        if (!selectedPeriods.length) {
            const err = new Error('No suitable periods found');
            err.errorCode = 'NO_PERIODS';
            return $q.reject(err);
        }
        return $q.when([selectedPeriods[0]]);
    }

    function validateInn(periods, companyInn, companyKpp) {
        if (periods.some(p => p.inn !== companyInn || p.kpp !== companyKpp)) {
            const err = new Error('Wrong company');
            err.errorCode = 'WRONG_COMPANY';
            return $q.reject(err);
        }
        return $q.when(periods);
    }

    function hasForm1ValueCode(period){
        var validCodes = getValidCodes(1);
        for (let row of (period.buhRecords || [])) {
            if(validCodes.indexOf(row.code) > -1){
                return true
            }
        }
        return false;
    }

    function hasForm2ValueCode(period){
        var validCodes = getValidCodes(2);
        for (let row of (period.buhRecords || [])) {
            if(validCodes.indexOf(row.code) > -1){
                return true
            }
        }
        return false;
    }

    function getFormValue(period, formIndex, modelPeriods){
        var sourcePeriod =  modelPeriods[period.dateMonth];
        const sourcePeriodValue = {};
        var validCodes = getValidCodes(formIndex);

        for (let code of validCodes) {
            if (sourcePeriod[`b${code}`]) {
                sourcePeriodValue[`b${code}`] = sourcePeriod[`b${code}`];
            }
        }

        return sourcePeriodValue;
    }

    function getValidCodes(formIndex){
        var validCodes = ["1110", "1120", "1130", "1140", "1150", "1160", "1170", "1180", "1190", "1210", "1220", "1230", "1240", "1250", "1260", "1310", "1320", "1340", "1350", "1360", "1370", "1410", "1420", "1430", "1450", "1510", "1520", "1530", "1540", "1550"];

        if($scope.model && ($scope.model.taxationTypeRefId === 'usn' || $scope.model.taxationTypeRefId === 'osn') && $scope.model.taxationAccountingTypeRefId === 'simple'){
            validCodes = ["1150", "1170", "1210", "1230", "1250", "1300", "1410", "1450", "1510", "1520", "1550"];
        }

        if (formIndex == 2) {
            validCodes = ["2110", "2120", "2210", "2220", "2310", "2320", "2330", "2340", "2350", "2410", "2430", "2450", "2460"];
            if($scope.model && ($scope.model.taxationTypeRefId === 'usn' || $scope.model.taxationTypeRefId === 'osn') && $scope.model.taxationAccountingTypeRefId === 'simple'){
                validCodes = ["2110", "2120", "2330", "2340", "2350", "2410"];
            }
        }

        return validCodes
    }

    function fillData(periods, modelPeriods) {
        var validCodes = ["1110", "1120", "1130", "1140", "1150", "1160", "1170", "1180", "1190", "1210", "1220", "1230", "1240", "1250", "1260", "1310", "1320", "1340", "1350", "1360", "1370", "1410", "1420", "1430", "1450", "1510", "1520", "1530", "1540", "1550", "2110", "2120", "2210", "2220", "2310", "2320", "2330", "2340", "2350", "2410", "2430", "2450", "2460"];
        if($scope.model && ($scope.model.taxationTypeRefId === 'usn' || $scope.model.taxationTypeRefId === 'osn') && $scope.model.taxationAccountingTypeRefId === 'simple'){
            validCodes = ["1150", "1170", "1210", "1230", "1250", "1300", "1410", "1450", "1510", "1520", "1550", "2110", "2120", "2330", "2340", "2350", "2410"];
        }

        periods.forEach(dataForPeriod => {
            const hasForm1 =  hasForm1ValueCode(dataForPeriod);
            const hasForm2 =  hasForm2ValueCode(dataForPeriod);
            var period = {};

            if(hasForm1 && !hasForm2){
                period = getFormValue(dataForPeriod, 2, modelPeriods);
            }

            if(!hasForm1 && hasForm2){
                period = getFormValue(dataForPeriod, 1, modelPeriods);
            }

            for (let row of (dataForPeriod.buhRecords || [])) {
                if(validCodes.indexOf(row.code) > -1){
                    const code = `b${row.code}`;
                    const amount = row.value * 100000;
                    period[code] = Math.abs(amount);
                }
            }
            modelPeriods[dataForPeriod.dateMonth] = period;
        });
    }

    function readFile(file) {
        const d = $q.defer();

        const reader = new FileReader();
        reader.onerror = function (e) {
            e.errorCode = 'READ_FILE_FAILED';
            d.reject(e);
        };

        reader.onload = function (e) {
            const cnt = e.target.result.substr(5).split(';');
            const content = cnt[1].substr(7);
            d.resolve(content);
        };

        reader.readAsDataURL(file);

        return d.promise;
    }

    function uploadToDrafts(taskId, companyId, filename, content, drafts) {
        const docType = 'finReportYear';
        const camundaApiBaseUrl = '/camunda/api/engine/engine/default';

        const draftName = `${docType}@${companyId}`;
        const nextNum = getNextNum(draftName, drafts);
        const numSuffix = nextNum == 1 ? '' : `!${nextNum}`;
        const varName = `$locFileDraft_${draftName}${numSuffix}`;
        const url = `${camundaApiBaseUrl}/task/${taskId}/localVariables/${encodeURIComponent(varName)}`;

        const data = {
            value: content,
            type: 'File',
            valueInfo: {
                filename: filename
            }
        }

        return $http.put(url, data).catch(e => {
            e.errorCode = 'UPLOAD_FAILED';
            return $q.reject(e);
        }).then(() => {
            if (drafts) {
                const draftFiles = angular.copy(drafts[draftName] || []);
                const draft = {
                    name: filename,
                    varName: varName
                };
                draftFiles.push(draft);
                drafts[draftName] = draftFiles;
            }
        });
    }

    function getNextNum(draftName, drafts) {
        const draftFiles = (drafts || {})[draftName] || [];
        if (!draftFiles.length) return 1;
        const numbers = draftFiles.map(x => {
            const ps = x.varName.split('!');
            if (ps.length < 2) return 1;
            return Number(ps[1]);
        });
        return Math.max(...numbers) + 1;
    }

    //---------------------------------------------------------------------------------------------

    if ($scope.model) onModelChange();
}];

export default ctrl;