export default ['$scope', function ($scope) {
    const my = this;

    my.selectedSecuredBank = {
        bankName: ($scope.model.data.kik || {}).securedAcctBankName,
        BIK: ($scope.model.data.kik || {}).securedAcctBIK,
        corrNumber: ($scope.model.data.kik || {}).securedAcctCorrNumber
    };

    $scope.$watch('my.selectedSecuredBank', function (selectedSecuredBank) {
        if (!selectedSecuredBank) selectedSecuredBank = {};

        if (!$scope.model.data.kik) $scope.model.data.kik = {};
        $scope.model.data.kik.securedAcctBankName = selectedSecuredBank.bankName;
        $scope.model.data.kik.securedAcctBIK = selectedSecuredBank.BIK;
        $scope.model.data.kik.securedAcctCorrNumber = selectedSecuredBank.corrNumber;
    });

    my.selectedRsBank = {
        bankName: ($scope.model.data.kik || {}).rsAcctBankName,
        BIK: ($scope.model.data.kik || {}).rsAcctBIK,
        corrNumber: ($scope.model.data.kik || {}).rsAcctCorrNumber
    };

    $scope.$watch('my.selectedRsBank', function (selectedRsBank) {
        if (!selectedRsBank) selectedRsBank = {};

        if (!$scope.model.data.kik) $scope.model.data.kik = {};
        $scope.model.data.kik.rsAcctBankName = selectedRsBank.bankName;
        $scope.model.data.kik.rsAcctBIK = selectedRsBank.BIK;
        $scope.model.data.kik.rsAcctCorrNumber = selectedRsBank.corrNumber;
    });
}];