import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', 'fzpSvcPayments', function ($scope, $modalInstanse, $modalParams, service) {
    const my = this;

    my.closeOk = function () {
        service.addPayment(my.selectedItem).then(res => {
            $modalInstanse.close({result: "yes", item: res});
            my.statusPost = 'ok';
        }).catch(err => {
            my.statusPost = 'error';
        });
    };

    my.closeCancel = function () {
        $modalInstanse.close({result: "no"});
    };

    my.header = $modalParams.header;
    my.readonly = $modalParams.readonly;
    my.model = angular.copy($modalParams.model);

    my.init = () =>  {
        my.statusLoad = 'pending'
        service.getCustomerAccount(my.model.pinEq).then(res => {
           my.accountList = res;
           my.statusLoad = 'ok';
        }).catch(err => {
           my.accountList = [];
           my.statusLoad = 'error';
        })
    };

    my.select = item => {
        my.selectedItem = {};
        my.selectedItem.payerAccountNumber = item.ean;
        my.selectedItem.externalId = "manualAft" + new Date().getTime() + (Math.random() * 100000000000000000).toString(36);
        my.selectedItem.payerINN = my.model.payer;
        my.selectedItem.payerKPP = my.model.payerKpp || '-';
        my.selectedItem.purpose = 'Оплата вознаграждения по Договору о предоставлении банковской гарантии ' + my.model.purpose + ' от ' + my.model.contractDate + ' . НДС не облагается';
    }

    my.clear = () => {
        my.selectedItem = null;
    };

    my.init();

    my.valid = () => {
        return my.selectedItem && (
            my.selectedItem.payerAccountNumber != null &&
            my.selectedItem.payerINN != null &&
            my.selectedItem.payerKPP != null &&
            my.selectedItem.purpose != null &&
            my.selectedItem.amount != null &&
            my.selectedItem.date != null
        )
    };
}];